import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Furniture from "./Components/BespokeFurniture";
import Temple from "./Components/BespokeTemple";
import FloorPlans from "./Components/FloorPlans";
// import Header1New from "./Components/Header1New";
import Blog from "./Components/Blog";
import Home from "./Components/Home";
import Home1 from "./Components/Home2";
import Interior from "./Components/InteriorDesigning";
import Kitchen from "./Components/ModularKitchen";
import OfficeSpaces from "./Components/OfficeSpaces";
function App() {
  return (
    // <>
    // <Home />
    // </>
    <Router>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Home/>} >    
         </Route>
        {/* <Route path="/services_temple" element={<Home/>} >  </Route>  
         <Route path="/id" element={<Home/>} />          */}
        <Route path="/enquiry" element={<Home1 />} />   
        <Route path="/Blogs" element={<Blog />} />   
        <Route path="/Modular_Kitchen" element={<Kitchen />} />
        <Route path="/Bespoke_Furniture" element={<Furniture />} />    
        <Route path="/InteriorDesigns" element={<Interior />} />
        <Route path="/Bespoke_Temple" element={<Temple />} />
        <Route path="/Office_Spaces" element={<OfficeSpaces />} />  
        <Route path="/FloorPlans" element={<FloorPlans/>} />            
      </Routes>
    </Router>
  );
}

export default App;
