import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "jquery";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import "./PortNewComp.css";
export default function PortNewComp({ responseFlag }) {
  const [portfolioData, setportfolioData] = useState([]);
  const [showPortData, setShowPortData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [relatedImage, setrelatedImage] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [maxImageWidths, setMaxImageWidths] = useState([]);
  useEffect(() => {
    if (responseFlag || localStorage.getItem("PortfolioWrapList") != null) {
      let data = JSON.parse(localStorage.getItem("PortfolioWrapList"));

      if (data) {
        setportfolioData(data);
      }
      setTimeout(() => {
        window.initJs();
      }, 1000);
    }
  }, [responseFlag]);

  useEffect(()=>{
    localStorage.setItem("portModalValue", false); 
  },[]);

  useEffect(() => {
    let url = window.location.href;
    if(url.includes('Projectname')){
      //  console.log('If Conds')
       let urlString = window.location.href.split('?')[1];   
       urlString = urlString.split('=')[1]; 
       urlString = decodeURIComponent(urlString);
       let datafromServer = JSON.parse(localStorage.getItem("PortfolioWrapList"));
       if (datafromServer) {
         const filterData = datafromServer.filter(
           (item) => item.portfolioObj.Name === urlString
         );  

       if(filterData.length!==0){
        openModal(urlString);
       }     

      }

    } 
    else{
      // console.log('Else Condition');
    }
  
  }, [responseFlag]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    // console.log('HelloServices');
    const valueModal = JSON.parse(localStorage.getItem("portModalValue"));  
    if (valueModal) {
        closeModal();              
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);   


  const openGetAQuoteModal = () => {
    setIsOpen(false);
    document.getElementById("getAQuoteButton").click();
  };


  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 464);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const showProperty = (itemData, itemImage, index) => {
  //   let datafromServer = JSON.parse(localStorage.getItem("PortfolioWrapList"));
  //   if (datafromServer) {
  //     const filterData = datafromServer.filter(
  //       (item) => item.portfolioObj.Name === itemData
  //     );
  //     setShowPortData({
  //       imgsrc: filterData[0].portfolioImageUrlList,
  //       ProjectType: filterData[0].portfolioObj.Interior_Location__c,
  //       title: filterData[0].portfolioObj.Name,
  //       Year: filterData[0].portfolioObj.Interior_Project_Year__c,
  //       Description: filterData[0].portfolioObj.Portfolio_Description__c,
  //     });
  //   }
  // };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsiveForModalCraousel = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const flickityOptions = {
    pageDots: false,
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    zIndex: "999",
  };

  let subtitle;

  function openModal(itemData) {
    // console.log('ItemData='+itemData);
    localStorage.setItem("portModalValue", true); 
    let url = window.location.href;
    if(!url.includes('Projectname')){
    url += '?Projectname=' + encodeURIComponent(itemData); // Encode itemData to handle special characters  
    window.history.pushState({ path: url }, '', url);        
    }
    setSelectedIndex(0);
    let datafromServer = JSON.parse(localStorage.getItem("PortfolioWrapList"));
    if (datafromServer) {
      const filterData = datafromServer.filter(
        (item) => item.portfolioObj.Name === itemData
      );
      setShowPortData({
        imgsrc: filterData[0].portfolioImageUrlList,
        ProjectType: filterData[0].portfolioObj.Interior_Location__c,
        title: filterData[0].portfolioObj.Name,
        Year: filterData[0].portfolioObj.Interior_Project_Year__c,
        Description: filterData[0].portfolioObj.Portfolio_Description__c,
      });

      setrelatedImage({
        imgsrc: filterData[0].portfolioImageUrlList.slice(1),
        ProjectType: filterData[0].portfolioObj.Interior_Location__c,
        title: filterData[0].portfolioObj.Name,
        Year: filterData[0].portfolioObj.Interior_Project_Year__c,
        Description: filterData[0].portfolioObj.Portfolio_Description__c,
      });

      const imageWidths = filterData[0].portfolioImageUrlList.map(() => 0);
      filterData[0].portfolioImageUrlList.forEach((item, index) => {
        const img = new Image();
        img.src = item;

        img.onload = () => {
          const width = img.width;
          // console.log('widh=',width);
          imageWidths[index] = width;
          setMaxImageWidths([...imageWidths]);
        };
      });
    }

    setIsOpen(true);
    document.getElementById("home").style.pointerEvents = "none";
    document.body.style.overflow = "hidden";
  }

  function afterOpenModal() {
    // subtitle.style.color = "#f00"; 
  }

  function closeModal() {
    localStorage.setItem("portModalValue", false); 
    setIsOpen(false);
    document.getElementById("home").style.pointerEvents = "auto";
    document.body.style.overflow = "auto";
    window.history.pushState(null, '', '/');
  }
  const getQuoteOpenProp = () => {
    document.getElementById("getAQuoteButton").click();
  };

  const handleCarouselBeforeChange = (oldIndex, newIndex) => {
    // console.log("Index Before", oldIndex);
    setSelectedIndex(oldIndex);
  };

  const handleCarouselAfterChange = (index) => {
    // console.log("Index after=", index);
  };

  const RelatedImageClick = (clickedIndex, clickedImage) => {
    // console.log('SelectedIndex=',selectedIndex);
    const clickedImageIndex = showPortData.imgsrc.findIndex(
      (item) => item === clickedImage
    );
    // console.log('clickedImageIndex=',clickedImageIndex);
    if (selectedIndex !== clickedImageIndex) {
      const updatedModalImage = [...showPortData.imgsrc];
      updatedModalImage[selectedIndex] = showPortData.imgsrc[clickedImageIndex];
      updatedModalImage[clickedImageIndex] = showPortData.imgsrc[selectedIndex];
      setShowPortData({
        ...showPortData,
        imgsrc: updatedModalImage, // Assuming item.image is the source property
      });
    }
  };

  //  useEffect(() => {
  //   let datafromServer = JSON.parse(localStorage.getItem("PortfolioWrapList"));
  //   if (datafromServer) {
  //     datafromServer.forEach((item) => {
  //       setShowPortData({
  //         imgsrc: item.portfolioImageUrlList,
  //         ProjectType: item.portfolioObj.Interior_Location__c,
  //         title: item.portfolioObj.Name,
  //         Year: item.portfolioObj.Interior_Project_Year__c,
  //         Description: item.portfolioObj.Portfolio_Description__c,
  //       });
  //     });
  //   }
  // }, []);

  //  useEffect(() => {
  //   if (showPortData.imgsrc && showPortData.imgsrc.length > 0) { 
  //     // console.log('Hi If');
  //     const imageWidths = showPortData.imgsrc.map(() => 0);
  //     showPortData.imgsrc.forEach((item, index) => {
  //       const img = new Image();
  //       img.src = item;

  //       img.onload = () => {
  //         const width = img.width;
  //         imageWidths[index] = width;
  //         setMaxImageWidths([...imageWidths]);
  //       };
  //     });
  //   }
  //   else{
  //     // console.log('Hi Else');
  //   }
  // }, [showPortData]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="innerDivModal lg:overflow-hidden">
          <div
            onClick={closeModal}
            style={{
              width: "4vh",
              height: "auto",
              marginBottom: "10px",
              color: "black",
              marginRight: "16px",
              display: "flex",
              justifyContent: "Center",
              alignItems: "center",
              cursor: "pointer",
              float: "right",
              position: "fixed",
              right: "9%",
            }}
            className="closeBtnServiceModal"
          >
            <span style={{ fontSize: "24px" }}>✖</span>
          </div>
          <div className="mainDivProp bg-transparent">
            <div className="FramerMotionCarouselPropModal">
              {/* <Carousel
                responsive={responsiveForModalCraousel}  
                className="CarouselPropModal"   
                beforeChange={handleCarouselBeforeChange}
                afterChange={handleCarouselAfterChange}
              >
                {showPortData?.imgsrc?.length > 0 &&
                  showPortData.imgsrc.map((item, idx) => (
                    <img
                      src={item}
                      key={idx}
                      // decoding="async"
                      loading="eager"
                      style={{
                        height: "-webkit-fill-available",
                        maxWidth: maxImageWidths[idx],
                      }}
                    />
                  ))}
              </Carousel> */}

<PhotoProvider>
  <Carousel
    responsive={responsiveForModalCraousel}
    className="CarouselPropModal"
    beforeChange={handleCarouselBeforeChange}
    afterChange={handleCarouselAfterChange}
  >
    {showPortData?.imgsrc?.length > 0 &&
      showPortData.imgsrc.map((item, idx) => (
        <PhotoView key={idx} src={item}>
               <img
                      src={item}
                      key={idx}
                      // decoding="async"  
                      loading="eager"
                      style={{
                        height: "-webkit-fill-available",  
                        maxWidth: maxImageWidths[idx],  
                      }}
                    />
        </PhotoView>
   
      ))}
  </Carousel>
</PhotoProvider>

               

            </div>
            <div className="detailDiv bg-white">
              <h2 className="HeadingName"> {showPortData.title} </h2>
              <p className="description">{showPortData.Description}</p>
              <br />
              <button className="bookButton" onClick={openGetAQuoteModal}>
                Book A Free Designer
              </button>
              <br />
              <br />
              <div style={{ display: "flex" }}>
                <span>
                  <p style={{ color: "#000000" }}>Location: </p>
                </span>
                <span>
                  <p> &nbsp; {showPortData.ProjectType}</p>
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span>
                  <p style={{ color: "#000000" }}>Project Year: </p>
                </span>
                <span>
                  <p> &nbsp; {showPortData.Year}</p>
                </span>
              </div>
              {/* <br /> */}
              <br />
              <h3 className="ShareDesign">Share this Design</h3>
              <div class="social-links">
                <a
                  href="https://www.facebook.com/spacifyindia"
                  target="_blank"
                  className="FacebookIcon iconsModal"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://twitter.com/spacifyindia"
                  target="_blank"
                  className="iconsModal"
                >
                  <i class="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/spacifyindia/"
                  target="_blank"
                  className="iconsModal"
                >
                  <i class="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/spacify-india-5394892a8/"
                  target="_blank"
                  className="iconsModal"
                >
                  <i class="fa fa-linkedin"></i>
                </a>
                <a
                  href="https://www.youtube.com/@spacifyindia"
                  target="_blank"
                  className="iconsModal"
                >
                  <i class="fa fa-youtube-play"></i>
                </a>
                {/* <a href="#">
                  <i class="fab fa-whatsapp"></i>  
                </a> */}
              </div>
              <br />
              <h3 className="ShareDesign" style={{ marginBottom: "10px" }}>
                Related Images
              </h3>
              <div className="row">
                {/* {modalImage.length > 0 &&
                  modalImage.map((item, index) => (
                    <div className="col-lg-4 col-md-2 col-sm-4 col-6 ">
                      <img
                        key={index}
                        src={item.image}
                        className="img-thumbnail"  
                        onClick={() => {
                          RelatedImage(item.image, index);
                        }}
                      />
                    </div>
                  ))} */}
                {relatedImage?.imgsrc?.length > 0 &&
                  relatedImage.imgsrc.map((item, idx) => (
                    // <img src={item} key={idx} />
                    <div className="col-lg-4 col-md-2 col-sm-4 col-6 " key={idx}>
                      <img
                        key={idx}
                        src={item}
                        className="img-thumbnail"
                        //  decoding="async"
                        loading="eager"
                        onClick={() => {
                          RelatedImageClick(idx, item);
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <section className="reviewSectionPort" id="portfolioNew">
        <div className="PortNewHead">
          <div className="PortTopHeading">
            Get a glimpse of Spacify projects 
            <p className="PortNewSecondHead">
              Latest dream home interiors delivered the hassle-free way
            </p>
          </div>
          <div className="divBtnPort">
            <button className="getPropBtn" onClick={getQuoteOpenProp}>
              Get a Quote
            </button>
          </div>
        </div>
        <div
          className="parent"
          style={{ paddingLeft: "80px", paddingRight: "80px", margin: "auto" }}
        >
          <Carousel
           customTransition="transform 500ms ease-in-out"
          //  additionalTransfrom={isMobile ? -(100 / 3.5) : 0} // Apply additional transform for mobile screens
            showDots={true} responsive={responsive} className="PortfolioCarouselClass">
            {portfolioData.length > 0 &&
              portfolioData.map((item, idx) => (
                <div key={idx}
                  className="slider12 propDivHoverSha propCardNew"  style={{cursor:"pointer"}}
                  // onClick={() => {
                  //   showProperty(
                  //     item.portfolioObj.Name,
                  //     item.portfolioImageUrlList[0],
                  //     idx
                  //   );
                  // }}
                >
                  <figure className="snip1527">  
                    <div className="imageSnip"
                    //  onClick={openModal}
                     onClick={() => { 
                      openModal(
                        item.portfolioObj.Name
                      );
                    }}
                     >
                      <img
                        src={item.portfolioImageUrlList[0]}
                        width="{500}"
                        height="{466}"
                        alt="pr-sample23"
                      />
                    </div>
                    <figcaption>
                      <h3>{item.portfolioObj.Name}</h3>
                    </figcaption>
                  </figure>  
                  {/* <article className="cardProp"  onClick={() => {
                      openModal(
                        item.portfolioObj.Name,
                        item.portfolioImageUrlList[0],
                        idx
                      );
                    }}
                    >
                    <div className="imgDivPropCard">
                      <img src={item.portfolioImageUrlList[0]} />
                    </div>

                    <div className="textCardProp">
                      <div className="textCardProp1">
                        <h4>{item.portfolioObj.Name}</h4>
                        <p>{item.portfolioObj.Project_mini_discription__c}</p>
                      </div>
                      <div className="textCardPropIcon">
                        <span className="textCardIconSpan">
                          <i class="fas fa-angle-right"></i>
                        </span>
                      </div>
                    </div>
                  </article> */}
                </div>
              ))}

            {/* <div className="slider">
              <figure className="snip1527">
                <div className="imageSnip" onClick={openModal}>
                  <img
                    src="https://images.livspace-cdn.com/w:500/h:466/plain/https://d3gq2merok8n5r.cloudfront.net/bumblebee/in/unification-home-1663681501-pVo75/desktop-1663681517-hulYi/livspace-homes-1682068198-shXmv/contemporary-3-bhk-house-design-in-gurgaon-1682068277-e8xQN.jpg"
                    width="{500}"
                    height="{466}"
                    alt="pr-sample23"
                  />
                </div>
                <figcaption>
                  <h3>An Abstract Post Heading</h3>
                </figcaption>
              </figure>
            </div>
            <div className="slider">
            <figure className="snip1527">
              <div className="imageSnip" onClick={openModal}>
                <img
                  src="https://images.livspace-cdn.com/w:500/h:466/plain/https://d3gq2merok8n5r.cloudfront.net/bumblebee/in/unification-home-1663681501-pVo75/desktop-1663681517-hulYi/livspace-homes-1682068198-shXmv/contemporary-house-design-for-3-bhk-flat-in-ahmedabad-1682068278-VzD95.jpg"
                  width="{500}"
                  height="{466}"
                  alt="pr-sample23"
                />
              </div>
              <figcaption>
                <h3>An Abstract Post Heading</h3> 
              </figcaption>
            </figure>
          </div>
          <div className="slider">
            <figure className="snip1527">
              <div className="imageSnip" onClick={openModal}>
                <img
                  src="https://images.livspace-cdn.com/w:500/h:466/plain/https://d3gq2merok8n5r.cloudfront.net/bumblebee/in/unification-home-1663681501-pVo75/desktop-1663681517-hulYi/livspace-homes-1682068198-shXmv/rustic-house-design-for-3-bhk-flat-in-bangalore-1682068282-lbPWM.jpg"
                  width="{500}"
                  height="{466}"
                  alt="pr-sample23"
                />
              </div>
              <figcaption>
                <h3>An Abstract Post Heading</h3>
              </figcaption>
            </figure>
          </div>
          <div className="slider">
            <figure className="snip1527">
              <div className="imageSnip" onClick={openModal}>
                <img
                  src="https://images.livspace-cdn.com/w:500/h:466/plain/https://d3gq2merok8n5r.cloudfront.net/bumblebee/in/unification-home-1663681501-pVo75/desktop-1663681517-hulYi/livspace-homes-1682068198-shXmv/modern-house-design-for-2-bhk-flat-in-mumbai-1682068281-ab4gr.jpg"
                  width="{500}"
                  height="{466}"
                  alt="pr-sample23"
                />
              </div>
              <figcaption>
                <h3>An Abstract Post Heading</h3>
              </figcaption>
            </figure>
          </div>  */}
          </Carousel>
        </div>
        <div className="divBtnGetResponsive">  
            <button className="getQuotebtnRespon" onClick={getQuoteOpenProp}>
              Get Free Quote
            </button>
          </div>
      </section>
    </>
  );
}
