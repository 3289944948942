// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "jquery";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ReelsComp.css";
export default function ReelsComp({ responseFlag }) {
  const [reelData, setReelData] = useState([]);
  useEffect(() => {
    if (responseFlag || localStorage.getItem("reelWrapList") != null) {
      let Data = JSON.parse(localStorage.getItem("reelWrapList"));
      if (Data) {
        setReelData(Data);
      }
      setTimeout(() => {
        window.initJs();
      }, 1000);
    }
  }, [responseFlag]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 3,
    },
    smallTablet: {
      breakpoint: { max: 700, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };
  const [playingIndex, setPlayingIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setPlayingIndex(index);
  };

  const handleMouseLeave = () => {
    setPlayingIndex(null);
  };

  const clickInstaBtn = () =>{
    var instagramUrl = "https://www.instagram.com/spacifyindia/";
    window.open(instagramUrl,'_blank');  
  };
  return (
    <section className="reviewSectionReels">
      <div className="PortNewHeadReels">Instagram Reels</div>
      <div
        className="parentReels"
        style={{ paddingLeft: "80px", paddingRight: "80px", margin: "auto" }}
      >
        <Carousel showDots={false} responsive={responsive}>
          {reelData.length > 0 &&
            reelData.map((item, index) => (
              <div
                className="sliderReels"
                key={index}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <figure className="snip1527">
                  <div className="imageReels">
                    <video
                      className="VideoReels"
                      src={item.videoURL}
                      alt=""
                      width={257}
                      height={257}
                      // autoPlay={index === playingIndex}
                      controls
                      loop
                      muted
                       loading="eager"
                      preload="auto"
                      playsInline
                      autoPlay={index === playingIndex}
                      ref={(videoRef) => {
                        if (videoRef && index === playingIndex) {
                          // Check if videoRef is not null before calling play
                          videoRef
                            .play()
                            .catch((error) => console.error(error));
                        } else if (videoRef) {
                          // Check if videoRef is not null before calling pause
                          videoRef.pause();
                        }
                      }}
                    />
                  </div>
                </figure>
              </div>
            ))}
        </Carousel>
      </div>
      <div style={{}}>
        <div style={{display:"flex", justifyContent:"center"}}>
          <img  src="assets/images/mainlogo.png" alt="img1" className="imgInstaLogo"></img>
        </div>
        <br/>
        <div style={{display:"flex", justifyContent:"center"}}>
          <button className="InstaBtn" onClick={clickInstaBtn}>
          <span>
              <i className="fa fa-instagram"></i>&nbsp; &nbsp;
            </span>
            <span>FOLLOW</span>
          </button>
        </div>
      </div>
    </section>
  );
}
