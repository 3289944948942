import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACCESS_TOKEN_API } from "../shared/constants";
import { Dashboard } from "./Dashboard";
// import GetaQuote from "./GetaQuote";
import ScaleLoader from "react-spinners/ScaleLoader";
import BannerSection12 from "./BannerSection12";
// import BlogComp from "./BlogComp";
import Faq from "./Faq";
import Navbar from "./Navbar";
import NewContactUs from "./NewContactUs";
import NewReview from "./NewReview";
import OurTrustedPartners from "./OurTrustedPartners";
import PortNewComp from "./PortNewComp";
import ReelsComp from "./ReelsComp";
import Services from "./Services";
import "./Style.css";
import WhySpacify from "./WhySpacify";
import Footer from "./footer";
import PortfolioModal from "./portfolioModal";

const Home = () => {
  const [responseFlag, setResponseFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const [apiSuccessServ, setApiSuccessServ] = useState(false);
  const [apiSuccessPort, setApiSuccessPort] = useState(false);
  const [contactModal, setContactModal] = useState("d-none");
  const [getaQuoteModal, setgetaQuoteModal] = useState("d-none");
  const [pModalStyle, setpModalStyle] = useState("d-none");
  const [propertyData, setPropertyData] = useState({});
  const [portfolioDisplayHome, setportfolioDisplayHome] = useState(true);
  const [interiorDisplayHome, setinteriorDisplayHome] = useState(true);
  const [userloggedin, setuserloggedin] = useState(false);
  const [showOrHideProjectModal, setshowOrHideProjectModal] = useState(false);
  const [budgetValues, setBudgetValues] = useState([]);
  const [projectTypePicklist, setProjectTypePicklist] = useState([]);
  const budgetValue = [];
  const projectTypePick = [];

  useEffect(() => {
    fetch(ACCESS_TOKEN_API, { method: "POST" }) ///getAccessToken
      .then((response) => response.json())
      .then((result) => {
        getData(result);
      })
      .catch((error) => {
        // console.log('The error in react ='+error);
      });
    if (localStorage.getItem("isLogin")) {
      setuserloggedin(true);
    } else {
      setuserloggedin(false);
    }

    // For Http Link Change To Https
    let cnt = window.location.href.search("https"); 
    //  console.log('cnt',cnt);
     if(cnt<0){
        window.location.replace('https://spacifyindia.com/')                             
     }     
     
     setTimeout(() => {
      // console.log('Hello Timeout');  
      setLoading(false);  
    }, 4000);
    // For Http Link Change To Https
  }, []);
  const getQuoteOpenProp = () => {
    document.getElementById("getAQuoteButton").click();
  };

  const getData = (res) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${res.access_token}`);

    var raw = JSON.stringify({
      Name: "Kamran Khan",
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${res.instance_url}/services/apexrest/Interior_App_Api`,
      requestOptions
    ).then(r=> r.json()).then( (result) => { 
            const data = JSON.parse(result);
      //  console.log('The Result ='+JSON.stringify(data));
      for (
        let i = 0;
        i < data.PicklistValuesList[0].budgetPicklist.length;
        i++
      ) {
        budgetValue.push(data.PicklistValuesList[0].budgetPicklist[i]);
      }
      setBudgetValues(budgetValue);
      for (
        let i = 0;
        i < data.PicklistValuesList[0].projectTypePicklist.length;
        i++
      ) {
        projectTypePick.push(data.PicklistValuesList[0].projectTypePicklist[i]);
      }
      setProjectTypePicklist(projectTypePick);

      if (
        data.ReelVideoURLSList === null ||
        data.ReelVideoURLSList === "" ||
        data.ReelVideoURLSList === undefined
      ) {
        localStorage.removeItem("reelWrapList");
      } else {
        localStorage.setItem(
          "reelWrapList",
          JSON.stringify(data.ReelVideoURLSList)
        );
      }

      if (
        data.videoURLS === null ||
        data.videoURLS === "" ||
        data.videoURLS === undefined
      ) {
        localStorage.removeItem("videoWrapList");
      } else {
        localStorage.setItem("videoWrapList", JSON.stringify(data.videoURLS));
      }

      if (
        data.floorPlanWrapList === null ||
        data.floorPlanWrapList === "" ||
        data.floorPlanWrapList === undefined
      ) {
        localStorage.removeItem("floorPlanWrapList");
      } else {
        localStorage.setItem(
          "floorPlanWrapList",
          JSON.stringify(data.floorPlanWrapList)
        );
      }

      if (
        data.feedbackWrapList === null ||
        data.feedbackWrapList === "" ||
        data.feedbackWrapList === undefined
      ) {
        localStorage.removeItem("feedbackWrapList");
      } else {
        localStorage.setItem(
          "feedbackWrapList",
          JSON.stringify(data.feedbackWrapList)
        );
      }
      if (
        data.partnerWrapList === null ||
        data.partnerWrapList === "" ||
        data.partnerWrapList === undefined
      ) {
        localStorage.removeItem("partnerWrapList");
      } else {
        localStorage.setItem(
          "partnerWrapList",
          JSON.stringify(data.partnerWrapList)
        );
      }

      if (
        data.bannerWrapList === null ||
        data.bannerWrapList === "" ||
        data.bannerWrapList === undefined
      ) {
        localStorage.removeItem("bannerWrapList");
      } else {
        localStorage.setItem(
          "bannerWrapList",
          JSON.stringify(data.bannerWrapList)
        );
      }

      if (
        data.interiorWrapList === null ||
        data.interiorWrapList === "" ||
        data.interiorWrapList === undefined
      ) {
        setinteriorDisplayHome(false);
        setApiSuccessServ(false);
        localStorage.removeItem("interiorWrapList");
      } else {
        setinteriorDisplayHome(true);
        setApiSuccessServ(true);
        localStorage.setItem(
          "interiorWrapList",
          JSON.stringify(data.interiorWrapList)
        );
      }

      if (
        data.PortfolioWrapList === null ||
        data.PortfolioWrapList === "" ||
        data.PortfolioWrapList === undefined
      ) {
        setportfolioDisplayHome(false);
        setApiSuccessPort(false);
        localStorage.removeItem("PortfolioWrapList");
      } else {
        setportfolioDisplayHome(true);
        setApiSuccessPort(true);
        localStorage.setItem(
          "PortfolioWrapList",
          JSON.stringify(data.PortfolioWrapList)
        );
      }
      setResponseFlag(true);
    });

   
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        rtl={false}
        draggable
        theme="light"
        style={{ zIndex: 9999999 }}
      />
   {loading ? (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ScaleLoader color="#e89246" loading={loading} size={20} style={{position:"fixed", top:"45%"}} />
  </div>
) : (
  <>
    {userloggedin ? (
      <Dashboard
        setuserloggedin={setuserloggedin}
        showOrHideProjectModal={showOrHideProjectModal}
        setshowOrHideProjectModal={setshowOrHideProjectModal}
      />
    ) : (
      <div>
        <div className="page ele">
          <div
            className={pModalStyle}
            style={{ position: "fixed", zIndex: "2" }}
          >
            <PortfolioModal
              setpModalStyle={setpModalStyle}
              propertyData={propertyData}
            />
          </div>
          <div>
            <Navbar
              setOpenModal={setContactModal}
              setOpenModalGetaQuote={setgetaQuoteModal}
              setuserloggedin={setuserloggedin}
              setshowOrHideProjectModal={setshowOrHideProjectModal}
              contactModal={contactModal}
            />
            <BannerSection12 responseFlag={responseFlag} />
            {/* <Landingpage setOpenModalGetaQuote={setgetaQuoteModal} /> */}
            <WhySpacify />
            {interiorDisplayHome === true ? (
              <Services responseFlag={responseFlag} />
            ) : (
              <div></div>
            )}
            <PortNewComp responseFlag={responseFlag} />

            {/* Hide in mobile view */}
            {/* <div className="removeCompMobile" style={{ display: "block" }}> 
              <ServicesNew /> 
            </div> */}

            {/* Hide in mobile view */}
            <div className="removeCompMobile" style={{ display: "block" }}> 
              <NewReview responseFlag={responseFlag}/> 
            </div>

            <OurTrustedPartners responseFlag={responseFlag} />

            <NewContactUs /> 

            {/* Hide in mobile view */}
            <div className="removeCompMobile" style={{ display: "block" }}>
              <ReelsComp responseFlag={responseFlag} />
            </div>

            {/* Hide in mobile view */}
            <div className="removeCompMobile" style={{ display: "block" }}>
              <Faq />
            </div>

            <Footer setshowOrHideProjectModal={setshowOrHideProjectModal} />
            <a
              target="_blank"
              href="https://wa.link/cp1ike"
              className="ui-to-topWhatsapp active"
            >
              <img src="https://storage.googleapis.com/bik-assets/bap%2Ffab-assets%2Fimages%2FDDQoj5ZVYwYd8aEtdFP5NRvL2Mk2%2F4b0d640ebd.png" />
            </a>

            <a href="tel:9654788000" className="ui-to-phone active">
              <img src="assets/images/CallLogo.png" />
            </a>

            {/* get a quote button in mobile view */}
            <div className="getaQuoteStaticBtnDiv">
              <button className="getQuoteStaticBtn" onClick={getQuoteOpenProp}>
                Get a Quote
              </button>
            </div>
            {/* get a quote button in mobile view */}
          </div>
        </div>

        <noscript>
          &lt;iframe src="//www.googletagmanager.com/ns.html?id=GTM-P9FT69" height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;
        </noscript>
      </div>
    )}
  </>
)}

    </>
  );
};

export default Home;
