import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "remixicon/fonts/remixicon.css";
import { ACCESS_TOKEN_API } from "../shared/constants";
import "./GetAFreeDesigner.css";
import Loginpage from "./Loginpage";
import ProfilePage from "./Profile";
import Signup from "./Signup";
export default function FloorNavBar({
  setOpenModal,
  setOpenModalGetaQuote,
  setuserloggedin,
  setshowOrHideProjectModal,
}) {

  const navigate = useNavigate();
  const [lookup, setlookup] = useState(false);
  const [loggedin, setloggedin] = useState(false);
  const [showProfile, setshowProfile] = useState(false);
  const [loginModal, setloginModal] = useState(false);
  const [signupModal, setsignupModal] = useState(false);
  const [signoutModal, setsignoutModal] = useState(false);
  const [burgerBtn, setBurgerBtn] = useState(false);
  const [cityTypeSelect, setCityTypeSelect] = useState(false);
  const [fnameWarning, setFnameWarning] = useState(false);
  const [fnameDigitError, setFnameDigitError] = useState(false);
  const [phoneWarning, setPhoneWarning] = useState(false);
  const [cityWarning, setcityWarning] = useState(false);
  const [propTypeWarning, setpropTypeWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);
  const [showMiniHeader, setshowMiniHeader] = useState(true);

  const [bookBtn, setbookBtn] = useState(false);
  const [sProTypeValue, setsProTypeValue] = useState("");
  const [sCityValue, setsCityValue] = useState("");

  const [getDetails, setGetDetails] = useState({
    full_Name: "",
    phone: "",
    propertyType: "",
    city: "",
    email: "",
    query: "",
  });

  useEffect(() => {
    setshowMiniHeader(true);
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setGetDetails({ ...getDetails, [name]: value });
    // var obj = {...getDetails}
    // var fullName =obj.full_Name;

    if ("full_Name" === event.target.name) {
      if (event.target.value.length > 2) {
        setFnameWarning(false);
      } else {
        setFnameWarning(true);
      }

      const re = /^([^0-9]*)$/;
      // const re = /^[0-9-+()]*$/;
      // console.log("Check=", event.target.value.match(re));
      if (!event.target.value.match(re) && event.target.value.length > 2) {
        setFnameDigitError(true);
      } else {
        setFnameDigitError(false);
      }
    }

    if ("phone" === event.target.name) {
      if (value.length === 10) {
        setPhoneWarning(false);
      } else {
        setPhoneWarning(true);
      }
    }
    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if ("email" === event.target.name) {
      if (event.target.value.match(mailformat)) {
        setEmailWarning(false);
      } else {
        setEmailWarning(true);
      }
    }
  };

  const handlePropertyTypeChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setsProTypeValue(value); 
    setGetDetails({ ...getDetails, [name]: value });
  };

  const cityChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setsCityValue(value);
    // console.log('value='+value);
    setGetDetails({ ...getDetails, [name]: value }); 
  };

  const validatePhoneNumber = (event) => {
    var keycode = event.which;
    if (
      !(
        (event.shiftKey === false &&
          (keycode === 46 ||
            keycode === 8 ||
            keycode === 37 ||
            keycode === 39 ||
            (keycode >= 48 && keycode <= 57))) ||
        (keycode >= 96 && keycode <= 105) ||
        keycode === 9
      )
    ) {
      event.preventDefault();
    }
  };

  const bookBtnClick = () => {
    var obj = getDetails;
    // console.log('propertyType Length='+getDetails.propertyType.length);
    // console.log("The GetDetails=", obj);
    if (getDetails.full_Name.length > 2) {
      setFnameWarning(false);
    } else {
      setFnameWarning(true);
    }

    const re = /^([^0-9]*)$/;
    // const re = /^[0-9-+()]*$/;
    if (!getDetails.full_Name.match(re) && getDetails.full_Name.length > 2) {
      setFnameDigitError(true);
    } else {
      setFnameDigitError(false);
    }

    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (getDetails.email.match(mailformat)) {
      setEmailWarning(false);
    } else {
      setEmailWarning(true);
    }

    if (obj.phone.length === 10) {
      setPhoneWarning(false);
    } else {
      setPhoneWarning(true);
    }

    if (obj.city.length > 0) {
      setcityWarning(false);
    } else {
      setcityWarning(true);
    }

    if (obj.propertyType.length > 0) {
      setpropTypeWarning(false);
    } else {
      setpropTypeWarning(true);
    }

    if (getDetails.full_Name.length <= 1) {
      // console.log('Hello If Name');
      setbookBtn(false);
      //  return;
    }
    if (obj.phone.length < 10) {
      // console.log('Hello If Phone');
      setbookBtn(false);
      //  return;
    }
    if (obj.city.length <= 0) {
      // console.log('Hello If City');
      setbookBtn(false);
      //  return;
    }
    if (obj.propertyType.length <= 0) {
      // console.log('Hello If PropertyType');
      setbookBtn(false);
      //  return;
    }
    if (obj.email.match(mailformat)) {
      // console.log('Hello If PropertyType');
      setbookBtn(false);
    }

    if (
      getDetails.full_Name.length >= 3 &&
      obj.phone.length >= 10 &&
      obj.city.length > 0 &&
      obj.propertyType.length > 0 &&
      obj.email.match(mailformat)
    ) {
      // console.log('Hello Else');
      setbookBtn(true);
      fetch(ACCESS_TOKEN_API, { method: "POST" })
        .then((response) => response.json())
        .then((result) => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${result.access_token}`);
          var raw = JSON.stringify({
            getAQuoteNew: "sdfdsfdsf",
            Name: getDetails.full_Name,
            phone: getDetails.phone,
            propertyType: getDetails.propertyType,
            city: getDetails.city,
            email: getDetails.email,
            query: getDetails.query,
          });
          // console.log("The Console Raw=" + raw);

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
          fetch(
            `${result.instance_url}/services/apexrest/Interior_App_Api`,
            requestOptions
          ).then(async (response) => {
            let result = await response.json();
            if (result === "Successfully Inserted") {
              getDetails.full_Name = "";
              getDetails.city = "";
              getDetails.phone = "";
              getDetails.propertyType = "";
              getDetails.email = "";
              getDetails.query = "";
              setsCityValue("");
              setsProTypeValue("");
              toast.success("Request Submitted Successfully !", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
              });
              setTimeout(() => {
                setIsOpen(false);
                document.getElementById("home").style.pointerEvents = "auto";
                document.body.style.overflow = "auto";
              }, 1000);
              setbookBtn(false);
            } else if (result === "Failed GetAQuote") {
              getDetails.full_Name = "";
              // getDetails.city = "";
              getDetails.phone = "";
              getDetails.email = "";
              // getDetails.propertyType = "";
              getDetails.query = "";
              setsCityValue("");
              setsProTypeValue("");
              toast.error("Something Went Wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
              });
              setTimeout(() => {
                setIsOpen(false);
                document.getElementById("home").style.pointerEvents = "auto";
                document.body.style.overflow = "auto";
              }, 1000);
              setbookBtn(false);
            }
          });
        })
        .catch((error) => {
          // console.log('result Failed');
          getDetails.full_Name = "";
          getDetails.city = "";
          getDetails.phone = "";
          getDetails.propertyType = "";
          getDetails.email = "";
          getDetails.query = "";
          setsCityValue("");
          setsProTypeValue("");
          toast.error("Something Went Wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
          setTimeout(() => {
            setIsOpen(false);
            document.getElementById("home").style.pointerEvents = "auto";
            document.body.style.overflow = "auto";
          }, 1000);
          setbookBtn(false);
        });
    }

    // console.log("Name fukl name=" + JSON.stringify(getDetails));
  };

  const customStyles = {
    overlay: {
      background: "red",
      zIndex: 9999,
    },
    content: {
      width: "85%",
      top: "47%", 
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    zIndex: "9999",
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    getDetails.full_Name = ""; 
    getDetails.city = "";
    getDetails.phone = "";
    getDetails.propertyType = "";
    getDetails.email = "";
    getDetails.query = "";
    setsCityValue("");
    setsProTypeValue("");
    setFnameWarning(false);
    setFnameDigitError(false);
    setcityWarning(false);
    setpropTypeWarning(false);
    setPhoneWarning(false);
    setEmailWarning(false);
    setIsOpen(true);
    document.getElementById("home").style.pointerEvents = "none";
    document.body.style.overflow = "hidden";
    // document.getElementById("threeDotBtn").style.pointerEvents = "none";
    // document.getElementById("threeDotBtn").click();
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    document.getElementById("home").style.pointerEvents = "auto";
    document.body.style.overflow = "auto";
    // document.getElementById("threeDotBtn").style.pointerEvents = "all";
  }

  useEffect(() => {
    const currentURL = window.location.href;
    // console.log("Current URL:", currentURL);
    if (
      currentURL === "https://www.spacifyindia.com/#contact" || 
      currentURL === "http://www.spacifyindia.com/#contact" ||
      currentURL === "http://localhost:3000/#contact"
    ) {
      setStyle();
    }
  }, []);

  const showProfilelookup = () => {
    if (lookup) {
      setlookup(false);
      window.scrollBy(0, -60);
    } else {
      setlookup(true);
      window.scrollBy(0, 60);
    }
  };
  const blurme = () => {
    setTimeout(() => {
      setlookup(false);
    }, 700);
  };
  const test = () => {
    document.body.style.overflow = "hidden";
    setshowOrHideProjectModal(false);
    setshowProfile(true);
  };
  const userlogin = () => {
    // console.log("Click");
    localStorage.removeItem("Spacify_CloudCertitude_Password");
    localStorage.removeItem("Spacify_CloudCertitude_Username");
    localStorage.removeItem("SpacifyData");
    localStorage.removeItem("notificationData");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("element");
    setBurgerBtn(true);
    document.getElementById("home").style.pointerEvents = "none";
    document.getElementById("logoimg").style.pointerEvents = "none";
    // document.getElementById("threeDotBtn").style.pointerEvents = "none";
    // document.getElementById("threeDotBtn").click();
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    setloginModal(true);
    window.scrollTo(0, 0);
    // scrollME();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 2500);
    //setIsMenuActive(false);
  };
  const userSignUo = () => {
    localStorage.removeItem("Spacify_CloudCertitude_Password");
    localStorage.removeItem("Spacify_CloudCertitude_Username");
    localStorage.removeItem("SpacifyData");
    localStorage.removeItem("notificationData");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("element");
    setBurgerBtn(true);
    document.getElementById("home").style.pointerEvents = "none";
    document.getElementById("logoimg").style.pointerEvents = "none";
    // document.getElementById("threeDotBtn").style.pointerEvents = "none";
    // document.getElementById("threeDotBtn").click();
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    setsignupModal(true);
    window.scrollTo(0, 0);
    // scrollME();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 2500);
    //setIsMenuActive(false);
  };
  function scrollME() {
    if (!loginModal) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        scrollME();
      }, 500);
    }
  }

  const userSignoutBTNCLK = () => {
    document.body.style.overflow = "hidden";
    setsignoutModal(true);
  };
  setTimeout(() => {
    const profileImage = document.getElementById("profileImage");
    // profileImage.textContent = ;
    // window.intializePage();
  }, 0);
  const setStyle = (event) => {
    // var a = document.createElement('a')
    // a.href = '#contact'
    // a.click()
    setOpenModal("d-block");
    setshowOrHideProjectModal(false);
    setOpenModalGetaQuote("d-none");
    document.getElementById("home").style.pointerEvents = "none";
    document.getElementById("BurgerBtn").click();
  };
  const setqoute = (event) => {
    setOpenModalGetaQuote("d-block");
    setOpenModal("d-none");
    document.body.style.overflow = "hidden";
    document.getElementById("home").style.pointerEvents = "none";
  };
  const hideProjectModal = () => {
    setshowOrHideProjectModal(false);
    document.getElementById("BurgerBtn").click();
  };


  const showFloorPlansPage = (event) => {
    event.preventDefault(); // Prevent default behavior
    // navigate('/floorPlans');
    window.location.href = "/floorPlans";
    setshowOrHideProjectModal(false);
    document.getElementById("BurgerBtn").click();
  };

  const showMainPage = (event) => {
    event.preventDefault();
    window.location.href = "/";
    setshowOrHideProjectModal(false);
    document.getElementById("BurgerBtn").click();
  };

  const [navTop, setNavTop] = useState("32px");
  const [navTopRespo, setnavTopRespo] = useState("28px");
  const [loginIconBtn, setloginIconBtn] = useState("43px");

  useEffect(() => {
    const handleScroll = () => {
      var header = document.getElementById("nav");
      var responsiveHeader = document.getElementById("responsiveNavBar");
      var threeBtn = document.getElementById("loginIconBtn");
      var scrollY = window.scrollY;

      // Change the 'top' property based on the scroll position
      if (scrollY > 0) {
        header.style.top = "0px";
        responsiveHeader.style.top = "0px";
        threeBtn.style.top = "17px";
      } else {
        header.style.top = "32px";
        responsiveHeader.style.top = "28px";
        threeBtn.style.top = "43px";
      }
    };
    // Add or remove the scroll event listener based on showMiniHeader

    if (showMiniHeader) {
      // console.log("scroll - addEventListener");
      document.addEventListener("scroll", handleScroll);
    } else {
      // console.log("Hello else - removeEventListener");
      setNavTop("0px");
      document.removeEventListener("scroll", handleScroll);
    }

    // Cleanup function to remove the event listener when the component is unmounted or when showMiniHeader changes
    return () => {
      // console.log("Cleanup - removeEventListener");
      document.removeEventListener("scroll", handleScroll);
    };
  }, [showMiniHeader]);

  const closeMiniHeader = () => {
    setNavTop("0px");
    setnavTopRespo("0px");
    setloginIconBtn("17px");
    var navItem = document.querySelector(".navItem");
    setshowMiniHeader(false);
  };
  return (
    <>
      {showMiniHeader && (
        <div id="noty-holder">
          <div className="alert alert-info alert-dismissable page-alert custom-notify">
            <button
              type="button"
              className="close notify-close"
              fdprocessedid="oyueal"
              onClick={closeMiniHeader}
              id="miniHeaderClose"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            {/* <a  data-toggle="modal" className="__mPS2id">
          //    <span>Get Offer</span>
          //    <span style={{ fontSize: "10px", fontWeight: 700 }}>〉</span>
          //  </a> */}
            <div className="minHeaderDiv">
              <div className="miniHeaderDiv1">
                <span style={{ cursor: "pointer" }}>
                  <a href="mailto:response@spacifyindia.com">
                    response@spacifyindia.com
                  </a>
                </span>

                <span style={{ cursor: "pointer" }}>
                  <a href="tel:9654788000" className="numberMini">
                    {" "}
                    +91 96547-88000
                  </a>
                </span>
              </div>
              <div className="miniHeaderDiv2">
                <span
                  style={{
                    padding: "0px 8px",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="https://www.facebook.com/spacifyindia"
                    target="_blank"
                  >
                    <i
                      className="fab fa-facebook-f"
                      style={{ color: "#fff" }}
                    ></i>
                  </a>
                </span>
                <span style={{ padding: "0px 8px", cursor: "pointer" }}>
                  <a href="https://twitter.com/spacifyindia" target="_blank">
                    <i className="fab fa-twitter" style={{ color: "#fff" }}></i>
                  </a>
                </span>
                <span style={{ padding: "0px 8px", cursor: "pointer" }}>
                  <a
                    href="https://www.instagram.com/spacifyindia/"
                    target="_blank"
                  >
                    <i
                      className="fab fa-instagram"
                      style={{ color: "#fff" }}
                    ></i>
                  </a>
                </span>
                <span style={{ padding: "0px 8px", cursor: "pointer" }}>
                  <a
                    href="https://www.linkedin.com/in/spacify-india-5394892a8/"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin" style={{ color: "#fff" }}></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* For Get a Quote Modal */}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="LoginModalDiv4  SlidInanimation overff getaQuoteModalDiv4"
          style={{ overflow: "auto" }}
        >
          <div
            className="closebtnforLoginUp"
            style={{ textAlign: "right", fontWeight: "700" }}
          >
            <div className="centerss"></div>
            <button
              className="closeBtn getCloseBtnNav"
              // disabled={singUpBtn}
              // onClick={() => {
              //   // setloggedin(false);
              //   // setsignupModal(false);
              //   // setloginModal(false);
              //   // setuserloggedin(false);
              //   document.body.style.overflow = "auto";
              //   document.getElementById("logoimg").style.pointerEvents = "all";
              //   document.getElementById("threeDotBtn").style.pointerEvents =
              //     "all";
              // }}
              onClick={closeModal}
            >
              &#x2715;
            </button>
          </div>
          <div
            className="loginModalmainDiv"
            style={{
              margin: "0",
              backgroundImage: "url(assets/images/ModalBackImg.jpg)",
            }}
          >
            <div className="SignModalDiv2portion getModalDiv2Portion">
              <div
                style={{ cursor: "default", paddingTop: "10%" }}
                className="px-4"
              >
              <h1
                  className=" fw-bold ls-tight"
                  style={{ fontSize: "1.8rem", textAlign: "left" }}
                >
                  <span style={{ color: "#fff" }}>
                    Full home Interiors, Kitchen &{" "}
                  </span>
                  <br />
                  <span style={{ color: "#e89246" }}>
                    {" "}
                    Wardrobe Office & Workspace Planning
                  </span>
                </h1>
                {/* <p
                  className="pImageGetQuote"
                  style={{
                    color: "white",
                    fontSize: "1vw",
                  }}
                >
                  Experience unmatched quality & timely deliverly with Spacify
                </p> */}
              </div>
              {/* <div className="loginPopContainer border-none">
                  <h1>66</h1>
                  <div className="text-center d-flex align-items-center px-1">
                    <h5>
                      Let <span>Spacify</span> take care of your first
                      impressions{" "}
                    </h5>
                  </div>
                </div> */}
            </div>
            <div
              className="loginModalDiv1portion registerFormModal px-lg-3 px-md-2 px-sm-1"
              style={{ margin: "0", border: "none", width: "44%" }}
            >
              <div className="text-center mr-5">
                {/* <BeatLoader
                    color="#000000"
                    loading={singUpBtn}
                    size={12}
                    style={{
                      zIndex: "5",
                      position: "fixed",
                      marginTop: "15vh",
                    }}
                  /> */}
              </div>
              <div
                className="text-center loginLabel getQuoteLabel"
                style={{
                  fontFamily: "sans-serif",
                  marginTop: "0",
                  paddingTop: "3vh",
                }}
              >
                Book a Free Designer
              </div>
           
              <form>
                <div
                  className=""
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    style={{ width: "94%" }}
                    id="full_Name"
                    name="full_Name"
                    autoComplete="off"
                    value={getDetails.full_Name}
                    maxLength={100}
                    type="text"
                    onChange={handleChange}
                    required="true"
                    placeholder="Name"
                  />
                </div>
                {fnameWarning && (
                  <label
                    style={{ color: "red", marginLeft: "3%", fontSize: "12px" }}
                  >
                    First Name Contains Atleast 3 Characters.
                  </label>
                )}
                {fnameDigitError && (
                  <label
                    style={{ color: "red", marginLeft: "3%", fontSize: "12px" }}
                  >
                    Name Not Contains Digits
                  </label>
                )}
                <div
                  className=""
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    style={{ width: "94%" }}
                    id="email"
                    name="email"
                    autoComplete="off"
                    value={getDetails.email}
                    placeholder="Email Id"
                    type="Email"
                    onChange={handleChange}
                    required="true"
                  />
                </div>
                {emailWarning && (
                  <label
                    style={{ color: "red", marginLeft: "3%", fontSize: "12px" }}
                  >
                    Email Must Be Correct.
                  </label>
                )}
                <div
                  className=""
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    style={{ width: "94%" }}
                    id="phone"
                    name="phone"
                    autoComplete="off"
                    value={getDetails.phone}
                    placeholder="Mobile Number"
                    type="tel"
                    onChange={handleChange}
                    onKeyDown={validatePhoneNumber}
                    required="true"
                    maxLength="10"
                  />
                </div>
                {phoneWarning && (
                  <label
                    style={{ color: "red", marginLeft: "3%", fontSize: "12px" }}
                  >
                    Phone No. Contains 10 Digits
                  </label>
                )}

                <select name="propertyType" id="propertyType"   style={{ width: "94%", color: sProTypeValue === "" ? "#c1bac8" : "#414042" }} onChange={handlePropertyTypeChange} >
                <option value="" disabled selected >  Select Any Type  </option> 
                  <option value="Furniture">Furniture</option>
                  <option value="Residential">Residential</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Space planning">Space planning</option>
                  <option value="kitchen Wardrobe">kitchen Wardrobe</option>  
                </select>
                {propTypeWarning && (
                <label
                  style={{ color: "red", marginLeft: "3%", fontSize: "12px" }}
                >
                  Please select any Type
                </label>
              )}


                <select name="city" id="city"   style={{ width: "94%", color: sCityValue === "" ? "#c1bac8" : "#414042" }}  onChange={cityChange} >   
                <option value="" disabled selected>Select Any City</option>  
                  <option value="Delhi">Delhi</option>
                  <option value="Gurugram">Gurugram</option>
                  <option value="Noida">Noida</option>
                  <option value="Faridabad">Faridabad</option> 
                  <option value="Ghaziabad">Ghaziabad</option>
                  <option value="Other">Other</option> 
                </select>
                {cityWarning && (
                <label
                  style={{ color: "red", marginLeft: "3%", fontSize: "12px" }} 
                >
                  Please select any City
                </label>
              )}


                <input
                  style={{ width: "94%" }}
                  id="query"
                  name="query"
                  autoComplete="off"
                  value={getDetails.query}
                  placeholder="Any Query"
                  type="Text"
                  onChange={handleChange}
                />
              </form>
              <div className="text-center" style={{ marginBottom: "10%" }}>
                <button
                  // disabled={singUpBtn}
                  style={{ cursor: "pointer", width: "94%" }}
                  disabled={bookBtn}
                  onClick={bookBtnClick}
                >
                  Get Quote 
                </button>
              </div>
              <div className="text-center loginModalforgotpasswordSpan"></div>
            </div>
          </div>
        </div>
      </Modal>
      {/* For Get a Quote Modal */}

      <header className="section page-header context-dark" id="home">
        <div className="rd-navbar-wrap tempNav">
          <nav
            id="nav"
            style={{
              position: "fixed",
              top: navTop,
              width: "100%",
              zIndex: 1000,
            }}
            className="rd-navbar"
            data-layout="rd-navbar-fixed"
            data-sm-layout="rd-navbar-fixed"
            data-md-layout="rd-navbar-fixed"
            data-lg-layout="rd-navbar-fixed"
            data-xl-layout="rd-navbar-static"
            data-xxl-layout="rd-navbar-static"
            data-md-device-layout="rd-navbar-fixed"
            data-lg-device-layout="rd-navbar-fixed"
            data-xl-device-layout="rd-navbar-static"
            data-xxl-device-layout="rd-navbar-static"
            data-lg-stick-up-offset="46px"
            data-xl-stick-up-offset="46px"
            data-xxl-stick-up-offset="46px"
            data-lg-stick-up="true"
            data-xl-stick-up="true"
            data-xxl-stick-up="true"
          >
            {/* <div
              id="threeDotBtn"
              style={{ position: "fixed", top: loginIconBtn, zIndex: 9999 , display:"none"}}
              className="rd-navbar-collapse-toggle rd-navbar-fixed-element-1"
              data-rd-navbar-toggle=".rd-navbar-collapse"
            >
              <span style={{ background: "black" }} />
            </div> */}
            {/* In responsive Login Icon */}
            <div
              id="loginIconBtn"
              style={{ position: "fixed", top: loginIconBtn, zIndex: 9999 }}
              className="loginIconInRespNav"
              onClick={userlogin}
            >
              {/* <span style={{padding:"10px", borderRadius:"24px" ,backgroundColor:"black", color:"#fff"}}>
              <i className="fa fa-user" style={{fontSize:"15px"}}></i> 
              </span> */}
              <div className="loginIconInnerDiv">
                <i className="fa fa-user" style={{ fontSize: "15px" }}></i>
              </div>
            </div>
            {/* In responsive Login Icon */}

            <div className="rd-navbar-main-outer">
              <div className="rd-navbar-main">
                <div className="rd-navbar-nav-wrap">
                  <ul className="rd-navbar-nav" style={{ marginBottom: "1%" }}>
                    <li className="rd-nav-item active ">
                      <a href="/">
                        <img
                          // className="col"
                          src="assets/images/LogoImage.png"
                          alt=""
                          width="30"
                          height="30"
                          className="mainLogo"
                        />
                      </a>

                      <a className="homeWithImage" href="/">
                        <p onClick={hideProjectModal}>Home</p>
                      </a>
                    </li>
                    {/* <li
                      className="rd-nav-item"
                      onClick={() => {
                        setshowProfile(false);
                        document.body.style.overflow = "auto";
                      }}
                    >
                      <a
                        className="rd-nav-link"
                        href="#aboutUsNew"
                        onClick={hideProjectModal}
                      >
                        About Us
                      </a>
                    </li> */}
                    <li
                      className="rd-nav-item"
                      onClick={() => {
                        setshowProfile(false);
                        document.body.style.overflow = "auto";
                      }}
                    >
                      <a className="rd-nav-link" href="" onClick={showMainPage}>
                        Our Services
                      </a>
                    </li>
                    <li
                      className="rd-nav-item"
                      onClick={() => {
                        setshowProfile(false);
                        document.body.style.overflow = "auto";
                      }}
                    >
                      <a className="rd-nav-link" href="" onClick={showMainPage}>
                        Portfolio
                      </a>
                    </li>

                    <li
                      className="rd-nav-item"
                      onClick={() => {
                        setshowProfile(false);
                        document.body.style.overflow = "auto";
                      }}
                    >
                      <a
                        className="rd-nav-link"
                        href=""
                        onClick={showFloorPlansPage}
                      >
                        Floor Plans
                      </a>
                    </li>

                    {/* <li
                      className="rd-nav-item"
                      onClick={() => {
                        setshowProfile(false);
                        document.body.style.overflow = "hidden";
                      }}
                    >
                      <p
                        className="rd-nav-link navContact"
                        // href="{JavaScript:void(0)}"
                        href="#ContactUs"
                        // onClick={setStyle}
                      >
                        Contact Us
                      </p>
                    </li> */}
                    <li
                      className="rd-nav-item"
                      onClick={() => {
                        setshowProfile(false);
                        document.body.style.overflow = "auto";
                      }}
                    >
                      <a className="rd-nav-link" href="" onClick={showMainPage}>
                        Contact Us
                      </a>
                    </li>

                    {loggedin ? (
                      <>
                        <li
                          className="rd-nav-item"
                          onClick={() => {
                            setshowProfile(false);
                            document.body.style.overflow = "auto";
                          }}
                        >
                          <a className="rd-nav-link" href="#mytab">
                            My Projects
                          </a>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
                <div
                  id="responsiveNavBar"
                  className="rd-navbar-panel"
                  style={{
                    position: "fixed",
                    top: navTopRespo,
                    width: "100%",
                    zIndex: 1000,
                  }}
                >
                  <button
                    id="BurgerBtn"
                    className="rd-navbar-toggle"
                    data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                    disabled={burgerBtn}
                  >
                    <span />
                  </button>

                  <div className="rd-navbar-brand" id="logoimg">
                    <a href="/">
                      <img
                        src="assets/images/LogoImage.png"
                        alt=""
                        width="20"
                        height="20"
                        className="centerLogo"
                      />
                    </a>
                  </div>
                </div>
                <div className="rd-navbar-collapse">
                  <ul className="list-inline list-inline-md">
                    <li>
                      <p
                        className="mainPageloginButtonforLoginModal loginClass navContact"
                        // onClick={setqoute}
                        id="getAQuoteButton"
                        onClick={openModal}
                        style={{
                          fontWeight: "500",
                          backgroundColor: "#e89246",
                          padding: "10px 20px",
                          color: "#fff",
                          borderRadius: "10px",
                        }}
                      >
                        Get a Quote
                      </p>
                    </li>
                    {loggedin ? (
                      <>
                        <li
                          onClick={showProfilelookup}
                          tabIndex="0"
                          onBlur={blurme}
                        >
                          <a
                            className="nav-link nav-profile d-flex align-items-center pe-0"
                            href="# "
                            onClick={(e) => e.preventDefault()}
                            data-bs-toggle="dropdown"
                            id="user-icon"
                          >
                            <div
                              id="profileImage"
                              style={{
                                padding: "5px",
                                backgroundColor: "white",
                                borderRadius: "100%",
                                color: "black",
                                fontWeight: "700",
                                letterSpacing: "0.02rem",
                              }}
                            ></div>
                            <span
                              style={{ cursor: "pointer", right: "2vh" }}
                              className="d-none d-md-block dropdown-toggle ps-2"
                            >
                              &nbsp;&nbsp;Melody Sunshine
                            </span>
                          </a>
                          {lookup ? (
                            <ul className="profileNavDropDown">
                              <li
                                className="dropdown-header"
                                style={{ cursor: "pointer" }}
                                onClick={test}
                              >
                                <h6>My Profile</h6>
                              </li>
                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                              <li
                                className="dropdown-header"
                                style={{ cursor: "pointer" }}
                              >
                                <h6>
                                  <a
                                    href="#myprojects"
                                    onClick={() =>
                                      setshowOrHideProjectModal(true)
                                    }
                                  >
                                    My Projects
                                  </a>
                                </h6>
                              </li>

                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                              <li
                                className="dropdown-header"
                                style={{ cursor: "pointer" }}
                                onClick={userSignoutBTNCLK}
                              >
                                <h6>Sign Out</h6>
                              </li>
                            </ul>
                          ) : (
                            <></>
                          )}
                        </li>
                      </>
                    ) : (
                      <li style={{ paddingLeft: "0px" }}>
                        <p
                          className="mainPageloginButtonforLoginModalUserIcon loginClass navContactUserIcon"
                          onClick={userlogin}
                          style={{
                            fontWeight: "500",
                            border: "2px solid #e89246",
                            borderRadius: "10px",
                            padding: "9px 20px",
                            background: "#fff",
                            color: "#e89246",
                          }}
                        >
                          Login/Signup
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {loginModal && (
        <Loginpage
          setsignupModal={setsignupModal}
          setloginModal={setloginModal}
          setloggedin={setloggedin}
          setuserloggedin={setuserloggedin}
          setBurgerBtn={setBurgerBtn}
        />
      )}
      {signupModal && (
        <Signup
          setsignupModal={setsignupModal}
          setloginModal={setloginModal}
          setloggedin={setloggedin}
          setuserloggedin={setuserloggedin}
          setBurgerBtn={setBurgerBtn} 
        />
      )}
      {showProfile && (
        <ProfilePage
          setuserloggedin={setuserloggedin}
          setshowProfile={setshowProfile}
          setloggedin={setloggedin}
        />
      )}
      {signoutModal && (
        <div className="signOutModal">
          <div className="signOutModalDiv">
            <div
              style={{
                textAlign: "right",
                marginTop: "-2vh",
                cursor: "pointer",
                fontWeight: "700",
              }}
              onClick={() => {
                setsignoutModal(false);
                document.body.style.overflow = "auto";
              }}
            >
              &#x2715;
            </div>
            <h4 style={{ marginBottom: "4vh" }}>
              Are you sure? you want to Sign out
            </h4>
            <button
              onClick={() => {
                document.body.style.overflow = "auto";
                setsignoutModal(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                document.body.style.overflow = "auto";
                setshowProfile(false);
                setloggedin(false);
                setuserloggedin(false);
                setsignoutModal(false);
                document.body.style.overflow = "auto";
              }}
            >
              Sign Out
            </button>
          </div>
        </div>
      )}
    </>
  );
}
