import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACCESS_TOKEN_API } from "../shared/constants";
import "./Style.css";

export default function ProfilePage({
  setsignupModal,
  setloggedin,
  setloginModal,
  setuserloggedin,
  setBurgerBtn, 
}) {
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRepassword] = useState("");
  const [singUpBtn, setSignUpBtn] = useState(false);

  const validation = () => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; 
    if (email.match(regex)) {
      return true;
    } else {
      toast.error("Invalid email address");
      setSignUpBtn(false);
      return false;
    }
  };

  const Register = () => {
    setSignUpBtn(true);
    toast.dismiss();
    const re = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    if (
      fName === "" ||
      lName === "" ||
      email === "" ||
      password === "" ||
      rePassword === ""
    ) {
      toast.error("Please enter value");
      setSignUpBtn(false);
      return false;
    } else if (validation() === false) {
      setSignUpBtn(false);
      return false;
    } else if (!fName.match(re)) {
      toast.error("Invalid Firstname ");
      setSignUpBtn(false);
    } else if (!lName.match(re)) {
      toast.error("Invalid Lastname");
      setSignUpBtn(false);
    } else {
      if (password.length < 4) {
        setSignUpBtn(false);
        toast.error("Enter Strong Password ");
      } else if (password !== rePassword) {
        setSignUpBtn(false);
        toast.error("Password doesn't match");
      } else {
        document.body.style.overflow = "auto";
        fetch(ACCESS_TOKEN_API, { method: "POST" })
          .then((response) => response.json())
          .then((result) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${result.access_token}`);
            var raw = JSON.stringify({
              firstName: fName,
              lastName: lName,
              userEmail: email.toLowerCase(),
              userPassword: password,
              register: "register",
            });

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };

            fetch(
              `${result.instance_url}/services/apexrest/Interior_App_Api`, 
              requestOptions
            ).then(async (response) => {
              let result = await response.json();
              if (result === "successfully updated") {
                toast.success("Registered Successfully"); 
                setloggedin(false);
                setsignupModal(false);
                setloginModal(false);  
                setuserloggedin(false);
                document.body.style.overflow = "auto";
                document.getElementById("home").style.pointerEvents = "auto";
                document.getElementById("logoimg").style.pointerEvents =
                  "all";
                  setBurgerBtn(false);  

              }
              if (result === "Email Already Exist") {
                toast.error("Email already exist");
              }
              if (result === "Failed") {
                toast.error("Something went wrong");
              }
              setSignUpBtn(false);
            });
          })
          .catch((error) => {});
      }
    }
  };

  setTimeout(() => {
    const profileImage = document.getElementById("profilePageImage");
    profileImage.textContent = "PG";
  }, 0);

  return (
    <div>
      <div>
        <div className="loginModalDiv1 " >
          <div
            className="LoginModalDiv4  SlidInanimation overff"
            style={{ overflow: "auto" }}
          >
            <div
              className=" closebtnforLoginUp"
              style={{ textAlign: "right", fontWeight: "700" }}
            >
              <div className="centerss"></div>
              <button
               className="closeBtn"
                disabled={singUpBtn}
                onClick={() => {
                  setloggedin(false);
                  setsignupModal(false);
                  setloginModal(false);
                  setuserloggedin(false);
                  document.body.style.overflow = "auto";
                  document.getElementById("home").style.pointerEvents = "auto";
                  document.getElementById("logoimg").style.pointerEvents =
                    "all";
                    setBurgerBtn(false);    
                  // document.getElementById("threeDotBtn").style.pointerEvents =
                  //   "all"; 
                 
                }}   
              >
                &#x2715;
              </button>
            </div>
            <div className="loginModalmainDiv" style={{ margin: "0", backgroundImage: "url(assets/images/loginBack.jpg)" }}>
              <div className="SignModalDiv2portion">
                {/* <div
                  style={{ cursor: "default", paddingTop: "10%" }}
                  className="px-4"
                >
                  <h1
                    className=" fw-bold ls-tight"
                    style={{ fontSize: "2.7rem" }}
                  >
                    <span className="text-primary">Welcome </span>
                    <br />
                    <span style={{ color: "rgb(237 200 6)" }}>
                      {" "}
                      We're so glad you've decided to join us.
                    </span>
                  </h1>
                  <p
                    style={{
                      color: "white",
                      fontSize: "1vw",
                    }}
                  >
                    Registering for our website is quick and easy. Once you've
                    completed the registration process, you'll gain access to
                    exclusive content, special offers, and a personalized
                    experience tailored just for you.
                  </p>
                </div> */}
                <div className="loginPopContainer border-none">
                  {/* <h1>66</h1> */}
                  <div className="imgDiv">
                     <img  src="https://i.imgur.com/1VIQPBL.png">
                  </img>
                  </div>
                  <div className="text-center d-flex align-items-center px-1">
                    <h5>
                      Let <span>Spacify</span> take care of your first
                      impressions{" "}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className="loginModalDiv1portion registerFormModal px-lg-3 px-md-2 px-sm-1"
                style={{ margin: "0", border: "none" }}
              >
                <div className="text-center mr-5">
                  <BeatLoader
                    color="#000000"
                    loading={singUpBtn}
                    size={12}
                    style={{
                      zIndex: "5",
                      position: "fixed",
                      marginTop: "15vh",
                    }}
                  />
                </div>
                <div
                  className="text-center loginLabel registerLabel"
                  style={{
                    fontFamily: "sans-serif",
                    marginTop: "0",
                    paddingTop: "3vh",
                  }}
                >
                  Register Yourself Here  
                </div>
                <form>
                  <div
                    className="text-center"
                    style={{
                      marginTop: "1vh",
                      display: "flex",
                      justifyContent: "center",
                      width: "10 0%",
                    }}
                  >
                    <input
                      type="text"
                      id="form3Example1"
                      title="Firstname not contains digits or space"
                      placeholder="First Name"
                      name="email"
                      onChange={(e) => setfName(e.target.value)}
                    />
                    <input
                      type="text"
                      id="form3Example2"
                      title="Lastname not contains digits or space"
                      placeholder="Last Name"
                      name="email"
                      onChange={(e) => setlName(e.target.value)}
                    />
                  </div>
                  <div
                    className=""
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      id="form3Example3"
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ width: "94%" }}
                      placeholder="Email"
                      type={"email"}
                      name="password"
                    />
                  </div>
                  <div
                    className=""
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      id="form3Example4"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      style={{ width: "94%" }}
                      placeholder="Password"
                      type={"password"}
                      name="password"
                    />
                  </div>
                  <div
                    className=""
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      id="form3Example4"
                      onChange={(e) => {
                        setRepassword(e.target.value);
                      }}
                      style={{ width: "94%" }}
                      placeholder="Re-enter Password"
                      type={"password"}
                      name="password"
                    />
                  </div>
                </form>
                <div className="text-center">
                  <button
                    disabled={singUpBtn}
                    style={{ cursor: "pointer", width: "94%" }}
                    id="loginBtn"
                    onClick={Register}
                  >
                    Register
                  </button>
                </div>
                <div className="text-center loginModalforgotpasswordSpan"></div>
                <div className="text-center">
                  <p>
                    <div className="col-xl-12 text-center ">
                      <span style={{ fontSize: "1rem", color: "#414042" }}>
                        Already have account ?
                      </span>
                      <span
                        className="alreadySignin"
                        onClick={() => {
                          setloginModal(true);
                          setsignupModal(false);
                          setloggedin(false);
                        }}
                        style={{ color: "#e89246" }}
                      >
                        Log in
                      </span>
                    </div>
                  </p>
                  {/* <a href="https://www.facebook.com/" className="px-3">
                    {" "}
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="https://www.google.com/" className="px-3">
                    <i className="fab fa-google"></i>{" "}
                  </a>
                  <a href="https://twitter.com/" className="px-3">
                    {" "}
                    <i className="fab fa-twitter"></i>{" "}
                  </a>
                  <a href="https://github.com/" className="px-3">
                    <i className="fab fa-github"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
