// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "jquery";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import 'remixicon/fonts/remixicon.css';
import "./NewReview.css";
export default function NewReview({ responseFlag }) {  
  const [feedbackData, setfeedbackData] = useState([]);
  useEffect(() => {
    if (responseFlag || localStorage.getItem("feedbackWrapList") != null) {
      let Data = JSON.parse(localStorage.getItem("feedbackWrapList"));
      if (Data) {
        setfeedbackData(Data); 
      }
      setTimeout(() => {
        window.initJs();
      }, 1000);
    }
  }, [responseFlag]);  
  // useEffect(() => {
  //     AOS.init(); // Initialize AOS
  //   }, []);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className="reviewSection">
      <div className="clientReviewHead">Happy Homes By Spacify</div>
      <div
        className="parent"
        style={{ paddingLeft: "5vh", paddingRight: "5vh" }}
      >
        <Carousel
          showDots={true}
          responsive={responsive}
          autoPlay
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
          {feedbackData.length > 0 &&
            feedbackData.map((item, idx) => (
              <div className="slider" key={idx}>
                <div className="testimonial">
                  <div className="testimonial-content">
                    <div className="testimonial-icon">
                      <i className="fa fa-quote-left" />
                    </div>
                    <p className="description">
                    {item.feedbackObj.Feedback_Description__c}
                    </p>
                  </div>
                  <h3 className="title"> {item.feedbackObj.Name}</h3>
                  {/* <span className="post">Web Developer</span> */}
                </div>
              </div>
            ))}
          {/* <div className="slider">
            <div className="testimonial">
              <div className="testimonial-content">
                <div className="testimonial-icon">
                  <i className="fa fa-quote-left" />
                </div>
                <p className="description"></p>
              </div>
              <h3 className="title">Steven</h3>
              <span className="post">Web Developer</span>
            </div>
          </div> */}
          {/* <div className="slider">
            <div className="testimonial">
              <div className="testimonial-content">
                <div className="testimonial-icon">
                  <i className="fa fa-quote-left" />
                </div>
                <p className="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent bibendum dolor sit amet eros imperdiet, sit amet
                  hendrerit nisi vehicula.
                </p>
              </div>
              <h3 className="title">Steven</h3>
              <span className="post">Web Developer</span>
            </div>
          </div>
          <div className="slider">
            <div className="testimonial">
              <div className="testimonial-content">
                <div className="testimonial-icon">
                  <i className="fa fa-quote-left" />
                </div>
                <p className="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent bibendum dolor sit amet eros imperdiet, sit amet
                  hendrerit nisi vehicula.
                </p>
              </div>
              <h3 className="title">Steven</h3>
              <span className="post">Web Developer</span>
            </div>
          </div>
          <div className="slider">
            <div className="testimonial">
              <div className="testimonial-content">
                <div className="testimonial-icon">
                  <i className="fa fa-quote-left" />
                </div>
                <p className="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent bibendum dolor sit amet eros imperdiet, sit amet
                  hendrerit nisi vehicula.
                </p>
              </div>
              <h3 className="title">Steven</h3>
              <span className="post">Web Developer</span>
            </div>
          </div> */}
        </Carousel>
      </div>
    </section>
  );
}
