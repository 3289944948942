import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "./Style.css";
import "react-toastify/dist/ReactToastify.css";

export default function ProfilePage({
  setuserloggedin,
}) {
  const [liOne, setliOne] = useState(false);
  const [lifour, setlifour] = useState(false);
  const [userFname, setUserFname] = useState("");
  const [userLname, setUserLname] = useState("");
  const [usercity, setUserCity] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userCoutnry, setUserCoutnry] = useState("");
  const [userState, setUserState] = useState("");
  const [userStreet, setUserStreet] = useState("");
  useEffect(() => {
    var DETA = JSON.parse(localStorage.getItem("SpacifyData"));
    setUserFname(DETA.ContactList.FirstName);
    setUserLname(DETA.ContactList.LastName);
    setUserCity(DETA.ContactList.MailingCity);
    setUserEmail(DETA.ContactList.Email);
    setUserPhone(DETA.ContactList.Phone);
    setUserCoutnry(DETA.ContactList.MailingCountry);
    setUserState(DETA.ContactList.MailingState);
    setUserStreet(DETA.ContactList.MailingStreet);
  }, []);
  setTimeout(() => {
    var DETA = JSON.parse(localStorage.getItem("SpacifyData"));
    let fLetter =
      DETA.ContactList.FirstName.charAt(0) +
      DETA.ContactList.LastName.charAt(0);
    const profileImage = document.getElementById("profilePageImage");
    profileImage.textContent = fLetter;
  }, []);

  return (
    <>
      <ToastContainer />
      <div
        style={{ backgroundColor: "", overflow: "auto" }}
        id="mytab"
        className="progileBack"
      >
        <div className="pagetitle opacity1animation">
          <h4 style={{ paddingTop: "4vh", paddingLeft: "4vh", color: "" }}>
            Profile
          </h4>
        </div>
        <section
          className="section card py-2 profile opacity1animation"
          style={{ marginRight: "3vh" }}
        >
          <div className="row">
            <div className="col-xl-4" style={{ marginBottom: "4vh" }}>
              <div className="card profileUserNameCard">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center  ">
                  <div
                    id="profilePageImage"
                    className="profilePageUserIcon"
                  ></div>
                  <div className="social-links mt-2 ">
                    <a  href="https://twitter.com/spacifyindia">
                    <i className="fa fa-twitter editSocialIcon "></i></a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="https://www.facebook.com/spacifyindia">
                    <i className="fa fa-facebook editSocialIcon "></i></a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="https://www.instagram.com/spacifyindia/">
                    <i className="fa fa-instagram editSocialIcon "></i></a>
                    &nbsp;&nbsp;&nbsp;
                    <a  href="https://www.linkedin.com/in/spacify-india/">
                    <i className="fa fa-linkedin editSocialIcon "></i></a>
                    &nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-8">
              <div
                className="card profileOptions "
                style={{ marginLeft: "20px" }}
              >
                <ul style={{ padding: "0" }}>
                  <li
                    style={{
                      backgroundColor: "wheat",
                      cursor: "default",
                      paddingTop: "4px",
                      paddingLeft: "10px",
                      fontFamily: "revert",
                      fontWeight: "700",
                      letterSpacing: "1px",
                      fontSize: "18px",
                    }}
                  >
                    User Information
                    <hr
                      className="dropdown-divider"
                      style={{ color: "wheat" }}
                    />
                  </li>

                  <li
                    className="profileOptionsli"
                    onClick={() => (liOne ? setliOne(false) : setliOne(true))}
                  >
                    Personal Information
                  </li>
                  {liOne ? (
                    <li>
                      <div>
                        <div className="row profileInputs">
                          <div className="col-xl-2">
                            <h6>
                              First Name<span style={{ color: "red" }}>*</span>
                            </h6>
                          </div>
                          <div className="col-xl-4">
                            <input
                              value={userFname}
                              style={{ fontWeight: "400" }}
                            ></input>
                          </div>
                          <div className="col-xl-2">
                            <h6>
                              Last Name<span style={{ color: "red" }}>*</span>
                            </h6>
                          </div>
                          <div className="col-xl-4">
                            <input
                              value={userLname}
                              style={{ fontWeight: "400" }}
                            ></input>
                          </div>
                          <div className="col-xl-2">
                            <h6>
                              Email<span style={{ color: "red" }}>*</span>
                            </h6>
                          </div>
                          <div className="col-xl-4">
                            <input
                              value={userEmail}
                              style={{ fontWeight: "400" }}
                            ></input>
                          </div>
                          <div className="col-xl-2">
                            <h6>
                              Phone<span style={{ color: "red" }}>*</span>
                            </h6>
                          </div>
                          <div className="col-xl-4">
                            <input
                              value={userPhone}
                              style={{ fontWeight: "400" }}
                            ></input>
                          </div>
                          <div className="col-xl-2">
                            <h6>Company</h6>
                          </div>
                          <div className="col-xl-4">
                            <input
                              value={userCompany}
                              style={{ fontWeight: "400" }}
                            ></input>
                          </div>
                          <div className="col-xl-2">
                            <h6>Street</h6>
                          </div>
                          <div className="col-xl-4">
                            <input
                              value={userStreet}
                              style={{ fontWeight: "400" }}
                            ></input>
                          </div>
                          <div className="col-xl-2">
                            <h6>City</h6>
                          </div>
                          <div className="col-xl-4">
                            <input
                              value={usercity}
                              style={{ fontWeight: "400" }}
                            ></input>
                          </div>
                          <div className="col-xl-2">
                            <h6>State</h6>
                          </div>
                          <div className="col-xl-4">
                            <input
                              value={userState}
                              style={{ fontWeight: "400" }}
                            ></input>
                          </div>
                          <div className="col-xl-2">
                            <h6>Country</h6>
                          </div>
                          <div className="col-xl-4">
                            <input
                              value={userCoutnry}
                              style={{ fontWeight: "400" }}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <hr className="dropdown-divider" />
                    </li>
                  ) : (
                    <></>
                  )}
                  <li
                    className="profileOptionsli"
                    style={{ marginBottom: "10px", borderBottom: "none" }}
                    onClick={() => {
                      lifour ? setlifour(false) : setlifour(true);
                      document.getElementById("header").style.pointerEvents =
                        "none";
                    }}
                  >
                    Sign Out
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {lifour ? (
          <div className="signOutModal">
            <div className="signOutModalDiv ">
              <div
                style={{
                  textAlign: "right",
                  marginTop: "-2vh",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={() => {
                  document.getElementById("header").style.pointerEvents =
                    "auto";
                  setlifour(false);
                }}
              >
                &#x2715;
              </div>
              <h4 style={{ marginBottom: "4vh" }}>
                Are you sure? you want to Sign out
              </h4>
              <button
                onClick={() => {
                  setlifour(false);
                  document.getElementById("header").style.pointerEvents =
                    "auto";
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  window.location.reload();
                  document.getElementById("header").style.pointerEvents =
                    "auto";
                  setuserloggedin(false);
                  localStorage.removeItem("isLogin");
                }}
              >
                Sign Out
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="loading d-none" id="spinner">
          Loading&#8230;
        </div>
      </div>
    </>
  );
}
