import React from "react";
import "./portfolioModal.css";
export default function portfolioModal({ setpModalStyle, propertyData }) {
  const setStyleGetaQuote = (event) => {
    document.getElementById("nav").style.opacity = 1;
    document.getElementById("nav").style.zIndex = 999;
    setpModalStyle("d-none");
    document.getElementById("home").style.pointerEvents = "auto";
    document.body.style.overflowY = "scroll";
  };

  return (
    <div className="modalBackground1">
      <div className="modalContainer1" style={{ overflow: "hidden" }}>
        <div className="get_quote_close_btn">
          <button onClick={setStyleGetaQuote}>&#x2716; </button>
        </div>
        <div
          style={{
            backgroundColor: "white",
            zIndex: "1",
          }}
          id="portfolio"
          className="scroll propertyModalContent"
        >
          <h2 className="title-single" style={{ textAlign: "center" }}>
            {propertyData.title}
          </h2>
          <br />
          <section className="property-single nav-arrow-b">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div
                    id="property-single-carousel"
                    className="swiper text-center"
                  >
                    <div className="swiper-wrapper">
                      <div className="carousel-item-d swiper-slide" key="v">
                        <img
                          src={propertyData.imgsrc}
                          alt=""
                          height={500}
                          width={400}
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="property-single-carousel-pagination carousel-pagination"></div>
                </div>
              </div>
              <br />
              <div>
                <div className="col-sm-12">
                  <div className="row justify-content-between">
                    <div className="col-md-5 col-lg-4">
                      <div className="property-summary">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="title-box-d section-t4">
                              <h3 className="title-d">Quick Summary</h3>
                            </div>
                          </div>
                        </div>
                        <div className="summary-list">
                          <ul className="list UlModal" >          
                            <li className="d-flex justify-content-between">
                              <strong>Location:</strong>
                              <span>{propertyData.ProjectType}</span>
                            </li>
                            <li className="d-flex justify-content-between">
                              <strong>Project Year:</strong>
                              <span>{propertyData.Year} </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 col-lg-7 section-md-t3">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="title-box-d">
                            <h3 className="title-d">Portfolio Description</h3>
                          </div>
                        </div>
                      </div>
                      {propertyData.Portfolio_Description__c}
                      <div className="property-description">
                        <p className="description color-text-a">
                          {propertyData.Description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
