import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Toaster } from "react-hot-toast";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ACCESS_TOKEN_API } from "../shared/constants";
import "./Style.css";

export default function Project({
  showOrHideProjectModal,
  setshowOrHideProjectModal,
  setCurrentTab,
  setRequest,
}) {
  const [pojectCards, setProjectCards] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [uploadFIleData, setUploadFileData] = useState({});
  const [fileRequestedImages, setFIleRequestedImages] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isShowThisProject, setIsShowThisProject] = useState(false);
  const [uploadFILEbtn, setUploadFILEbtn] = useState(true);
  const [loading, setLoading] = useState(true);
  const [progressbar, setProgressbar] = useState(0);
  const [myAccessToken, setmyAccessToken] = useState();
  const [myFiles, setMyFiles] = useState([]);

  useEffect(() => {
    var data = localStorage.getItem("element");
    if (isShowThisProject === true) {
      showThisProject(JSON.parse(data));

      setIsShowThisProject(false);
    }
    setLoading(false);
  }, [flag]);

  var allFileMainData = [];

  var j = 1;

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("id") === "00D2w000RpXvr") {
      toast.success(
        "Thank you for contacting us. We will reach out to you soon."
      );

      window.history.replaceState({}, "", window.location.pathname);
    }
    fetch(ACCESS_TOKEN_API, { method: "POST" })
      .then((response) => response.json())
      .then((result) => {
        setmyAccessToken(result);
        //console.log("AccessToken==>", myAccessToken);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${result.access_token}`);
        var raw = JSON.stringify({
          username: localStorage.getItem("Spacify_CloudCertitude_Username"),
          password: localStorage.getItem("Spacify_CloudCertitude_Password"),
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          `${result.instance_url}/services/apexrest/Interior_App_Api`,
          requestOptions
        ).then(async (response) => {
          let result = await response.json();

          let data = JSON.parse(result);

          localStorage.setItem("SpacifyData", result);
          setFlag(true);
        });
      })
      .catch((error) => {});
    document.body.style.backgroundColor = "white";
    var requestList = [];
    var idList = [];
    var DETA2 = JSON.parse(localStorage.getItem("SpacifyData"));
    var DETA3 = DETA2.ProjectImgWrapList;
    DETA2 = DETA2.Checklist_Item;

    DETA2.forEach((element) => {
      if (element.Status__c === "File Requested") {
        DETA3.forEach((item) => {
          if (item.projectObj.Id === element.Record_Id__c) {
            var obj = {
              Id: element.Id,
              probj: item,
              task: element,
              flag: true,
            };
            requestList.push(obj);
            idList.push(element.Id);
          }
        });
      }
    });
    //console.log(idList);
    var tempList = [];
    var data = JSON.parse(localStorage.getItem("notificationData"));
    if (data) {
      data.forEach((item) => {
        if (idList.includes(item.Id)) {
          tempList.push(item);
        }
      });
      localStorage.setItem("notificationData", JSON.stringify(tempList));
    }
    setRequest(requestList);
    var DETA = JSON.parse(localStorage.getItem("SpacifyData"));
    //console.log(DETA);
    setProjectCards(DETA.ProjectImgWrapList);  
  }, [projectData]);

  const dayDuration = (d1, d2) => {
    var date1 = new Date(d1);
    var date2 = new Date(d2);
    var diffMs = Math.abs(date2 - date1);
    var diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const getMilestoneWithSequence = (projectName, projectId) => {
    var DETA = JSON.parse(localStorage.getItem("SpacifyData"));
    DETA = DETA.projectList;
    var completedList = [];
    var processingList = [];
    var notStartedList = [];

    DETA.forEach((ele) => {
      if (
        ele.Status__c === "Completed" &&
        ele.Project__r.Name === projectName &&
        ele.Project__r.Id === projectId
      ) {
        var tasks = [];
        if (ele.hasOwnProperty("Tasks")) {
          tasks = getTaskinSequence(ele.Tasks);
        }
        var eachMile = {
          name: ele.Name,
          status: ele.Status__c,
          description: ele.Description__c,
          dueDate: ele.Due_Date__c,
          warnDate: ele.Warning_Date__c,
          completeDate: ele.Completed_Date__c,
          tasks: tasks,
        };
        completedList.push(eachMile);
      }
    });
    DETA.forEach((ele) => {
      if (
        ele.Status__c === "In Progress" &&
        ele.Project__r.Name === projectName &&
        ele.Project__r.Id === projectId
      ) {
        var tasks = [];
        if (ele.hasOwnProperty("Tasks")) {
          tasks = getTaskinSequence(ele.Tasks);
        }
        var eachMile = {
          name: ele.Name,
          status: ele.Status__c,
          description: ele.Description__c,
          dueDate: ele.Due_Date__c,
          warnDate: ele.Warning_Date__c,
          tasks: tasks,
        };
        processingList.push(eachMile);
      }
    });
    DETA.forEach((ele) => {
      if (
        ele.Status__c === "Not Started" &&
        ele.Project__r.Name === projectName &&
        ele.Project__r.Id === projectId
      ) {
        var tasks = [];
        if (ele.hasOwnProperty("Tasks")) {
          tasks = getTaskinSequence(ele.Tasks);
        }
        var eachMile = {
          name: ele.Name,
          status: ele.Status__c,
          description: ele.Description__c,
          dueDate: ele.Due_Date__c,
          warnDate: ele.Warning_Date__c,
          taskflag: false,
          tasks: tasks,
        };
        notStartedList.push(eachMile);
      }
    });

    var mainList2 = [...completedList, ...processingList, ...notStartedList];
    return mainList2;
  };

  const getTaskinSequence = (deta) => {
    var completedList = [];
    var pendingList = [];
    var notStartedlist = [];
    var mainTasklist = [];
    deta.records.forEach((element) => {
      if (element.Status === "Not Started") {
        notStartedlist.push(element);
      } else if (element.Status === "Completed") {
        completedList.push(element);
      } else {
        pendingList.push(element);
      }
    });
    mainTasklist = [...completedList, ...pendingList, ...notStartedlist];
    return mainTasklist;
  };

  const showThisProject = (element) => {
    setMyFiles([]);
    setIsShowThisProject(true);
    localStorage.setItem("element", JSON.stringify(element));
    var DETA = JSON.parse(localStorage.getItem("SpacifyData"));
    var projectName = element.projectObj.Name;
    var projectId = element.projectObj.Id;

    var projectImages = element.ProjectImageUrlList;
    var projectMaterials = getMaterials(projectName, projectId);
    var projestDetails = getProjectDetails(projectName, projectId);
    var mileStone = getMilestoneWithSequence(projectName, projectId);
    var checklist = getCheckListinSequence(projectId);
    var mainObj = {
      Name: projectName,
      Id: projectId,
      description: element.projectObj.Description__c,
      images: projectImages,
      details: projestDetails,
      materials: projectMaterials,
      milstone: mileStone,
      checklist: checklist,
    };
    //console.log("Enter the world==> ", mainObj);
    setProjectData(mainObj);
    setshowOrHideProjectModal(true);
    setCurrentTab("Dashboard / My Projects");
  };

  const handleRequestMyFiles = (e) => {
    e.target.querySelector("i").classList.add("fa-spin");
    e.target.disabled = "true";
    var result = myAccessToken;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${result.access_token}`);
    var raw = JSON.stringify({
      username: localStorage.getItem("Spacify_CloudCertitude_Username"),
      password: localStorage.getItem("Spacify_CloudCertitude_Password"),
      myFiles: "Request for My Files",
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    //console.log(requestOptions);
    fetch(
      `${result.instance_url}/services/apexrest/Interior_App_Api`,
      requestOptions
    ).then(async (response) => {
      let result2 = await response.json();
      let data = JSON.parse(result2);
      var obj = { flag: false };
      var tempLi = [];
      tempLi.push(obj);
      //console.log("Last Stage==>", data);
      var files = showmyfiles(projectData.Id, data.MilestoneWrapList);
      //console.log(files);
      {
        files.length > 0 ? setMyFiles(files) : setMyFiles(tempLi);
      }
      e.target.querySelector("i").classList.remove("fa-spin");
    });
  };

  const showmyfiles = (projectId, data) => {
    var myList = data;
    var tempList = [];
    myList.forEach((item) => {
      if (item.checkObj.Record_Id__c === projectId) {
        item.imageUrlList.forEach((element) => {
          var obj = { obj: item.checkObj, url: element };
          tempList.push(obj);
        });
      }
    });
    //console.log(tempList);
    return tempList;
  };

  const getProjectDetails = (projectName, projectId) => {
    var DETA = JSON.parse(localStorage.getItem("SpacifyData"));
    var proj = {};
    DETA = DETA.projectList;
    DETA.forEach((item) => {
      if (item.Name === projectName && item.Id === projectId) {
        proj = item;
        return proj;
      }
    });
    return proj;
  };

  const getMaterials = (name, Id) => {
    var DETA = JSON.parse(localStorage.getItem("SpacifyData"));
    var mYlist = [];
    DETA = DETA.ProjectMaterial;
    DETA.forEach((item) => {
      if (item.Project__r.Name === name && item.Project__r.Id === Id) {
        mYlist.push(item);
      }
    });
    return mYlist;
  };

  const getCheckListinSequence = (projectId) => {
    var NameList = [];
    var tempcheckList = [];
    var checkList = [];
    var myI = 0;
    var DETA = JSON.parse(localStorage.getItem("SpacifyData"));
    DETA = DETA.Checklist_Item;
    console.clear()

    DETA.forEach((item,idx) => {
       //console.log(item.Sequence_No__c,'Index==>',idx);
      if (
        !NameList.includes(item.Group__c) &&
        projectId === item.Record_Id__c
      ) {
        NameList.push(item.Group__c);
        tempcheckList.push(item)
        //console.log(idx,item);
        myI++;
      }
    });
    console.clear()
    tempcheckList.sort((a,b)=>a.Sequence_No__c - b.Sequence_No__c)
    //console.log(tempcheckList);
    tempcheckList.forEach((item) => {
      var object = { name: item.Group__c, checks: [], checkflag: false };
      DETA.forEach((eachitem) => {
        if (eachitem.Group__c === item.Group__c && eachitem.Record_Id__c === projectId) {
          object.checks.push(eachitem);
        }
      });
      object.checks.sort((a,b)=>a.Rank__c - b.Rank__c)
      checkList.push(object);
    });
    //console.log(checkList);
    // NameList.forEach((item) => {
    //   var object = { name: item, checks: [], checkflag: false };
    //   DETA.forEach((eachitem) => {
    //     if (eachitem.Group__c === item && eachitem.Record_Id__c === projectId) {
    //       object.checks.push(eachitem);
    //     }
    //   });
    //   checkList.push(object);
    // });
    return checkList;
  };
  const showList = (idx) => {
    const temp = { ...projectData };
    temp.checklist[idx].checkflag = !temp?.checklist[idx]?.checkflag;
    setProjectData(temp);
  };

  const getUploadFiles = (e) => {
    allFileMainData = [];
    if (e.target.files.length > 0) {
      setUploadFILEbtn(false);
    } else {
      setUploadFILEbtn(true);
    }

    for (let i = 0; i < e.target.files.length; i++) {
      var file = e.target.files[i];
      dataOfFile(file);
    }

    setFIleRequestedImages(allFileMainData);
  };
  function dataOfFile(file) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var fileContent = reader.result;
      var base64 = "base64,";
      var dataStart = fileContent.indexOf(base64) + base64.length;
      fileContent = fileContent.substring(dataStart);
      var fileObj = {
        fileName: file.name,
        fileType: file.type,
        fileContent: fileContent,
        Size: file.size,
      };
      allFileMainData.push(fileObj);
      return fileContent;
    };
    reader.readAsDataURL(file);
  }

  const submitUploadFIle = () => {
    //console.log("Loading=" + loading);
    setLoading(true);
    setUploadFILEbtn(true);
    //console.log("Loading=" + loading);
    var obj = {
      projectId: uploadFIleData.projectId,
      checkId: uploadFIleData.checkId,
      Files: fileRequestedImages,
    };
    fetch(ACCESS_TOKEN_API, { method: "POST" })
      .then((response) => response.json())
      .then(async (result) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${result.access_token}`);
        var fileData = [];

        for (var i = 0; i < fileRequestedImages.length; i++) {
          fileData[i] = {
            attributes: {
              type: "ContentVersion",
              referenceId: "ref" + i,
            },
            VersionData: fileRequestedImages[i].fileContent,
            PathOnClient: fileRequestedImages[i].fileName,
            Description: "Test" + i,
          };
        }
        var raw = JSON.stringify({
          records: fileData,
        });
        var result2 = [];
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        const url = `${result.instance_url}/services/data/v43.0/composite/tree/ContentVersion`;
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${result.access_token}`,
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.round((loaded * 100) / total);
            setProgressbar(percentCompleted);
          },
        };
        try {
          const response = await axios.post(url, raw, config);
          let result1 = response.data;
          result2.push(result1.results);
          var idArr = [];
          for (var i = 0; i < result2?.[0].length; i++) {
            idArr.push(result2[0][i].id);
          }
          var obj1 = {
            projectId: uploadFIleData.projectId,
            checkId: uploadFIleData.checkId,
            refId: idArr,
          };

          var raw1 = JSON.stringify({
            requestedFile: obj1,
          });

          var requestOptions1 = {
            method: "POST",
            headers: myHeaders,
            body: raw1,
            redirect: "follow",
          };

          fetch(
            `${result.instance_url}/services/apexrest/Interior_App_Api`,
            requestOptions1
          ).then(async (response) => {
            let result = await response.json();
            if (result === "Successfully upload") {
              var allDeta = { ...projectData };
              allDeta.checklist.forEach((element) => {
                element.checks.forEach((item) => {
                  if (
                    item.Id === obj.checkId &&
                    item.Status__c === "File Requested"
                  ) {
                    item.Status__c = "File Received";
                  }
                });
              });
              setProjectData(allDeta);
              document.getElementById("header").style.pointerEvents = "auto";
              setUploadFileModal(false);
              setUploadFILEbtn(true);
              setLoading(false);
              toast.success("Uploaded successfully");
              setProgressbar(0);
            } else {
              toast.error("something went wrong");
              setUploadFILEbtn(false);
              setLoading(false);
              setProgressbar(0);
            }
          });
        } catch (error) {
          console.error(error);
        }
      })
      .catch((e) => {});
  };

  return (
    <div id="mytab">
      {showOrHideProjectModal ? (
        <>
          <div className="row ">
            <div className="col-12">
              <div className="overff2 projectMainDiv">
                <div className="projectBackgroundDiv ">
                  <div
                    className="text-hover8"
                    style={{
                      color: "white",
                      width: "100%",
                      position: "absolute",
                      paddingLeft: "6vh",
                      fontSize: "1.5rem",
                      cursor: "default",
                      zIndex: "2",
                    }}
                  >
                    <button
                      style={{
                        color: "white",
                        position: "relative",
                        backgroundColor: "transparent",
                        border: "none",
                        fontSize: "2rem",
                      }}
                      onClick={() => {
                        setshowOrHideProjectModal(false);
                        setCurrentTab("Dashboard");
                      }}
                    >
                      &#x2190;
                    </button>
                  </div>
                  <div className="projectDiv1">
                    <h1
                      className="SlidInanimation"
                      style={{ fontFamily: "Calibri" }}
                    >
                      <p>
                        <a
                          href="#"
                          id="style-2"
                          data-replace={projectData.Name}
                          className="text-hover6"
                          style={{ cursor: "default" }}
                        >
                          <span>{projectData.Name}</span>
                        </a>
                      </p>
                    </h1>
                  </div>
                </div>
                <div className="projectCenterDiv SlidInanimation">
                  <div className="projectCenterDiv2 SlidInanimation">
                    <div
                      className="row "
                      style={{ marginTop: "12vh", borderRadius: "3px" }}
                    >
                      <div
                        className="col-sm-5 text-left "
                        style={{
                          backgroundColor: "transparent",
                          marginBottom: "11vh",
                        }}
                      >
                        <div
                          className="card transparent border-none "
                          style={{ backgroundColor: "transparent" }}
                        >
                          <div className="card-body border-none SlidInanimation">
                            <h5
                              className="card-title colorwhite border-none text-hover5 skew-1-1"
                              style={{
                                letterSpacing: "3px",
                                fontSize: "2.5rem",
                                fontFamily: "Calibri",
                                cursor: "default",
                              }}
                            >
                              {projectData.Name}
                            </h5>
                            <p
                              className="card-text colorwhite border-none"
                              style={{
                                fontFamily: "monospace",
                                letterSpacing: "0.2px",
                                cursor: "default",
                              }}
                            >
                              <br></br>
                              <b
                                style={{ color: "#ffda25", cursor: "default" }}
                              >
                                <u>Description of Project :</u>
                              </b>{" "}
                              {projectData.details.Description__c
                                ? projectData.details.Description__c
                                : "No descriptoin fouond"}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2"></div>
                      <div
                        className="col-sm-4 relative "
                        style={{ marginBottom: "11vh" }}
                      >
                        <div className="ProjectImagesCard relative">
                          <div className="ProjectImagesCard">
                            <img
                              src={
                                projectData.images[
                                  projectData.images.length - 1
                                ]
                              }
                              alt="No preview available"
                            />
                          </div>
                          <div className="details">
                            <div className="center">
                              <h1 style={{ fontFamily: "monospace" }}>
                                Interior Design
                                <br />
                                <span>Team leader</span>
                              </h1>
                              <p style={{ fontFamily: "revert" }}>
                                {projectData.details.Comments__c}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-1"></div>
                    </div>
                    <div className="row SlidInanimation">
                      {projectData.images.map((img) => (
                        <div className="col-md-3 py-4">
                          <div className="imagesProject">
                            <img src={img} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 ">
              <div className="card py-2">
                <div className="card-body ">
                  <h5 className="card-title border-bottom ">Project Details</h5>
                  <div
                    className="overff3"
                    style={{ overflow: "scroll", height: "28rem" }}
                  >
                    <table className="table table-borderless datatable   detailTable">
                      <tbody>
                        <tr>
                          <th
                            scope="row"
                            style={{ color: "blue", fontWeight: "bold" }}
                          >
                            Project Number
                          </th>
                          <td>{projectData.details.Project_Number__c}</td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            style={{ color: "blue", fontWeight: "bold" }}
                          >
                            Start Date
                          </th>
                          <td>{projectData.details.Start_Date__c}</td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            style={{ color: "blue", fontWeight: "bold" }}
                          >
                            Planned Completion Date
                          </th>
                          <td>
                            {
                              projectData.details
                                .Planned_Project_Completion_Date__c
                            }
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            style={{ color: "blue", fontWeight: "bold" }}
                          >
                            Total Duration
                          </th>
                          <td>
                            {dayDuration(
                              projectData.details.Start_Date__c,
                              projectData.details
                                .Planned_Project_Completion_Date__c
                            )}{" "}
                            Days
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            style={{ color: "blue", fontWeight: "bold" }}
                          >
                            Budget
                          </th>
                          <td>{projectData.details.Budget__c}</td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            style={{ color: "blue", fontWeight: "bold" }}
                          >
                            Priority
                          </th>
                          <td>{projectData.details.Priority__c}</td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            style={{ color: "blue", fontWeight: "bold" }}
                          >
                            Completion Percentage
                          </th>
                          <td>
                            {projectData.details.Completion_Percentage__c !==
                            0 ? (
                              <div
                                className="progress m1-3"
                                style={{
                                  height: "2vh",
                                  borderRadius: "7px",
                                  backgroundColor: "wheat",
                                }}
                              >
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width:
                                      projectData.details
                                        .Completion_Percentage__c + "%",
                                    height: "2vh",
                                    borderRadius: "7px",
                                  }}
                                  aria-valuenow={
                                    projectData.details.Completion_Percentage__c
                                  }
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {projectData.details.Completion_Percentage__c}{" "}
                                  %
                                </div>
                              </div>
                            ) : (
                              <div
                                className="progress m1-3"
                                style={{
                                  height: "2vh",
                                  borderRadius: "7px",
                                  backgroundColor: "wheat",
                                }}
                              >
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: "4vh",
                                    height: "2vh",
                                    borderRadius: "7px",
                                    color: "black",
                                    backgroundColor: "transparent",
                                  }}
                                  aria-valuenow="5"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  0%
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            style={{ color: "blue", fontWeight: "bold" }}
                          >
                            Status
                          </th>
                          <td>{projectData.details.Project_Status__c}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card py-2 ">
                <div className="card-body">
                  <h5 className="card-title border-bottom">
                    Milestones <span>| Latest update</span>
                  </h5>
                  <Tooltip id="my-tooltip" style={{ zIndex: "999" }} />
                  <div
                    className="activity overff3"
                    style={{ overflow: "scroll", height: "28rem" }}
                  >
                    {projectData.checklist.length > 0 ? (
                      <>
                        {projectData.checklist.map((element, i) => (
                          <div
                            className="alert alert-light disable-text-selection overff"
                            role="alert"
                            style={{ backgroundColor: "#e3e2e2",overflowX:"scroll"}}
                          >
                            {element.checkflag ? (
                              <div
                                className=""
                                style={{ cursor: "pointer" }}
                                onClick={() => showList(i)}
                              >
                                <table>
                                  <tr>
                                    <td style={{fontSize:'1.2rem'}} ><b><i className="fa fa-angle-down" /></b></td>
                                    <td className="px-2"> <b> {element.name}</b></td>
                                  </tr>
                                </table>                               
                              </div>
                            ) : (
                              <div
                                className=""
                                style={{ cursor: "pointer" }}
                                onClick={() => showList(i)}
                              >
                                 <table>
                                  <tr>
                                    <td><b><i className="fa fa-angle-right" /></b></td>
                                    <td className="px-2"> <b> {element.name}</b></td>
                                  </tr>
                                </table>  
                              </div>
                            )}
                            {element.checks.length > 0 && element.checkflag ? (
                              <>
                                {element.checks.map((ele, j) => (
                                  <div className="activity-item d-flex ">
                                    <div
                                      className="activite-label showInSlowanim"
                                      style={{ minWidth: "25px" }}
                                    >
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#f23f5b",
                                          fontSize: "1rem",
                                          fontWeight: "600",
                                        }}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Milestone not started"
                                        data-tooltip-place="top"
                                      >
                                        <i className="fa-regular fa-calendar-clock"></i>
                                      </span>{" "}
                                    </div>

                                    {ele.Status__c === "Completed" ? (
                                      <i
                                        className="bi bi-circle-fill activity-badge text-success align-self-start"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={ele.Status__c}
                                        data-tooltip-place="left"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {ele.Status__c === "Open" ? (
                                      <i
                                        className="bi bi-circle-fill activity-badge text-danger align-self-start"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={ele.Status__c}
                                        data-tooltip-place="left"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {ele.Status__c === "Closed" ? (
                                      <i
                                        className="bi bi-circle-fill activity-badge text-success align-self-start"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={ele.Status__c}
                                        data-tooltip-place="left"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {ele.Status__c === "File Requested" ? (
                                      <i
                                        className="bi bi-circle-fill activity-badge text-danger align-self-start"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={ele.Status__c}
                                        data-tooltip-place="left"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {ele.Status__c === "File Received" ? (
                                      <i
                                        className="bi bi-circle-fill activity-badge text-danger align-self-start"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={ele.Status__c}
                                        data-tooltip-place="left"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {ele.Status__c === "Not Started" ? (
                                      <i
                                        className="bi bi-circle-fill activity-badge text-danger align-self-start"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={ele.Status__c}
                                        data-tooltip-place="left"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {ele.Status__c === "In Progress" ? (
                                      <i
                                        className="bi bi-circle-fill activity-badge text-danger align-self-start"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={ele.Status__c}
                                        data-tooltip-place="left"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {ele.Status__c === "N/A" ? (
                                      <i
                                        className="bi bi-circle-fill activity-badge text-danger align-self-start"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={ele.Status__c}
                                        data-tooltip-place="left"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {ele.Status__c === "File Requested" ? (
                                      <div
                                        className="activity-content"
                                        style={{
                                          paddingBottom: "14px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setUploadFileModal(true);
                                          var obj = {
                                            projectId: projectData.Id,
                                            checkId: ele.Id,
                                            ele: ele,
                                          };
                                          setUploadFileData(obj);
                                          document.getElementById(
                                            "header"
                                          ).style.pointerEvents = "none";
                                        }}
                                      >
                                        <b>{ele.Name}</b> &nbsp;{" "}
                                        <i className="fa fa-exclamation-circle hover-zoom" />
                                      </div>
                                    ) : (
                                      <div
                                        className="activity-content"
                                        style={{ paddingBottom: "14px" }}
                                      >
                                        {ele.Name}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="text-center py-4">No record found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card py-2">
                <div className="card-body  " style={{ height: "35rem" }}>
                  <h5 className="card-title">Calendar</h5>
                  <div
                    className="border-top py-3 px-3 calendar-container text-center react-calendar"
                    style={{
                      border: "none",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Calendar />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {projectData.materials.length > 0 ? (
              <div className="col-12 my-4">
                <div
                  className="card recent-sales overflow-auto overff3"
                  style={{ maxHeight: "40rem" }}
                >
                  <div className="card-body m-0 p-0">
                    <h5 className="card-title mx-4 my-2">
                      Project Material <span>| &nbsp; Details </span>
                    </h5>
                    <table className="table table-borderless datatable py-2 border-top ">
                      <thead style={{ backgroundColor: "#dcdce7" }}>
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Product</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Rate</th>
                          <th scope="col">Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectData.materials.map((item) => (
                          <tr>
                            <th scope="row">
                              <a href="#">{j++}</a>
                            </th>
                            <td>{item.Product__r.Name}</td>
                            <td>
                              <a href="#" className="text-primary">
                                {item.Quantity__c}
                              </a>
                            </td>
                            <td>{item.Rate__c}</td>
                            <td className="">
                              <span className="text-dark">
                                {item.Total_Price__c}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div
              id="tempImageViewDiv"
              style={{
                position: "fixed",
                zIndex: "9999",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                display: "none",
                background: "rgb(173 173 173 / 58%)",
                justifyContent: "center",
              }}
              
            > 
              <div
                style={{
                  backgroundColor: "transparent",
                  minWidth: "20vh",
                  borderRadius: "7px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="overff" style={{overflow:"scroll",}}>
                <div className="text-right" style={{fontFamily:'serif'}}><button className="text-dark" style={{padding:'1px 9px',background:"rgb(231 224 224 / 21%)",fontWeight:'900',position:"absolute",margin:'3vh -6vh -22vh -4vh',fontSize:'1.3rem',border:'1px solid rgba(173, 156, 156, 0.17)'}} onClick={(e) => {
                document.getElementById("tempImageViewDiv").style.display ="none"
                document.body.style.overflow="auto"
                  }} >&#x2715;</button></div>
                <div className="text-center text-dark" id="imageViewInMyFiles" style={{fontWeight:'500'}} />
                <img
                  src=""
                  onError={(e) => {
                    console.clear();
                    var an = document.createElement('a')
                    an.href = e.target.src
                    an.download = true
                    if (document.getElementById("tempImageViewDiv").style.display == 'flex') {
                      an.click()
                      document.getElementById("tempImageViewDiv").style.display = 'none'
                      console.clear()
                      toast.success('Downloading...')
                      document.body.style.overflow="auto"
                    }
                    
                  }}
                  id="tempImageView"
                  style={{ borderRadius: "6px" }}
                  alt="Your file downloading..."
                  // alt="Preview not available, You can download and view"
                />
                </div>
              </div>
            </div>

            <div className="col-12 my-4">
              <div
                className="card recent-sales overflow-auto overff3"
                style={{ maxHeight: "40rem" }}
              >
                <div className="card-body m-0 p-0">
                  <h5 className="card-title mx-4 my-2">
                    My Files <span>| &nbsp; Details </span>
                  </h5>
                  {myFiles.length > 0 ? (
                    <table className="table table-image ">
                      {myFiles[0].hasOwnProperty("flag") ? (
                        <tr>
                          <td colSpan={5} className="text-center">
                            No record found
                          </td>
                        </tr>
                      ) : (
                        <>
                          <thead
                            style={{ backgroundColor: "rgb(220, 220, 231)" }}
                          >
                            <tr>
                              <th scope="col">S.No</th>
                              <th scope="col">Docs.</th>
                              <th scope="col">Group Name</th>
                              <th scope="col">Task Name</th>
                              <th scope="col">File Name</th>
                              <th scope="col" className="text-center">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {myFiles.map((element, z) => (
                              <>
                                <tr>
                                  <th scope="row">{z + 1}</th>
                                  <td
                                    title="Click to View this Docs"
                                    className="w-25 overff3"
                                    onClick={() => {
                                      //console.log("Enter");
                                      document.body.style.overflow="hidden"
                                      var ele =
                                        document.getElementById(
                                          "tempImageView"
                                        );
                                      var ele2 =
                                        document.getElementById(
                                          "tempImageViewDiv"
                                        );
                                      document.getElementById('imageViewInMyFiles').innerHTML = element.url.fileName
                                      ele2.style.display = "flex";
                                      ele.src = element.url.ContentDownloadUrl;
                                      ele.style.display = "flex";
                                    }}
                                  >
                                    <i
                                      className="fa-solid fa-file-contract"
                                      style={{ fontSize: 30, color: "#a75252" }}
                                    />
                                  </td>
                                  <td title="Group Name">
                                    {element.obj.Group__c}
                                  </td>
                                  <td title="Task Name">{element.obj.Name}</td>
                                  <td title="Task Name">{element.url.fileName}</td>
                                  <td className="text-center">
                                    <a
                                      href={element.url.ContentDownloadUrl}
                                      download={true}
                                      onClick={() =>
                                        setTimeout(() => {
                                          toast.success("Downloading...", {
                                            position: "top-center",
                                            autoClose: 2000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                          });
                                        }, 1000)
                                      }
                                    >
                                      <i
                                        className="fa fa-download"
                                        title="Download"
                                        style={{ color: "#3b71ca" }}
                                      />
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </>
                      )}
                    </table>
                  ) : (
                    <div className="text-center border-top">
                      <button
                        className="text-center p-3 border-none bg-transparent font-weight-bold"
                        onClick={handleRequestMyFiles}
                      >
                        <i className="fa-duotone fa-rotate fa-lg" /> &nbsp;
                        Click me to Request files
                      </button>
                    </div>
                  )}
                  <div>
                    <Toaster position="top-center" reverseOrder={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {uploadFileModal ? (
            <div>
              <div className="uploadfileModal">
                <div className="card uploadfileDiv">
                  <div className="card-body">
                    <div className="text-right border-none">
                      <button
                        className="border-none"
                        style={{
                          backgroundColor: "transparent",
                          fontWeight: "700",
                        }}
                        id="uploadfileModalcloseBTN"
                        onClick={() => {
                          setUploadFileModal(false);
                          setUploadFILEbtn(true);
                          document.getElementById(
                            "header"
                          ).style.pointerEvents = "auto";
                        }}
                      >
                        &#x2715;
                      </button>
                    </div>

                    <h5 className="card-title" style={{ cursor: "default" }}>
                      Name:- {uploadFIleData.ele.Name}
                    </h5>
                    <p className="card-text" style={{ cursor: "default" }}>
                      {uploadFIleData.ele.Status__c} on{" "}
                      {uploadFIleData.ele.Date__c}
                    </p>
                    {loading ? (
                      <ProgressBar completed={progressbar} maxCompleted={100} />
                    ) : (
                      <label htmlFor="images" className="drop-container">
                        <span className="drop-title">Drop files here</span>
                        or
                        <input
                          type="file"
                          id="images"
                          required=""
                          style={{ backgroundColor: "#ebebf0", color: "black" }}
                          onChange={getUploadFiles}
                          multiple={true}
                        />
                      </label>
                    )}
                  </div>

                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary mb-3"
                      onClick={submitUploadFIle}
                      disabled={uploadFILEbtn}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className="projectTabmainDiv ">
            <div
              classname="text-center"
              style={{ padding: "3vh", fontWeight: "900" }}
            >
              <h3 style={{ fontSize: "2.3rem" }}>
                <b>
                  <h1
                    className="opacity1animation"
                    style={{ fontFamily: "Calibri" }}
                  >
                    {pojectCards && pojectCards.length > 0 ? (
                      <p>
                        <a
                          href=""
                          id="style-2"
                          data-replace="My Projects"
                          className="text-hover6"
                          style={{ color: "black" }}
                        >
                          <span style={{ color: "black !important" }}>
                            My Projects
                          </span>
                        </a>
                      </p>
                    ) : (
                      <></>
                    )}
                  </h1>
                </b>
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              className="SlidInanimation"
            >
              {pojectCards && pojectCards.length > 0 ? (
                pojectCards.map((element) => (
                  <div
                    className="card hover-1"
                    style={{ width: "18rem", margin: "3vh" }}
                    key={element}
                    onClick={() => {
                      showThisProject(element);
                    }}
                  >
                    <img
                      src={element.ProjectImageUrlList[0]}
                      className="card-img-top"
                      alt="No preview available"
                      style={{ height: "175px" }}
                    />
                    <div className="card-body">
                      <h5
                        className="card-title overff"
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "600",
                          height: "5.3rem",
                          overflow: "auto",
                        }}
                      >
                        {element.projectObj.Name}
                      </h5>
                      <p
                        className="card-text overff"
                        style={{ height: "7rem", overflow: "auto" }}
                      >
                        {element.projectObj?.Description__c?.length > 0
                          ? element.projectObj.Description__c
                          : "No description found"}
                      </p>
                      <div className="button-hover4">
                        <p className="btnText">Go to Project</p>
                        <div className="btnTwo">
                          <p className="btnText2">&#8594;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No projects to display.</div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
