import "mdb-ui-kit/css/mdb.min.css";
import React, { useEffect, useState } from "react";
import Contact2 from "./Contact2";
import ProfilePage from "./Profile";
import Project from "./Project";

export const Dashboard = ({
  setuserloggedin,
  showOrHideProjectModal,
  setshowOrHideProjectModal,
}) => {
  const [dashboard, setdashboard] = useState(true);
  const [profile, setprofile] = useState(false);
  const [currentTab, setCurrentTab] = useState("Dashboard");
  const [contactModal, setContactModal] = useState(false);
  const [dashboardCanva, setDashboardCanva] = useState(false);
  const [userName, setUserName] = useState("");
  const [fLetter, setFLetter] = useState("");
  const [request, setRequest] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [notificationLength, setNotificationLength] = useState(0);
  const [notifiactionViewallbtn, setnotificationViewall] = useState({
    len: 2,
    view: true,
  });

  const offCanva = document.getElementById("offcanvasScrolling");
  const signOut = (evt) => {
    evt.preventDefault();
    localStorage.removeItem("notificationData");
    localStorage.removeItem("SpacifyData");
    localStorage.removeItem("isLogin");
    setuserloggedin(false);
    window.location.reload();
  };

  useEffect(() => {
    var temp = [];

    if (
      localStorage.getItem("notificationData") === null ||
      localStorage.getItem("notificationData") === "" ||
      localStorage.getItem("notificationData") === undefined
    ) {
      localStorage.setItem("notificationData", JSON.stringify(request));
    } else {
      var oldData = JSON.parse(localStorage.getItem("notificationData"));
      var TempList = [];
      var TempList2 = [];
      oldData.forEach((element) => {
        TempList.push(element.Id);
        if (element.flag === false) {
          TempList2.push(element.Id);
        }
      });
      request.forEach((element) => {
        if (!TempList.includes(element.Id)) {
          oldData.push(element);
        }
        if (TempList2.includes(element.Id)) {
          element.flag = false;
        }
      });
      localStorage.setItem("notificationData", JSON.stringify(oldData));
      setRequestData(oldData);
      oldData.forEach((element) => {
        if (element.flag === true) {
          temp.push(element);
        }
      });
      setNotificationLength(temp.length);
    }
  }, [request]);

  useEffect(() => {
    let fLetter = "";

    var DETA = JSON.parse(localStorage.getItem("SpacifyData"));
    setUserName(DETA.ContactList.Name);
    if (DETA.ContactList.hasOwnProperty("FirstName")) {
      fLetter =
        DETA.ContactList.FirstName.charAt(0) +
        DETA.ContactList.LastName.charAt(0);
    } else {
    }
    setFLetter(fLetter);
  }, []);

  const DisableDiv = (event) => {
    var temp = requestData;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].task.Id === event.target.id) {
        temp[i].flag = false;
      }
    }

    if (notificationLength - 1 === -1) {
      setNotificationLength(0);
    } else {
      setNotificationLength(notificationLength - 1);
    }
    setRequest(temp);
    localStorage.setItem("notificationData", JSON.stringify(requestData));
  };
  return (
    <>
      <div style={{ background: "white" }}>
        <header
          id="header"
          className="header fixed-top d-flex align-items-center"
        >
          <div className="d-flex align-items-center justify-content-between"> 
            <a href="#" className="logo d-flex align-items-center">     
              <img  
                src="assets/images/LogoImage.png"     
                alt=""
                width="120"  
                // height="30"
                className="logoDashBoard"
              />
            </a>
            <i
              className="bi bi-list toggle-sidebar-btn burgerBtnNew" 
              id="dashcanva"
              onClick={() => {
                dashboardCanva
                  ? setDashboardCanva(false)
                  : setDashboardCanva(true);
              }}
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasScrolling"
              aria-controls="offcanvasScrolling"
            />
          </div>
          <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-icon"
                  href="#"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                >
                  <i className="bi bi-bell" />
                  <span className="badge bg-primary badge-number">
                    {notificationLength}
                  </span>
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications overff"
                  style={{
                    overflow: "scroll",
                    maxHeight: "32vh",
                    width: "30vh",
                  }}
                >
                  <li className="dropdown-header">
                    You have {notificationLength}{" "}
                    {notificationLength > 0 ? "new" : ""} notifications
                  </li>
                  <li
                    className="overff"
                    style={{ overflow: "scroll", maxHeight: "20vh" }}
                  >
                    {requestData
                      .slice(0, notifiactionViewallbtn.len)
                      .map((item, i) => (
                        <>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li
                            className={
                              item.flag
                                ? "notification-item "
                                : "notification-item disabled"
                            }
                            onClick={DisableDiv}
                            style={{ cursor: "default" }}
                            id={item.task.Id}
                          >
                            <i
                              className="bi bi-exclamation-circle text-warning"
                              id={item.task.Id}
                            />
                            <div onClick={DisableDiv} id={item.task.Id}>
                              <h4 id={item.task.Id}>
                                {item.probj.projectObj.Name}
                              </h4>
                              <p id={item.task.Id}>
                                {item.task.Group__c} - File Requested
                              </p>
                            </div>
                          </li>
                        </>
                      ))}
                  </li>

                  {notifiactionViewallbtn.view && request.length > 2 ? (
                    <li className="text-center">
                      <span
                        style={{ cursor: "pointer" }}
                        className="badge rounded-pill bg-primary p-2 ms-2"
                        onClick={() => {
                          let temp = request;
                          var temp2 = { ...notifiactionViewallbtn };
                          temp2.len = temp.length;
                          temp2.view = false;
                          setnotificationViewall(temp2);
                        }}
                      >
                        View all
                      </span>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link nav-icon"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <i className="bi bi-chat-left-text" />
                  <span className="badge bg-success badge-number">3</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                  <li className="dropdown-header">
                    You have 3 new messages
                    <a href="#">
                      <span className="badge rounded-pill bg-primary p-2 ms-2">
                        View all
                      </span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="message-item">
                    <a href="#">
                      <img
                        src="assets/img/messages-1.jpg"
                        alt=""
                        className="rounded-circle"
                      />
                      <div>
                        <h4>Maria Hudson</h4>
                        <p>
                          Velit asperiores et ducimus soluta repudiandae labore
                          officia est ut...
                        </p>
                        <p>4 hrs. ago</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="message-item">
                    <a href="#">
                      <img
                        src="assets/img/messages-2.jpg"
                        alt=""
                        className="rounded-circle"
                      />
                      <div>
                        <h4>Anna Nelson</h4>
                        <p>
                          Velit asperiores et ducimus soluta repudiandae labore
                          officia est ut...
                        </p>
                        <p>6 hrs. ago</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="message-item">
                    <a href="#">
                      <img
                        src="assets/img/messages-3.jpg"
                        alt=""
                        className="rounded-circle"
                      />
                      <div>
                        <h4>David Muldon</h4>
                        <p>
                          Velit asperiores et ducimus soluta repudiandae labore
                          officia est ut...
                        </p>
                        <p>8 hrs. ago</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="dropdown-footer">
                    <a href="#">Show all messages</a>
                  </li>
                </ul>
              </li> */}
              <li
                className="nav-item dropdown pe-3"
                onClick={() => {
                  dashboardCanva
                    ? document.getElementById("dashcanva").click()
                    : console.log();
                }}
              >
                <a
                  className="nav-link nav-profile d-flex align-items-center pe-0"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <div
                    alt="Profile"
                    className="rounded-circle"
                    style={{
                      backgroundColor: "#eae6de",
                      border: "1px solid #dbd7d7",
                      padding: " 0 0.15em",
                    }}
                  >
                    {fLetter}
                  </div>
                  <span className="d-none d-md-block dropdown-toggle ps-2">
                    {userName}
                  </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                  <li className="dropdown-header">
                    <h6>{userName}</h6>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="#"
                      onClick={() => {
                        setprofile(true);
                        setdashboard(false);
                        setContactModal(false);
                        setCurrentTab("Profile");
                      }}
                    >
                      <i className="bi bi-person" />
                      <span>My Profile</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="#"
                      onClick={() => {
                        document.getElementById("contactus123").click();
                      }}
                    >
                      <i className="bi bi-question-circle" />
                      <span>Need Help?</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="# "
                      onClick={signOut}
                    >
                      <i className="bi bi-box-arrow-right" />
                      <span>Sign Out</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </header>
        <aside
          className="sidebar offcanvas offcanvas-start  py-5"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabindex="-1"
          id="offcanvasScrolling"
          aria-labelledby="offcanvasScrollingLabel"
          style={{
            zIndex: "20",
            minWidth: "30vh",
            width: "22vw",
            border: "1px solid transparent",
          }}
        >
          <ul className="sidebar-nav py-5" id="sidebar-nav">
            <li className="nav-item">
              <a
                className="nav-link "
                href="#"
                onClick={() => {
                  setprofile(false);
                  setdashboard(true);
                  setContactModal(false);
                  setCurrentTab("Dashboard");
                  setshowOrHideProjectModal(false);
                  dashboardCanva
                    ? document.getElementById("dashcanva").click()
                    : setDashboardCanva(false);
                }}
              >
                <i className="bi bi-grid" />
                <span>Dashboard</span>
              </a>
            </li>
            <li className="nav-heading">Pages</li>
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                onClick={() => {
                  setprofile(true);
                  setdashboard(false);
                  setContactModal(false);
                  setCurrentTab("Profile");
                  dashboardCanva
                    ? document.getElementById("dashcanva").click()
                    : setDashboardCanva(false);
                }}
              >
                <i className="bi bi-person" />
                <span>Profile</span>
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                id="contactus123"
                onClick={() => {
                  setprofile(false);
                  setdashboard(false);
                  setCurrentTab("Contact");
                  setContactModal(true);
                  dashboardCanva
                    ? document.getElementById("dashcanva").click()
                    : setDashboardCanva(false);
                }}
              >
                <i className="bi bi-envelope" />
                <span>Contact us</span>
              </a>
            </li>
          </ul>
        </aside>
        <main
          id="main"
          className="main"
          style={{ marginTop: "0px", backgroundColor: "white", margin: "0" }}
          onClick={() => {
            dashboardCanva
              ? document.getElementById("dashcanva").click()
              : setDashboardCanva(false);
          }}
        >
          <div className="pagetitle">
            <h1>Dashboard</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => {
                      setprofile(false);
                      setdashboard(true);
                      setContactModal(false);
                      setshowOrHideProjectModal(false);
                      setCurrentTab("Dashboard");
                    }}
                  >
                    Home
                  </span>
                </li>
                <li className="breadcrumb-item active"> {currentTab} </li>
              </ol>
            </nav>
          </div>

          {dashboard ? (
            <section className="section dashboard">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <Project
                            showOrHideProjectModal={showOrHideProjectModal}
                            setshowOrHideProjectModal={
                              setshowOrHideProjectModal
                            }
                            setCurrentTab={setCurrentTab}
                            setRequest={setRequest}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
          {profile ? (
            <section className="section ">
              <ProfilePage setuserloggedin={setuserloggedin} />
            </section>
          ) : (
            <></>
          )}

          {contactModal ? <Contact2 /> : <></>}
        </main>
        <footer id="footer" className="footer" style={{ marginLeft: "0px" }}>
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>Spacify</span>
            </strong>
            . All Rights Reserved
          </div>
        </footer>
      </div>
    </>
  );
};
