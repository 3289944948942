import React from "react";
export default function footer({setshowOrHideProjectModal}) {
  var privacy_policy =
    process.env.PUBLIC_URL +
    "assets/privacy_policy/Privacy Policy_Spacify India.pdf";


    const showFloorPlansPage = (event) => {
      event.preventDefault(); // Prevent default behavior
      window.location.href = "/floorPlans";   
      setshowOrHideProjectModal(false);
      document.getElementById("BurgerBtn").click();      
  };

  const showBlogsPage = (event) => {
    event.preventDefault(); // Prevent default behavior
    window.location.href = "/Blogs";   
    setshowOrHideProjectModal(false);
    document.getElementById("BurgerBtn").click();        
};




  return (
    <>
     <footer
        className="section footer-classic bg-gray-900 novi-bg novi-bg-img"
        style={{
          backgroundImage: "url(assets/images/bg-price-1920x1128.png)",
          paddingTop: "2.5rem",
          paddingBottom: "2.5rem",
        }}
      >
     <div className="container">
          <div className="row row-30 justify-content-between">
            <div className="col-xl-4 col-lg-4 col-sm-6 col-12"> 
              {/* <p className="footer-classNameic-title">Our Address</p> */}
              <img
                src="assets/images/Spacify white Final Logo-01.png"
                style={{
                  width: "200px",
                  marginLeft: "-7px",
                  marginTop: "-2rem",
                }}
              ></img> 
              {/* <br/> <br/> */}
              <div className="social-links socialFooter">
                <br />
                <br />
                <a
                  href="https://www.facebook.com/spacifyindia"
                  target="_blank"
                  className="FacebookIcon iconsModalFooter"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://twitter.com/spacifyindia"
                  target="_blank"
                  className="iconsModalFooter"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/spacifyindia/"
                  target="_blank"
                  className="iconsModalFooter"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/in/spacify-india-5394892a8/" target="_blank" className="iconsModalFooter">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a
                  href="https://www.youtube.com/@spacifyindia"
                  target="_blank"
                  className="iconsModalFooter"
                >
                  <i className="fa fa-youtube-play"></i>
                </a>
                {/* <a href="#">
                  <i className="fab fa-whatsapp"></i>  
                </a> */}
              </div>
              <ul className="footer-classNameic-list" style={{ paddingLeft:"0rem" }} >    
                <li style={{ color: "#fff" ,fontSize:"14px" }}>
                  Our team creates comfortable spaces for our clients. We’ve
                  been designing your everyday life and work through great
                  ideas. Besides great interior design, there are lots of
                  reasons to choose SPACIFY CONCEPTS PRIVATE LIMITED.
                </li>
              </ul>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-6 col-6">
              <p className="footer-classNameic-title">Company</p>
              <ul className="footer-classNameic-list" style={{ color: "#f3ffff" , paddingLeft:"0rem"}}>
                <li>
                  <a
                    className="FooterNavOptions"
                    href="#ourServices"
                    // target={"_blank"}
                    rel="noreferrer"
                  >
                    Our Services
                  </a>
                </li>
                <br />
                <li>
                  <a
                    className="FooterNavOptions"
                    href="#portfolioNew"
                    // target={"_blank"}
                    rel="noreferrer"
                  >
                    Portfolio
                  </a>
                </li>
                <br />
                <li>
                  <a
                    className="FooterNavOptions"
                    href="#ContactUs"
                    // target={"_blank"}
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
                <br />
                <li>
                  <a
                    className="FooterNavOptions"
                    href=""
                    onClick={showFloorPlansPage} 

                    // target={"_blank"}
                    rel="noreferrer"
                  >
                    Floor Plans
                  </a>
                </li>
                <br />

                <li>
                  <a
                    className="FooterNavOptions"
                    href=""
                    onClick={showBlogsPage} 

                    // target={"_blank"}
                    rel="noreferrer"
                  >
                    Blogs
                  </a>
                </li>
                <br />

              </ul>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-6 col-6">   
              <p className="footer-classNameic-title">Catalogue links</p> 
              <ul className="footer-classNameic-list" style={{ paddingLeft:"0rem" }}>
                <li>
                  <a className="FooterNavOptions" target="_blank" href="https://drive.google.com/file/d/1CRD4s7X6b1KRb6K7wZzWuGVytc2lpb72/view?usp=sharing" style={{ color: "white" }}>
                  Commercial Product 
                  </a>
                </li>
                <br />
                <li>
                  <a className="FooterNavOptions" target="_blank" href="https://drive.google.com/file/d/11OJtSPwydcnXu6SEXkLXI3mXvARttnBh/view?usp=sharing"   style={{ color: "white" }}>
                  Residential Product 
                  </a>
                </li>
                <br />
                <li>
                  <a className="FooterNavOptions" target="_blank" href="https://drive.google.com/file/d/1VpDkKuyPB-mQu0l19q8WX8edKBxuh2nc/view?usp=sharing" style={{ color: "white" }}>
                  Commercial Profile
                  </a>
                </li>
                <br />
                <li>
                  <a className="FooterNavOptions" target="_blank" href="https://drive.google.com/file/d/1yHyUZK2ceqbx9oxsetI_ydAKCBtMQlU_/view?usp=sharing" style={{ color: "white" }}>
                  Residential Profile
                  </a>
                </li>
                <br />
              </ul>
            </div>
            <div className="col-xl-2 col-lg-3 col-sm-6 col-12"> 
              <p className="footer-classNameic-title">Contact Us</p>
              <ul className="footer-classNameic-list" style={{ paddingLeft:"0rem" }}>
              <li>
                  <p style={{marginBottom:"0rem", color:"#fff", fontSize:"14px"}}>Call us</p>
                  <a
                    href="tel:9654788000"
                    style={{ color: "white",fontSize:"14px" }}
                  >
                     +91 96547-88000
                  </a>
                </li>
                <br/>
                <li>
                  <p style={{marginBottom:"0rem", color:"#fff", fontSize:"14px"}}>Email us</p>
                  <a
                    href="mailto:response@spacifyindia.com"
                    style={{ color: "white" , fontSize:"14px" }}
                  >
                    response@spacifyindia.com
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="py-10 footerKeywordsNew">
            <div className="footerKeyInDiv">
              <h6>Our Interior Design Services</h6> 
              <span className="mt-2 spanKeyNew">Interior Designers In Gurgaon &nbsp;| &nbsp;</span>  
              <span className="mt-2 spanKeyNew">Mandir Interior Design &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Best Interior Designers In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Design Firms In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Company In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Office Interior Designers In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Design Company In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Office Interiors In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Design In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Latest Interior Design &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Low Budget Interior Designers In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Mandir Design &nbsp;| &nbsp;</span>  
              <span className="mt-2 spanKeyNew">Top Interior Designers In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Decorators In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Gurgaon Home Design &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Mandir Interior &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Luxury Interior Designers In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Mandir Interior Design For Home &nbsp;| &nbsp;</span>  
              <span className="mt-2 spanKeyNew">Home Temple Interior Design &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Decorator In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interiors In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Best Interior Design Company In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Washroom Interior Design &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Commercial Interior Designers In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Low Budget Interior Designers In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Home Interior Designers In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Design Services In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Mandir Design Interior &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Designers Company In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Residential Interior Designers In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Home Interior Gurgaon &nbsp;| &nbsp;</span>
              <span className="mt-2 spanKeyNew">Interior Design Office In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Best Office Interior Designer In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Mandir Design In Drawing Room &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Tiles Combination For Living Room &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Work In Gurgaon &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Interior Mandir &nbsp;| &nbsp;</span>
              <span className="mt-2 spanKeyNew">Bedroom Interior Desingers &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Bar Designers &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Bathroom &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Kitchen &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Wardrobe &nbsp;| &nbsp;</span> 
              <span className="mt-2 spanKeyNew">Living Room &nbsp;</span>  

             

             
            </div>
          </div>


          <p className="rights cursor-pointer pb-0 mb-0">
            <a href="#" onClick={() => window.open(privacy_policy, "_blank")}>
              Privacy Policy{" "}
            </a>
          </p>
          <p className="rights p-0 m-0">
            <span>©&nbsp; </span>
            <span className="copyright-year" />
            <span>&nbsp;</span>

            <span>All Rights Reserved By SPACIFY CONCEPTS PRIVATE LIMITED</span>
          </p>
        </div>
      </footer>
    </>
  );
}
