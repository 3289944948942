import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "jquery";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./OurTrustedPartners.css";
export default function OurTrustedPartners({ responseFlag }) {
  const [partnerData, setPartnerData] = useState([]);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,  
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,   
    },
  };

  useEffect(() => {
    if (responseFlag || localStorage.getItem("partnerWrapList") != null) {
      let partData = JSON.parse(localStorage.getItem("partnerWrapList"));

      if (partData) {
        setPartnerData(partData);
      }
      setTimeout(() => {
        window.initJs();
      }, 1000);
    }
  }, [responseFlag]);
  return (
    <section className="OurTrustedPartnerSection">
      <div className="OurTrustHead">Our Trusted Partners</div>
      <div
        className="parentOurTrustedPartner"    
        style={{ paddingLeft: "5vw", paddingRight: "5vw" }}   
      >
        <Carousel className="CrauselTrustedPartner" 
          // showDots={true}
          responsive={responsive}
          // rewind = {true}
          autoPlay   
          infinite={true}

          
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
          {partnerData.length > 0 &&
            partnerData.map((item, idx) => (
              <div className="slider sliderReview" key={idx}>
                <img
                  className="ImgOurTrustedPartner"
                  src={item.partnerImageUrlList[0]}
                  height={100}
                  width={250}
                  alt=""
                />
                
              </div>
            ))}

        </Carousel>
      </div>
    </section>
  );
}
