import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACCESS_TOKEN_API } from "../shared/constants";
import { Dashboard } from "./Dashboard";
// import GetaQuote from "./GetaQuote";
import { useLocation, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./BlogComp.css";
import ClientJourney from "./ClientJourney";
import FloorFooter from "./FloorFooter";
import FloorNavBar from "./FloorNavBar";
import "./FloorPlans.css";
import "./Home2.css";

// import ServicesNew from "./ServicesNew";

const FloorPlans = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [userloggedin, setuserloggedin] = useState(false);
  const [showOrHideProjectModal, setshowOrHideProjectModal] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [localDataFloor, setLocalDataFloor] = useState([]);
  const [FloorPlanOpenPage, setFloorPlanOpenPage] = useState("d-block");
  const [projectPageOpen, SetProjectPageOpen] = useState(false);
  const [nakshaData, SetNakshaData] = useState([]);
  const [singleNakshaData, SetSingleNakshaData] = useState([]);
  const [imageDataList, SetImageDataList] = useState([]);
  const [selectedPlotAreaValue, setSelectedPlotAreaValue] =
    useState("Any PlotArea");
  const [selectedStyleValue, setSelectedStyleValue] = useState("Any Unit");
  const [selectedDirecValue, setSelectedDirecValue] = useState("Any Direction");
  const [selectedFloorValue, setSelectedFloorValue] = useState("Any Floor");
  const [showAllPlansDetails, setshowAllPlansDetails] = useState(true);

  const [categoryList, setCategoryList] = useState([]);
  const [mainValue, setMainValue] = useState("");

  const [isUnit, setIsUnit] = useState(true);

  const [fnameWarning, setFnameWarning] = useState(false);
  const [fnameDigitError, setFnameDigitError] = useState(false);
  const [phoneWarning, setPhoneWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);
  const [companyWarning, setCompanyWarning] = useState(false);
  const [submitDisBtn, setSubmitDisBtn] = useState(false);

  const [getDetailsFormPage, setgetDetailsFormPage] = useState({
    full_Name: "",
    Company: "",
    email: "",
    mobileNumber: "",
    area: "",
  });

  // useEffect(() => {
  //   console.log(location);
  //   if(location?.state?.prevSearch.includes('Commercial')){
  //     console.log('in if', bckBtnBool);

  //   }
  // }, [location]);
  const onBackButtonEvent = (e) => {
    // console.log("HelloIf");
    e.preventDefault();
    // console.log(location);

    
    var currentLocation = window.location.href;
    const btnVal = JSON.parse(localStorage.getItem("BackBtnVal"));
    // console.log("Back Button123 Value=", btnVal);
    if (btnVal) {
      backButtonCall();
    } else {
      backButtonCallToHome();
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    fetch(ACCESS_TOKEN_API, { method: "POST" }) ///getAccessToken
      .then((response) => response.json())
      .then((result) => {
        getData(result);
      })
      .catch((error) => {
        // console.log('The error in react ='+error);
      });
    if (localStorage.getItem("isLogin")) {
      setuserloggedin(true);
    } else {
      setuserloggedin(false);
    }

    localStorage.setItem("BackBtnVal", false);

    // setTimeout(() => {
    //   // console.log('Hello Timeout');
    //   setLoading(false);
    // }, 4000);
  }, []);

  useEffect(() => {
    let url = window.location.href;
    if (url.includes("HouseArea")) {
      // console.log("Hello");
      let urlString = window.location.href.split("?")[1];
      urlString = urlString.split("=")[1];
      urlString = decodeURIComponent(urlString);
      openHouseData(urlString);
    }
    if (url.includes("Residential")) {
      let urlString = window.location.href.split("?")[1];
      urlString = urlString.split("=")[1];
      urlString = decodeURIComponent(urlString);   
      openCategoryResidenial(urlString);  
    }
    if (url.includes("Commercial")) {
      let urlString = window.location.href.split("?")[1];
      urlString = urlString.split("=")[1];
      urlString = decodeURIComponent(urlString);
      openCategoryData(urlString);
    }

    if (url.includes("design_name")) {
      let urlString = window.location.href.split("?")[1];     
      urlString = urlString.split("=")[1];
      urlString = decodeURIComponent(urlString);
      // console.log('UrlString='+urlString);
      setTimeout(()=>{
        onDesignName(urlString);
      },200) 
      
    }
    // console.log('Hello');
  }, [localDataFloor]);

  const getData = (res) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${res.access_token}`);

    var raw = JSON.stringify({
      Name: "Kamran Khan",
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${res.instance_url}/services/apexrest/Interior_App_Api`,
      requestOptions
    ).then(async (response) => {
      let result = await response.json();
      const data = JSON.parse(result);
      // console.log("Result= Floor Palns" + data);
      if (
        data.floorPlanWrapList === null ||
        data.floorPlanWrapList === "" ||
        data.floorPlanWrapList === undefined
      ) {
        localStorage.removeItem("floorPlanWrapList");
      } else {
        // localStorage.setItem(
        //   "floorPlanWrapList",
        //   JSON.stringify(data.floorPlanWrapList)
        // );
        setLocalDataFloor([...data.floorPlanWrapList]);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  useEffect(() => {
    // console.log('nakshaData=',nakshaData);
  }, [nakshaData]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };

  const responsiveForModalCraousel = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const validatePhoneNumber = (event) => {
    var keycode = event.which;
    if (
      !(
        (event.shiftKey === false &&
          (keycode === 46 ||
            keycode === 8 ||
            keycode === 37 ||
            keycode === 39 ||
            (keycode >= 48 && keycode <= 57))) ||
        (keycode >= 96 && keycode <= 105) ||
        keycode === 9
      )
    ) {
      event.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setgetDetailsFormPage({ ...getDetailsFormPage, [name]: value });

    if ("full_Name" === event.target.name) {
      if (event.target.value?.length >= 2) {
        setFnameWarning(false);
      } else {
        setFnameWarning(true);
      }

      const re = /^([^0-9]*)$/;
      if (!event.target.value.match(re) && event.target.value?.length > 2) {
        setFnameDigitError(true);
      } else {
        setFnameDigitError(false);
      }
    }

    if ("Company" === event.target.name) {
      if (event.target.value?.length >= 1) {
        setCompanyWarning(false);
      } else {
        setCompanyWarning(true);
      }
    }

    if ("mobileNumber" === event.target.name) {
      if (value?.length === 10) {
        setPhoneWarning(false);
      } else {
        setPhoneWarning(true);
      }
    }
    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if ("email" === event.target.name) {
      if (event.target.value.match(mailformat)) {
        setEmailWarning(false);
      } else {
        setEmailWarning(true);
      }
    }
  };

  const handleSelectChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setgetDetailsFormPage({ ...getDetailsFormPage, [name]: value });
  };

  const submitBtnClick = () => {
    if (getDetailsFormPage.full_Name?.length >= 2) {
      setFnameWarning(false);
    } else {
      setFnameWarning(true);
    }

    const re = /^([^0-9]*)$/;
    // const re = /^[0-9-+()]*$/;
    if (
      !getDetailsFormPage.full_Name.match(re) &&
      getDetailsFormPage.full_Name?.length > 2
    ) {
      setFnameDigitError(true);
    } else {
      setFnameDigitError(false);
    }

    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (getDetailsFormPage.email.match(mailformat)) {
      setEmailWarning(false);
    } else {
      setEmailWarning(true);
    }

    if (getDetailsFormPage.mobileNumber?.length === 10) {
      setPhoneWarning(false);
    } else {
      setPhoneWarning(true);
    }

    if (getDetailsFormPage.Company?.length >= 1) {
      setCompanyWarning(false);
    } else {
      setCompanyWarning(true);
    }

    if (getDetailsFormPage.full_Name?.length < 2) {
      // console.log('Hello If Name');
      setSubmitDisBtn(false);
      //  return;
    }
    if (getDetailsFormPage.mobileNumber?.length < 10) {
      // console.log('Hello If Phone');
      setSubmitDisBtn(false);
      //  return;
    }
    if (getDetailsFormPage.Company?.length < 1) {
      // console.log('Hello If City');
      setSubmitDisBtn(false);
      //  return;
    }
    if (getDetailsFormPage.email.match(mailformat)) {
      // console.log('Hello If PropertyType');
      setSubmitDisBtn(false);
    }

    if (
      getDetailsFormPage.full_Name?.length >= 2 &&
      getDetailsFormPage.mobileNumber?.length >= 10 &&
      getDetailsFormPage.Company?.length > 0 &&
      getDetailsFormPage.email.match(mailformat)
    ) {
      // console.log('Hello Else');
      setSubmitDisBtn(true);
      fetch(ACCESS_TOKEN_API, { method: "POST" })
        .then((response) => response.json())
        .then((result) => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${result.access_token}`);
          var raw = JSON.stringify({
            getAQuoteNewEnqiry: "sdfdsfdsf",
            Name: getDetailsFormPage.full_Name,
            phone: getDetailsFormPage.mobileNumber,
            company: getDetailsFormPage.Company,
            email: getDetailsFormPage.email,
            area: getDetailsFormPage.area,
          });
          // console.log("The Console Raw=" + raw);

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
          fetch(
            `${result.instance_url}/services/apexrest/Interior_App_Api`,
            requestOptions
          ).then(async (response) => {
            let result = await response.json();
            // console.log('result='+result);
            if (result === "Successfully Inserted") {
              getDetailsFormPage.full_Name = "";
              getDetailsFormPage.Company = "";
              getDetailsFormPage.mobileNumber = "";
              getDetailsFormPage.email = "";

              // console.log('getDetailsFormPage',getDetailsFormPage);

              toast.success("Request Submitted Successfully !", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
              });
              setSubmitDisBtn(false);
              setIsOpen(false);
            } else if (result === "Failed GetAQuote") {
              getDetailsFormPage.full_Name = "";
              getDetailsFormPage.Company = "";
              getDetailsFormPage.mobileNumber = "";
              getDetailsFormPage.email = "";
              toast.error("Something Went Wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
              });
              setSubmitDisBtn(false);
              setIsOpen(false);
            }
          });
        })
        .catch((error) => {
          // console.log('result Failed');
          getDetailsFormPage.full_Name = "";
          getDetailsFormPage.Company = "";
          getDetailsFormPage.mobileNumber = "";
          getDetailsFormPage.email = "";
          toast.error("Something Went Wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
          setSubmitDisBtn(false);
          setIsOpen(false);
        });
    }
  };

  useEffect(() => {
    getDetailsFormPage.area = "Less than 2000 sq feet";
  }, []);

  const openHouseData = (itemSize) => {
    setLoading(true);
    let url = window.location.href;
    navigate("/floorPlans" + "?HouseArea=" + encodeURIComponent(itemSize), {
      state: null,
    });
    // if(!url.includes('HouseArea')){
    //   url += '?HouseArea=' + encodeURIComponent(itemSize); // Encode itemData to handle special characters
    //   window.history.pushState({ path: url }, '', url);
    // }
    setMainValue("House");
    setIsUnit(true);
    window.scrollTo(0, 0);
    setSelectedPlotAreaValue(itemSize);
    setFloorPlanOpenPage("d-none");
    SetProjectPageOpen(false);
    const headerElement = document.getElementById("miniHeaderClose");
    if (headerElement) {
      document.getElementById("miniHeaderClose").click();
    }

    setTimeout(() => {
      changePlotArea(itemSize);
    }, 100);

    setTimeout(() => {
      setLoading(false); 
    }, 4000);
  };

  const changePlotArea = (itemSize) => {
    // setSelectedPlotAreaValue(itemSize);
    const selectElementPlot = document.getElementById("category-filterPlot");
    // console.log('itemSize12='+ selectElementPlot.value);
    selectElementPlot.value = itemSize;

    // console.log('itemSize='+ selectElementPlot.value);

    let filterData = [];
    let mainData = localDataFloor;
    //  console.log('MainDataPlot='+mainData);

    const plotAreaParts = itemSize.split("-");
    const minPlotArea = parseInt(plotAreaParts[0]);
    const maxPlotArea = parseInt(plotAreaParts[1]);

    filterData = mainData.filter(
      (item) =>
        item?.floorObj.Plot_Area__c >= minPlotArea &&
        item?.floorObj.Plot_Area__c <= maxPlotArea
    );
    // console.log('FilterDataPlot='+filterData);
    SetNakshaData(filterData);
  };

  const openCategoryData = (itemCategory) => {
    // console.log('ItemCategoryData='+itemCategory);
    setLoading(true);
    let url = window.location.href;
    navigate(
      "/floorPlans" + "?Commercial=" + encodeURIComponent(itemCategory),
      { state: null }
    );
    // if(!url.includes('Commercial')){
    //   url += '?Commercial=' + encodeURIComponent(itemCategory); // Encode itemData to handle special characters
    //   window.history.pushState({ path: url }, '', url);
    // }
    setSelectedStyleValue(itemCategory);
    setMainValue("Commercial");
    setIsUnit(false);
    window.scrollTo(0, 0);
    setFloorPlanOpenPage("d-none");
    SetProjectPageOpen(false);
    const headerElement = document.getElementById("miniHeaderClose");
    if (headerElement) {
      document.getElementById("miniHeaderClose").click();
    }

    const categoryOptions = [
      "Appartment",
      "Corporate Buildings",
      "Shopping Complex",
      "Hospitality",
      "Hospitals",
      "Institutional Buildings",
    ];
    setCategoryList(categoryOptions);

    setTimeout(() => {
      changeCategory(itemCategory);
    }, 100);
    setTimeout(() => {
      setLoading(false); 
    }, 4000);  
  };

  const openCategoryResidenial = (itemCategory) => {
    setLoading(true);
    let url = window.location.href;
    navigate(
      "/floorPlans" + "?Residential=" + encodeURIComponent(itemCategory),
      { state: null }
    );
    // if(!url.includes('Residential')){
    //   url += '?Residential=' + encodeURIComponent(itemCategory); // Encode itemData to handle special characters
    //   window.history.pushState({ path: url }, '', url);
    // }

    setSelectedStyleValue(itemCategory);
    setMainValue("Residential");
    // console.log("Item Category Residentail=" + itemCategory);
    setIsUnit(false);
    window.scrollTo(0, 0);
    setFloorPlanOpenPage("d-none");
    SetProjectPageOpen(false);  

    const headerElement = document.getElementById("miniHeaderClose");
    if (headerElement) {
      document.getElementById("miniHeaderClose").click();
    }

    const categoryOptions = [
      "Simplex House Plans",
      "Duplex House Plans",
      "Small House Plans",
      "Vastu House Plans",
      "Popular House Plans",
      "Bungalow House Plans",
    ];
    setCategoryList(categoryOptions);

    if (itemCategory === "Simplex House Plans") {
      // console.log("If Condition Check");
    }

    setTimeout(() => {
      changeCategory(itemCategory);
    }, 100);
    setTimeout(() => {
      setLoading(false);  
    }, 4000);
  };

  const changeCategory = (itemCategory) => {
    // console.log('itemCategory='+itemCategory);
    // setSelectedStyleValue(itemCategory);

    // console.log("itemCategory=" + itemCategory);
    const selectElementPlot = document.getElementById("category-filterUnit");
    // console.log("selectElement=" + selectElementPlot.value);
    selectElementPlot.value = itemCategory;
    // console.log("selectElement AFter=" + selectElementPlot.value);

    let filterData = [];
    let mainData = localDataFloor;

    filterData = mainData.filter(
      (item) => item?.floorObj.Category__c === itemCategory
    );
    SetNakshaData(filterData);
  };

  const onDesignName = (item) => {
    setFloorPlanOpenPage("d-none");  
    setTimeout(()=>{
      SetProjectPageOpen(true);  
    },400); 
    setLoading(true);   
    if(localDataFloor?.length > 0){

      localStorage.setItem("BackBtnVal", true);  
      // console.log(localDataFloor);    
      let Data = localDataFloor;
      const filterData = Data.filter((item12) => item12?.floorObj.Name === item);
      
      navigate(
      "/floorPlans" +
        "?design_name=" +
        encodeURIComponent(filterData[0]?.floorObj.Name),
      { state: { prevSearch: location?.search } }
    );
  

    const floorImageUrlArray = filterData[0]?.floorImageUrlList;
    SetSingleNakshaData(filterData);
    SetImageDataList(floorImageUrlArray);
    SetProjectPageOpen(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);  
    }, 4000);
    }
  };

  const viewDetailsCall = (item) => {
    setLoading(true);
    localStorage.setItem("BackBtnVal", true);
    // console.log('item='+item);
    let Data = localDataFloor;
    const filterData = Data.filter((item12) => item12?.floorObj.Id === item);
    // console.log(filterData);
    navigate(
      "/floorPlans" +
        "?design_name=" +
        encodeURIComponent(filterData[0]?.floorObj.Name),
      { state: { prevSearch: location?.search } }
    );
    let url = window.location.href;

    // if(!url.includes('design_name')){
    //   url = url.split('?')[0];
    //   url += '?design_name=' + encodeURIComponent(filterData[0]?.floorObj.Name); // Encode itemData to handle special characters
    //   window.history.pushState({ path: url }, '', url);
    // }

    const floorImageUrlArray = filterData[0]?.floorImageUrlList;
    //  JSON.parse(floorImageUrlArray);
    SetSingleNakshaData(filterData);
    SetImageDataList(floorImageUrlArray);
    SetProjectPageOpen(true); 
    window.scrollTo(0, 0);
    setTimeout(() => {
      // console.log('Hello Timeout');
      setLoading(false);  
    }, 4000);
  };

  useEffect(() => {
    FilterPlotAreas(selectedPlotAreaValue);
  }, [selectedPlotAreaValue]);

  useEffect(() => {
    FilterStyles(selectedStyleValue);
  }, [selectedStyleValue]);

  useEffect(() => {
    FilterPlotDirect(selectedDirecValue);
  }, [selectedDirecValue]);

  useEffect(() => {
    FilterFloorType(selectedFloorValue);
  }, [selectedFloorValue]);

  // handle Function Filter Plot Area
  function FilterPlotAreas(plotArea) {
    // console.log('PlotARea='+plotArea);
    setSelectedPlotAreaValue(plotArea);
    // console.log('selectedPlotAreaValue='+selectedPlotAreaValue);
    let filterData = [];
    let mainData = localDataFloor;
    //  console.log('MainData='+mainData);

    if (isUnit) {
      if (selectedPlotAreaValue === "Any PlotArea") {
        // console.log('selectedStyleValue='+selectedStyleValue);
        filterData = mainData.filter(
          (item) =>
            (selectedStyleValue === "Any Unit" ||
              (selectedStyleValue === "4BHK+"
                ? parseInt(item?.floorObj.Unit_Type__c) >= 4
                : item?.floorObj.Unit_Type__c === selectedStyleValue)) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      } else {
        // console.log('If');
        const plotAreaParts = selectedPlotAreaValue.split("-");
        const minPlotArea = parseInt(plotAreaParts[0]);
        const maxPlotArea = parseInt(plotAreaParts[1]);
        // console.log('MinPlotARea='+minPlotArea);
        // console.log('maxPlotArea='+maxPlotArea);
        filterData = mainData.filter(
          (item) =>
            item?.floorObj.Plot_Area__c >= minPlotArea &&
            item?.floorObj.Plot_Area__c <= maxPlotArea &&
            (selectedStyleValue === "Any Unit" ||
              (selectedStyleValue === "4BHK+"
                ? parseInt(item?.floorObj.Unit_Type__c) >= 4
                : item?.floorObj.Unit_Type__c === selectedStyleValue)) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );

        //  console.log('FilterDataElse='+filterData);
      }
    } else {
      if (selectedPlotAreaValue === "Any PlotArea") {
        filterData = mainData.filter(
          (item) =>
            (selectedStyleValue === "Any Category" ||
              item?.floorObj.Category__c === selectedStyleValue) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      } else {
        // console.log('If');
        const plotAreaParts = selectedPlotAreaValue.split("-");
        const minPlotArea = parseInt(plotAreaParts[0]);
        const maxPlotArea = parseInt(plotAreaParts[1]);
        // console.log('MinPlotARea='+minPlotArea);
        // console.log('maxPlotArea='+maxPlotArea);
        filterData = mainData.filter(
          (item) =>
            item?.floorObj.Plot_Area__c >= minPlotArea &&
            item?.floorObj.Plot_Area__c <= maxPlotArea &&
            (selectedStyleValue === "Any Category" ||
              item?.floorObj.Category__c === selectedStyleValue) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      }
    }
    //  console.log('FilterData='+filterData);

    SetNakshaData(filterData);
  }

  // handle Styles Picklist
  function FilterStyles(style) {
    // console.log('Hello='+selectedPlotAreaValue);
    setSelectedStyleValue(style);
    let filterData = [];
    let mainData = localDataFloor;

    if (isUnit) {
      if (selectedPlotAreaValue === "Any PlotArea") {
        // console.log('If');
        filterData = mainData.filter(
          (item) =>
            (selectedStyleValue === "Any Unit" ||
              (selectedStyleValue === "4BHK+"
                ? parseInt(item?.floorObj.Unit_Type__c) >= 4
                : item?.floorObj.Unit_Type__c === selectedStyleValue)) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      } else {
        // console.log('If');
        const plotAreaParts = selectedPlotAreaValue.split("-");
        const minPlotArea = parseInt(plotAreaParts[0]);
        const maxPlotArea = parseInt(plotAreaParts[1]);
        // console.log('MinPlotARea='+minPlotArea);
        // console.log('maxPlotArea='+maxPlotArea);
        filterData = mainData.filter(
          (item) =>
            item?.floorObj.Plot_Area__c >= minPlotArea &&
            item?.floorObj.Plot_Area__c <= maxPlotArea &&
            (selectedStyleValue === "Any Unit" ||
              (selectedStyleValue === "4BHK+"
                ? parseInt(item?.floorObj.Unit_Type__c) >= 4
                : item?.floorObj.Unit_Type__c === selectedStyleValue)) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      }
    } else {
      if (selectedPlotAreaValue === "Any PlotArea") {
        filterData = mainData.filter(
          (item) =>
            (selectedStyleValue === "Any Category" ||
              item?.floorObj.Category__c === selectedStyleValue) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      } else {
        // console.log('If');
        const plotAreaParts = selectedPlotAreaValue.split("-");
        const minPlotArea = parseInt(plotAreaParts[0]);
        const maxPlotArea = parseInt(plotAreaParts[1]);
        // console.log('MinPlotARea='+minPlotArea);
        // console.log('maxPlotArea='+maxPlotArea);
        filterData = mainData.filter(
          (item) =>
            item?.floorObj.Plot_Area__c >= minPlotArea &&
            item?.floorObj.Plot_Area__c <= maxPlotArea &&
            (selectedStyleValue === "Any Category" ||
              item?.floorObj.Category__c === selectedStyleValue) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      }
    }

    SetNakshaData(filterData);
  }

  function FilterPlotDirect(plotArea) {
    setSelectedDirecValue(plotArea);
    let filterData = [];
    let mainData = localDataFloor;

    if (isUnit) {
      if (selectedPlotAreaValue === "Any PlotArea") {
        // console.log('If');
        filterData = mainData.filter(
          (item) =>
            (selectedStyleValue === "Any Unit" ||
              (selectedStyleValue === "4BHK+"
                ? parseInt(item?.floorObj.Unit_Type__c) >= 4
                : item?.floorObj.Unit_Type__c === selectedStyleValue)) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      } else {
        // console.log('If');
        const plotAreaParts = selectedPlotAreaValue.split("-");
        const minPlotArea = parseInt(plotAreaParts[0]);
        const maxPlotArea = parseInt(plotAreaParts[1]);
        // console.log('MinPlotARea='+minPlotArea);
        // console.log('maxPlotArea='+maxPlotArea);
        filterData = mainData.filter(
          (item) =>
            item?.floorObj.Plot_Area__c >= minPlotArea &&
            item?.floorObj.Plot_Area__c <= maxPlotArea &&
            (selectedStyleValue === "Any Unit" ||
              (selectedStyleValue === "4BHK+"
                ? parseInt(item?.floorObj.Unit_Type__c) >= 4
                : item?.floorObj.Unit_Type__c === selectedStyleValue)) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      }
    } else {
      if (selectedPlotAreaValue === "Any PlotArea") {
        filterData = mainData.filter(
          (item) =>
            (selectedStyleValue === "Any Category" ||
              item?.floorObj.Category__c === selectedStyleValue) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      } else {
        // console.log('If');
        const plotAreaParts = selectedPlotAreaValue.split("-");
        const minPlotArea = parseInt(plotAreaParts[0]);
        const maxPlotArea = parseInt(plotAreaParts[1]);
        // console.log('MinPlotARea='+minPlotArea);
        // console.log('maxPlotArea='+maxPlotArea);
        filterData = mainData.filter(
          (item) =>
            item?.floorObj.Plot_Area__c >= minPlotArea &&
            item?.floorObj.Plot_Area__c <= maxPlotArea &&
            (selectedStyleValue === "Any Category" ||
              item?.floorObj.Category__c === selectedStyleValue) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      }
    }

    SetNakshaData(filterData);
  }

  // handle Styles Picklist
  function FilterFloorType(style) {
    // console.log('selectedPlotAreaValue='+selectedPlotAreaValue);
    setSelectedFloorValue(style);
    let filterData = [];
    let mainData = localDataFloor;

    if (isUnit) {
      if (selectedPlotAreaValue === "Any PlotArea") {
        // console.log('If');
        filterData = mainData.filter(
          (item) =>
            (selectedStyleValue === "Any Unit" ||
              (selectedStyleValue === "4BHK+"
                ? parseInt(item?.floorObj.Unit_Type__c) >= 4
                : item?.floorObj.Unit_Type__c === selectedStyleValue)) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      } else {
        // console.log('If');
        const plotAreaParts = selectedPlotAreaValue.split("-");
        const minPlotArea = parseInt(plotAreaParts[0]);
        const maxPlotArea = parseInt(plotAreaParts[1]);
        // console.log('MinPlotARea='+minPlotArea);
        // console.log('maxPlotArea='+maxPlotArea);
        filterData = mainData.filter(
          (item) =>
            item?.floorObj.Plot_Area__c >= minPlotArea &&
            item?.floorObj.Plot_Area__c <= maxPlotArea &&
            (selectedStyleValue === "Any Unit" ||
              (selectedStyleValue === "4BHK+"
                ? parseInt(item?.floorObj.Unit_Type__c) >= 4
                : item?.floorObj.Unit_Type__c === selectedStyleValue)) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      }
    } else {
      if (selectedPlotAreaValue === "Any PlotArea") {
        filterData = mainData.filter(
          (item) =>
            (selectedStyleValue === "Any Category" ||
              item?.floorObj.Category__c === selectedStyleValue) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      } else {
        // console.log('If');
        const plotAreaParts = selectedPlotAreaValue.split("-");
        const minPlotArea = parseInt(plotAreaParts[0]);
        const maxPlotArea = parseInt(plotAreaParts[1]);
        // console.log('MinPlotARea='+minPlotArea);
        // console.log('maxPlotArea='+maxPlotArea);
        filterData = mainData.filter(
          (item) =>
            item?.floorObj.Plot_Area__c >= minPlotArea &&
            item?.floorObj.Plot_Area__c <= maxPlotArea &&
            (selectedStyleValue === "Any Category" ||
              item?.floorObj.Category__c === selectedStyleValue) &&
            (selectedDirecValue === "Any Direction" ||
              item?.floorObj.Facing__c === selectedDirecValue) &&
            (selectedFloorValue === "Any Floor" ||
              (selectedFloorValue === "4+"
                ? item?.floorObj.Floor__c >= 4
                : item?.floorObj.Floor__c == selectedFloorValue))
        );
      }
    }

    SetNakshaData(filterData);
  }

  // Handle CallSearch Btn to handle filters
  function callSearchBtn() {
    // console.log('Hello');
    const selectElementPlot = document.getElementById("category-filterPlot");

    const selectElementUnit = document.getElementById("category-filterUnit");
    const selectElementDirec = document.getElementById("category-filterDirec");
    const selectElementFloor = document.getElementById("category-filterFloor");

    selectElementPlot.value = "Any PlotArea";
    setSelectedPlotAreaValue("Any PlotArea");

    if (isUnit) {
      selectElementUnit.value = "Any Unit";
      setSelectedStyleValue("Any Unit");
    } else {
      selectElementUnit.value = "Any Category";
      setSelectedStyleValue("Any Category");
    }

    selectElementDirec.value = "Any Direction";
    selectElementFloor.value = "Any Floor";

    setSelectedFloorValue("Any Floor");
    setSelectedDirecValue("Any Direction");

    let filterData = [];
    let mainData = localDataFloor;
    filterData = mainData;

    SetNakshaData(filterData);
  }
  // useEffect(() => {

  // }, );

  function backButtonCall() {
    // window.history.back();
    localStorage.setItem("BackBtnVal", false);
    // console.log("Hello Back Btn");
    SetProjectPageOpen(false);

    setTimeout(() => {
      const selectElementPlot = document.getElementById("category-filterPlot");
      selectElementPlot.value = selectedPlotAreaValue;
      const selectElementStyle = document.getElementById("category-filterUnit");
      selectElementStyle.value = selectedStyleValue;
      const selectElementDirec = document.getElementById(
        "category-filterDirec"
      );
      selectElementDirec.value = selectedDirecValue;
      const selectElementFloor = document.getElementById(
        "category-filterFloor"
      );
      selectElementFloor.value = selectedFloorValue;
    }, 200);
  }

  function backButtonCallToHome() {
    setFloorPlanOpenPage("d-block");
    setIsUnit(true);
    // window.history.back();
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    zIndex: "999",
  };

  const openModal = () => {
    setIsOpen(true);
    getDetailsFormPage.full_Name = "";
    getDetailsFormPage.Company = "";
    getDetailsFormPage.mobileNumber = "";
    getDetailsFormPage.email = "";

    setFnameWarning(false);
    setFnameDigitError(false);
    setPhoneWarning(false);
    setEmailWarning(false);
    setCompanyWarning(false);
    document.body.style.overflow = "hidden";
  };

  function afterOpenModal() {
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    getDetailsFormPage.full_Name = "";
    getDetailsFormPage.Company = "";
    getDetailsFormPage.mobileNumber = "";
    getDetailsFormPage.email = "";

    setFnameWarning(false);
    setFnameDigitError(false);
    setPhoneWarning(false);
    setEmailWarning(false);
    setCompanyWarning(false);
    document.body.style.overflow = "auto";
  }

  const showMainPage = (event) => {
    event.preventDefault();
    window.location.href = "/";
  };

  var privacy_policy =
    process.env.PUBLIC_URL +
    "assets/privacy_policy/Privacy Policy_Spacify India.pdf";
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        rtl={false}
        draggable
        theme="light"
        style={{ zIndex: 9999999 }}
      />

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        // className="123Hello"
      >
        <form className="login floorModal">
          <button className="closeBtnFloor" onClick={closeModal}>
            &#x2715;
          </button>
          <h2>Get In Touch</h2>
          <input
            onChange={handleInputChange}
            value={getDetailsFormPage.full_Name}
            id="full_Name"
            name="full_Name"
            autoComplete="off"
            maxLength={100}
            type="text"
            required="true"
            placeholder="Full Name*"
          />
          {fnameWarning && (
            <label style={{ color: "red", marginTop: "-5%" }}>
              First Name Contains Atleast 3 Characters.
            </label>
          )}
          {fnameDigitError && (
            <label style={{ color: "red", marginTop: "-5%" }}>
              Name Not Contains Digits
            </label>
          )}
          <input
            id="Company"
            name="Company"
            type="text"
            required="true"
            placeholder="Company"
            autoComplete="off"
            onChange={handleInputChange}
            value={getDetailsFormPage.Company}
          />
          {companyWarning && (
            <label style={{ color: "red", marginTop: "-5%" }}>
              Company Name Contains Atleast 1 Character.
            </label>
          )}

          <input
            id="email"
            name="email"
            required="true"
            placeholder="Email"
            autoComplete="off"
            onChange={handleInputChange}
            value={getDetailsFormPage.email}
            type="email"
          />
          {emailWarning && (
            <label style={{ color: "red", marginTop: "-5%" }}>
              Email Must Be Correct.
            </label>
          )}
          <input
            id="mobileNumber"
            name="mobileNumber"
            autoComplete="off"
            required="true"
            onChange={handleInputChange}
            onKeyDown={validatePhoneNumber}
            value={getDetailsFormPage.mobileNumber}
            type="tel"
            maxLength="10"
            placeholder="Mobile Number"
          />
          {phoneWarning && (
            <label style={{ color: "red", marginTop: "-5%" }}>
              Phone No. Contains 10 Digits
            </label>
          )}
          <select onChange={handleSelectChange} name="area">
            <option value="Less than 2000 sq feet">
              Less than 2000 sq feet
            </option>
            <option value="2000-5000 sq feet">2000-5000 sq feet</option>
            <option value="5000+ sq feet">5000+ sq feet</option>
          </select>
          <button
            className="buttonFormMainHome"
            disabled={submitDisBtn}
            onClick={submitBtnClick}
          >
            Submit
          </button>
        </form>
      </Modal>

      {userloggedin ? (
        <Dashboard
          setuserloggedin={setuserloggedin}
          showOrHideProjectModal={showOrHideProjectModal}
          setshowOrHideProjectModal={setshowOrHideProjectModal}
        />
      ) : (
        <>
          {/* Navbar Section */}

          <FloorNavBar
            setuserloggedin={setuserloggedin}
            setshowOrHideProjectModal={setshowOrHideProjectModal}
          />

          {/* Navbar Section */}
          {/* mainHome */}
          {FloorPlanOpenPage === "d-block" ? (
            <section
              className="mainHomeSection"
              style={{
                backgroundImage: 'url("assets/images/floorBack.jpg")',
                marginTop: "-72px",
              }}
            >
              <div className="MainHomeDiv">
                <div className="MainHomeDivInside1">
                  <div className="divBlockHome">
                    <h1>
                      <span style={{ color: "#e89246" }}>
                        {" "}
                        Transform your workspace
                      </span>
                      <br />
                      with technology-driven architectural Solutions
                    </h1>
                    <p>
                      With in-house built technology , we specialise in
                      designing customised solutions that align with your brand
                      identity and business objectives.
                    </p>
                  </div>
                </div>
                <div className="MainHomeDivInside2">
                  <form className="login">
                    <h2>Get In Touch</h2>
                    <input
                      onChange={handleInputChange}
                      value={getDetailsFormPage.full_Name}
                      id="full_Name"
                      name="full_Name"
                      autoComplete="off"
                      maxLength={100}
                      type="text"
                      required="true"
                      placeholder="Full Name*"
                    />
                    {fnameWarning && (
                      <label style={{ color: "red", marginTop: "-5%" }}>
                        First Name Contains Atleast 3 Characters.
                      </label>
                    )}
                    {fnameDigitError && (
                      <label style={{ color: "red", marginTop: "-5%" }}>
                        Name Not Contains Digits
                      </label>
                    )}
                    <input
                      id="Company"
                      name="Company"
                      type="text"
                      required="true"
                      placeholder="Company"
                      autoComplete="off"
                      onChange={handleInputChange}
                      value={getDetailsFormPage.Company}
                    />
                    {companyWarning && (
                      <label style={{ color: "red", marginTop: "-5%" }}>
                        Company Name Contains Atleast 1 Character.
                      </label>
                    )}

                    <input
                      id="email"
                      name="email"
                      required="true"
                      placeholder="Email"
                      autoComplete="off"
                      onChange={handleInputChange}
                      value={getDetailsFormPage.email}
                      type="email"
                    />
                    {emailWarning && (
                      <label style={{ color: "red", marginTop: "-5%" }}>
                        Email Must Be Correct.
                      </label>
                    )}
                    <input
                      id="mobileNumber"
                      name="mobileNumber"
                      autoComplete="off"
                      required="true"
                      onChange={handleInputChange}
                      onKeyDown={validatePhoneNumber}
                      value={getDetailsFormPage.mobileNumber}
                      type="tel"
                      maxLength="10"
                      placeholder="Mobile Number"
                    />
                    {phoneWarning && (
                      <label style={{ color: "red", marginTop: "-5%" }}>
                        Phone No. Contains 10 Digits
                      </label>
                    )}
                    <select onChange={handleSelectChange} name="area">
                      <option value="Less than 2000 sq feet">
                        Less than 2000 sq feet
                      </option>
                      <option value="2000-5000 sq feet">
                        2000-5000 sq feet
                      </option>
                      <option value="5000+ sq feet">5000+ sq feet</option>
                    </select>
                    <button
                      className="buttonFormMainHome"
                      disabled={submitDisBtn}
                      onClick={submitBtnClick}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </section>
          ) : null}
          {/* mainHome */}

          {/* LandingComp */}
          {FloorPlanOpenPage === "d-block" ? (
            <section
              id="about-boxes"
              className="about-boxesLanding"
              style={{
                backgroundColor: "#fff",
                paddingLeft: "20vh",
                paddingRight: "20vh",
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              <div className="LandingCmpHead">
                <div className="LandingCmpHeadDiv1">
                  <div></div>
                </div>
                <div className="LandingCmpHeadDiv2">
                  <h2>
                    Experience the combined power of
                    <br />
                    <span style={{ color: "#e89246" }}>
                      {" "}
                      Technology and Design
                    </span>
                  </h2>
                </div>
              </div>
              <div className="blogSecondHead">
                Revamp your living or working space with SpacifyIndia's
                innovative architectural designs, incorporating the latest
                technology to craft modern and inspiring environments. Infuse
                artistic flair and ergonomic principles to optimize
                functionality and enrich user experience. Collaborate with us to
                transform your residential or commercial space and elevate its
                essence to new heights.
              </div>
              <div className="row">
                <div
                  className=" col-12 col-lg-4 col-md-4  col-sm-6 d-flex align-items-stretch columnLanding"
                  // data-aos="fade-up"
                  // data-aos-delay={100}
                >
                  <div className="cardLanding">
                    <div className="imgDivLanding">
                      <img src="assets/images/WhatweDo.svg"></img>
                    </div>
                    <div className="imgDivH2">
                      <h2>Cutting edge technology</h2>
                    </div>
                  </div>
                </div>
                <div
                  className=" col-12 col-lg-4 col-md-4  col-sm-6 d-flex align-items-stretch columnLanding"
                  // data-aos="fade-up"
                  // data-aos-delay={200}
                >
                  <div className="cardLanding">
                    <div className="imgDivLanding">
                      <img src="assets/images/WhatweDo2.svg"></img>
                    </div>
                    <div className="imgDivH2">
                      <h2>Global design community</h2>
                    </div>
                  </div>
                </div>
                <div
                  className=" col-12 col-lg-4 col-md-4 col-sm-6 d-flex align-items-stretch columnLanding"
                  // data-aos="fade-up"
                  // data-aos-delay={300}
                >
                  <div className="cardLanding">
                    <div className="imgDivLanding">
                      <img src="assets/images/WhatweDo3.svg"></img>
                    </div>
                    <div className="imgDivH2">
                      <h2>Extensive execution ecosystem</h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          {/* LandingComp */}

          {/* House Design */}
          {FloorPlanOpenPage === "d-block" ? (
            <section className="FloorSection">
              <div className="ServiceHead">
                <div className="TopHeadingFloor">
                  <span style={{ color: "#e89246" }}>House</span> Design By Area{" "}
                  <span>
                    <i
                      style={{ color: "#e89246" }}
                      className="fa fa-home"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
              </div>

              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BeatLoader color="#e89246" loading={loading} size={18} />
                </div>
              ) : (
                <div
                  className="container Parent "
                  style={{
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    margin: "auto",
                  }}
                >
                  <Carousel
                    responsive={responsive}
                    showDots={true}
                    className="serviceCrauselHome"
                  >
                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openHouseData("0-1000sqft");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/house1.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>0-1000sqft</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openHouseData("1000-2000sqft");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/house2.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>1000-2000sqft</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openHouseData("2000-3000sqft");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/House3.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>2000-3000sqft</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openHouseData("3000-4000sqft");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/House4.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>3000-4000sqft</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openHouseData("4000-6000sqft");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/house5.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>4000-6000sqft</h4>
                          </div>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              )}
            </section>
          ) : null}

          {/* House Design */}

          {/*Commercial  Design */}
          {FloorPlanOpenPage === "d-block" ? (
            <section className="FloorSection" style={{ background: "white" }}>
              <div className="ServiceHead">
                <div className="TopHeadingFloor">
                  <span style={{ color: "#e89246" }}>Commercial</span> Designs{" "}
                  <span>
                    <i
                      style={{ color: "#e89246" }}
                      className="fa fa-building"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
              </div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BeatLoader color="#e89246" loading={loading} size={18} />
                </div>
              ) : (
                <div
                  className="container Parent"
                  style={{
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    margin: "auto",
                  }}
                >
                  <Carousel
                    responsive={responsive}
                    showDots={true}
                    className="serviceCrauselHome"
                  >
                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloorComm"
                        onClick={() => {
                          openCategoryData("Appartment");
                        }}
                      >
                        <div className="imgDivPropCardFloorComm">
                          <img src="assets/images/Appartments.jpg" />
                        </div>

                        <div className="textCardPropFloorComm">
                          <div className="textCardProp1FloorComm">
                            <h4>Appartment</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloorComm"
                        onClick={() => {
                          openCategoryData("Corporate Buildings");
                        }}
                      >
                        <div className="imgDivPropCardFloorComm">
                          <img src="assets/images/Corporate.jpg" />
                        </div>

                        <div className="textCardPropFloorComm">
                          <div className="textCardProp1FloorComm">
                            <h4>Corporate Buildings</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloorComm"
                        onClick={() => {
                          openCategoryData("Shopping Complex");
                        }}
                      >
                        <div className="imgDivPropCardFloorComm">
                          <img src="assets/images/mall.jpg" />
                        </div>

                        <div className="textCardPropFloorComm">
                          <div className="textCardProp1FloorComm">
                            <h4>Shopping Complex</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloorComm"
                        onClick={() => {
                          openCategoryData("Hospitality");
                        }}
                      >
                        <div className="imgDivPropCardFloorComm">
                          <img src="assets/images/Hotel.jpg" />
                        </div>

                        <div className="textCardPropFloorComm">
                          <div className="textCardProp1FloorComm">
                            <h4>Hospitality</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    {/* <div
                className="slider12 propDivHoverSha serviceDivHover"
                style={{ cursor: "pointer" }}
              >
                <article
                  className="cardPropFloorComm"
                  onClick={() => {
                    openCategoryData("Hotels");
                  }}
                >
                  <div className="imgDivPropCardFloorComm">
                    <img src="assets/images/Hotel.jpg" />
                  </div>

                  <div className="textCardPropFloorComm">
                    <div className="textCardProp1FloorComm">
                      <h4>Hotels</h4>
                    </div>
                  </div>
                </article>
              </div> */}

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloorComm"
                        onClick={() => {
                          openCategoryData("Hospitals");
                        }}
                      >
                        <div className="imgDivPropCardFloorComm">
                          <img src="assets/images/Health.jpg" />
                        </div>

                        <div className="textCardPropFloorComm">
                          <div className="textCardProp1FloorComm">
                            <h4>Hospitals</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloorComm"
                        onClick={() => {
                          openCategoryData("Institutional Buildings");
                        }}
                      >
                        <div className="imgDivPropCardFloorComm">
                          <img src="assets/images/Institutional.jpg" />
                        </div>

                        <div className="textCardPropFloorComm">
                          <div className="textCardProp1FloorComm">
                            <h4>Institutional Buildings</h4>
                          </div>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              )}
            </section>
          ) : null}

          {/* Commercial Design */}

          {/* Residentail Designs */}
          {/* House Design */}
          {FloorPlanOpenPage === "d-block" ? (
            <section className="FloorSection">
              <div className="ServiceHead">
                <div className="TopHeadingFloor">
                  <span style={{ color: "#e89246" }}>Residential</span> Designs{" "}
                  <span>
                    <i
                      style={{ color: "#e89246" }}
                      className="fa fa-home"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
              </div>

              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BeatLoader color="#e89246" loading={loading} size={18} />
                </div>
              ) : (
                <div
                  className="container Parent "
                  style={{
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    margin: "auto",
                  }}
                >
                  <Carousel
                    responsive={responsive}
                    showDots={true}
                    className="serviceCrauselHome"
                  >
                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openCategoryResidenial("Simplex House Plans");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/Res1.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>Simplex House Plans</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openCategoryResidenial("Duplex House Plans");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/Res2.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>Duplex House Plans</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openCategoryResidenial("Small House Plans");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/Res3.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>Small House Plans</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openCategoryResidenial("Vastu House Plans");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/Res4.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>Vastu House Plans</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openCategoryResidenial("Popular House Plans");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/house5.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>Popular House Plans</h4>
                          </div>
                        </div>
                      </article>
                    </div>

                    <div
                      className="slider12 propDivHoverSha serviceDivHover"
                      style={{ cursor: "pointer" }}
                    >
                      <article
                        className="cardPropFloor"
                        onClick={() => {
                          openCategoryResidenial("Bungalow House Plans");
                        }}
                      >
                        <div className="imgDivPropCardFloor">
                          <img src="assets/images/Res5.jpg" />
                        </div>

                        <div className="textCardPropFloor">
                          <div className="textCardProp1Floor">
                            <h4>Bungalow House Plans</h4>
                          </div>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              )}
            </section>
          ) : null}

          {/* House Design */}
          {/* Residentail Designs */}

          {/* CountSection */}
          {/* {FloorPlanOpenPage === "d-block" ? (
            <section className="aboutCount">
              <div
                className="container"
                style={{
                  backgroundImage: 'url("assets/images/countFloor.jpg")',
                }}
              >
                <div className="row justify-content-end">
                  <div className="col-lg-11">
                    <div className="row justify-content-end">
                      <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                          <i
                            className="bi bi-collection"
                            style={{ color: "#e89246" }}
                          />
                          <span className="purecounter"> 100</span>
                          <span className="SignSpan">+</span>
                          <p>Projects Done</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                          <i
                            className="bi bi-calendar4"
                            style={{ color: "#e89246" }}
                          />
                          <span className="purecounter"> 2019</span>
                          <p>Service Since</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                          <i
                            className="bi bi-building"
                            style={{ color: "#e89246" }}
                          />
                          <span className="purecounter"> 10</span>
                          <span className="SignSpan">+</span>
                          <p>Cities Covered</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                          <i
                            className="bi bi-emoji-smile"
                            style={{ color: "#e89246" }}
                          />
                          <span className="purecounter">500</span>
                          <span className="SignSpan">+</span>
                          <p>Happy Clients</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divCountBottom">
                  <p
                    style={{
                      fontSize: "22px",
                      color: "white",
                      fontWeight: "500",
                    }}
                  >
                    Explore More, & Get Best Offers
                  </p>
                  <button className="countEnqBtn" onClick={openModal}>
                    Enquire Now
                  </button>
                </div>
              </div>
            </section>
          ) : null} */}
          {/* CountSection */}

          {/*What we do */}
          {FloorPlanOpenPage === "d-block" ? <ClientJourney /> : null}
          {/*What we do */}

          {/* ======= About Section ======= */}
          {FloorPlanOpenPage === "d-block" ? (
            <section className="about" id="about" style={{ padding: "20px" }}>
              <div
                className="container"
                //  data-aos="fade-up"
              >
                <div style={{ paddingTop: "20px" }}>
                  <h3
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      fontSize: "24px",
                      color: "#263d4d",
                    }}
                  >
                    About Us
                  </h3>
                </div>
                <br />
                <div className="row">
                  <div
                    className="col-lg-6 video-box align-self-baseline"
                    // data-aos="zoom-in"
                    // data-aos-delay={50}
                  >
                    <img
                      src="assets/images/aboutUsImg.webp"
                      className="img-fluid"
                      alt=""
                    />
                    {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="glightbox play-btn mb-4"></a> */}
                  </div>
                  <div className="col-lg-6 pt-3 pt-lg-0 content">
                    <p>
                      Spacify is a creative design practice whose work
                      seamlessly weaves art and style in interior spaces, from
                      large space ideas to the smallest of furniture details. We
                      believe in creating sensory experience for people through
                      timeless design.
                    </p>
                    <p>
                      We take complete pride in designing and furnishing your
                      complete home or a section or a specialized room in our
                      signature, luxurious, bespoke and timeless appeal. We
                      customize and personalize your abode into an end result
                      that is plush, and elegant and sophisticated. Our designs
                      exude luxury and finesse. At Spacify, we believe that
                      every space has the potential to become extraordinary.
                      Whether it's a residential home, office, or commercial
                    </p>
                    <p>
                      We understand that your space is a reflection of your
                      identity, and we take pride in translating your ideas into
                      stunning reality. Our approach is rooted in collaboration
                      and attention to detail
                    </p>
                    <p>
                      We bring expertise in various areas of technology to
                      deliver comprehensive solutions to our clients. Our core
                      areas of expertise include Software Development, Cloud
                      Computing, Cybersecurity, Data Analytics, Artificial
                      Intelligence and Machine Learning.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          {/* End About Section */}

          {/* FloorData Show */}
          {FloorPlanOpenPage === "d-none" ? (
            <div
              className="FloorPlanSection"
              id="FloorPlans"
              style={{ marginTop: "-5rem" }}
            >
              {/* <div onClick={openModal}>  
        <button>Floor</button> 
      </div> */}
              {/* for change Section Floor To One Project Open */}
              {/* for change Section Floor To One Project Open */}
              {projectPageOpen ? (
                <>

{loading ? (
                <div
                  style={{
                    height:"300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ScaleLoader color="#e89246" loading={loading} size={18} />
                </div>
              ) : (
                <>
                <div
                style={{
                  marginTop: "3rem",
                  marginLeft: "3rem",
                  backgroundColor: "#e89246",
                  color: "white",
                  width: "76px",
                  padding: "5px",
                  borderRadius: "20px",
                  cursor: "Pointer",
                  position: "relative",
                  zIndex: "999",
                  display: "none",
                }}
                className="backBtn"
                onClick={backButtonCall}
              >
                <span>
                  <i className="fa fa-arrow-left ml-2" />{" "}
                </span>
                <span>Back</span>
              </div>
              
              <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 section px-5 mx-5 pt-5 projectOpenSection">
                <div className="col carouselCol">
                  {/* <Carousel
                    responsive={responsiveForModalCraousel}
                    className="floorDivCarousel"
                  >
                    {imageDataList?.length > 0 &&
                      imageDataList.map((item) => (
                        <div className="imgDivFloorCaarousel">
                          <img src={item} className="imgCrausel" />
                        </div>
                      ))}
                  </Carousel> */}
                  <PhotoProvider>
                    <Carousel
                      responsive={responsiveForModalCraousel}
                      className="floorDivCarousel"
                    >
                      {imageDataList?.length > 0 &&
                        imageDataList.map((item, index) => (
                          <div className="imgDivFloorCaarousel" key={index}>
                            <PhotoView src={item}>
                              <img
                                src={item}
                                className="imgCrausel"
                                alt={`Image ${index}`}
                              />
                            </PhotoView>
                          </div>
                        ))}
                    </Carousel>
                  </PhotoProvider>
                </div>

                <div className="col">
                  {/* <h1 className="post-title pb-3 fs-1rem">Affordable House Plan</h1> */}

                  <div className="wt-product-text mobile-hidden">
                    <p className="text-justify mb-1 mt-3 border-style"></p>
                    <div className="single-product-title ">
                      <h4 className="post-title mb-1 fs-custom">
                        {/* {singleNakshaData[0]?.floorObj.Plot_Dimensions__c} sqft{" "} */}
                        {singleNakshaData[0]?.floorObj.Name}
                      </h4>
                    </div>

                    <p />
                  </div>

                  <div className="grid-2">
                    <div className="cell-2 bl-0" id={1}>
                      <img
                        alt="share"
                        className="detailIcon"
                        src="/assets/images/PlotArea.png"
                      />{" "}
                      Plot Area <br />
                      {singleNakshaData[0]?.floorObj.Plot_Area__c}
                    </div>
                    <div className="cell-2 " id={2}>
                      <img
                        alt="share"
                        className="detailIcon"
                        src="/assets/images/PlotDimen.png"
                      />{" "}
                      Plot Dimensions <br />
                      {singleNakshaData[0]?.floorObj.Plot_Dimensions__c}
                    </div>
                    <div className="cell-2 bl-0 bb-0" id={3}>
                      <img
                        alt="share"
                        // projectOpenSection
                        className="detailIcon"
                        src="/assets/images/Facing.png"
                      />{" "}
                      Facing <br />
                      {singleNakshaData[0]?.floorObj.Facing__c}
                    </div>
                    <div className="cell-2 bb-0 pt-4m" id={4}>
                      <img
                        alt="share"
                        className="detailIcon"
                        src="/assets/images/PlotArea.png"
                      />{" "}
                      Style <br />
                      {singleNakshaData[0]?.floorObj.Style__c}
                    </div>
                  </div>

                  <div
                    style={{
                      // textAlign: "center",
                      paddingTop: "4vh",
                      paddingBottom: "2vh",
                      paddingRight: "3vh",
                    }}
                    className="detailDivUpperDiv"
                  >
                    <div className="detailFlooBtnDiv1">
                      {singleNakshaData[0]?.floorObj.Code__c}
                    </div>

                    <button
                      className="site-cart-btn site-button detail-btn "
                      style={{ marginRight: "1rem", color: "white" }}
                      onClick={openModal}
                    >
                      Enquire Now
                    </button>
                  </div>

                  <div className=" mt-4">
                    <div className>
                      <div className>
                        <h4 className="plan_details">Plan Details</h4>
                        <div className="grid">
                          <div className="cell">
                            Floor <br />
                            {singleNakshaData[0]?.floorObj.Floor__c}{" "}
                          </div>

                          {/* <div className="cell" id={2}>
                        Bathroom <br />{" "}
                        {singleNakshaData[0]?.floorObj.Bedroom__c}{" "}
                      </div>
                      <div className="cell" id={3}>
                        Floor <br />
                        {singleNakshaData[0]?.floorObj.Floor__c}{" "}
                      </div>
                      <div className="cell" id={4}>
                        Parking <br />
                        {singleNakshaData[0]?.floorObj.Parking__c}{" "}
                      </div>
                      <div className="cell" id={4}>
                        Kitchen <br />
                        {singleNakshaData[0]?.floorObj.Kitchen__c}{" "}
                      </div>
                      <div className="cell" id={4}>
                        Hall <br />
                        {singleNakshaData[0]?.floorObj.Drawing_Hall__c}{" "}
                      </div> */}

                          {singleNakshaData[0]?.floorObj
                            .PlansAttributes__r ? (
                            singleNakshaData[0]?.floorObj.PlansAttributes__r.records.map(
                              (attribute, index) => (
                                <div className="cell" key={index}>
                                  {attribute.Attribute__r.Name} <br />
                                  {attribute.Value__c}
                                </div>
                              )
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="divDescriPlans">
                <h3>{singleNakshaData[0]?.floorObj.Name}</h3>
                <p>
                  {/* On the ground floor we have designed, parking, garden and
              common toilet in house front, inside office, living &amp;
              dining in the centre, kitchen is in south-east with store
              room, in south-west we have given two bedrooms with attached
              toilet &amp; dresser. On the first floor we have given, two
              flats, one flat consists bedroom, toilet &amp; kitchen the
              other flat consist two bedrooms with attached toilet &amp;
              dresser, living room, Kitchen &amp; dining.   */}
                  {singleNakshaData[0]?.floorObj.Description__c}
                </p>
              </div>

              </>
      
)}

              
                </>
              ) : (
                <div className="mainDivFloor">
                  <div className="section   py-5 col-xl-12 col-lg-12 col-md-12 headerDivFloor  floorIntro">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h2 className="header-h2 fw-600">
                          {mainValue} Designs
                        </h2>
                      </div>

                      <div
                        style={{
                          backgroundColor: "#e89246",
                          color: "white",
                          width: "76px",
                          padding: "5px",
                          borderRadius: "20px",
                          cursor: "Pointer",
                          display: "none",
                        }}
                        className="backBtn"
                        onClick={backButtonCallToHome}
                      >
                        <span>
                          <i className="fa fa-arrow-left ml-2" />{" "}
                        </span>
                        <span>Back</span>
                      </div>
                    </div>

                    <h1 className="header-h1 fw-600">Floor Plans</h1>
                    {/* <h3 className="header-h3">Popular House Map</h3> */}
                    <p className="fs-16 m-t0 sx-tilte fsm-14  mt-3 mb-3 text-right-mobile descrMainFloor">
                      Want to design your dream home with the best designer in
                      India, Spacify.com is the one you are looking for.
                      Spacify.com offers a variety of ready-made home plans and
                      house designs at a very reasonable cost. We are open to
                      listen to your requirements in detail and provide you the
                      best cut designs as per your requirements and taste , and
                      if you want some modifications in that, we are delighted
                      to do that too.
                    </p>
                    <p />
                    <div className=" ">
                      <button
                        className="site-button2 fw-400"
                        fdprocessedid="1t4szh"
                        onClick={openModal}
                      >
                        Get Yours Now
                      </button>
                    </div>
                  </div>
                  <div className="row row-cols-2 row-cols-sm-4 row-cols-md-5 row-cols-lg-5 g-3 px-5  mb-4 cardFloor  ">
                    <div
                      className="col"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="columns medium-4">
                        <select
                          id="category-filterPlot"
                          className="video-filter plotAreaFloor"
                          data-filter-group="category"
                          style={{
                            padding: "5px",
                            borderRadius: "5px",
                            width: "20vh",
                          }}
                          onChange={(event) => {
                            const selectedValue = event.target.value;
                            FilterPlotAreas(selectedValue);
                          }}
                        >
                          <option value="Any PlotArea" selected="selected">
                            Any PlotArea
                          </option>
                          <option value="0-1000sqft">0-1000sqft</option>
                          <option value="1000-2000sqft">1000-2000sqft</option>
                          <option value="2000-3000sqft">2000-3000sqft</option>
                          <option value="3000-4000sqft">3000-4000sqft</option>
                          <option value="4000-6000sqft">4000-6000sqft</option>
                        </select>
                      </div>
                    </div>

                    {isUnit ? (
                      <div
                        className="col"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="columns medium-4">
                          <select
                            id="category-filterUnit"
                            className="video-filter UnitFloor"
                            data-filter-group="category"
                            style={{
                              padding: "5px",
                              borderRadius: "5px",
                              width: "16vh",
                            }}
                            onChange={(event) => {
                              const selectedStyle = event.target.value;
                              FilterStyles(selectedStyle);
                            }}
                          >
                            <option value="Any Unit" selected="selected">
                              Any Unit
                            </option>
                            <option value="1BHK">1BHK</option>
                            <option value="2BHK">2BHK</option>
                            <option value="3BHK">3BHK</option>
                            <option value="4BHK">4BHK</option>
                            <option value="4BHK+">4BHK+</option>
                          </select>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="col"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="columns medium-4">
                          <select
                            id="category-filterUnit"
                            className="video-filter UnitFloor"
                            data-filter-group="category"
                            style={{
                              padding: "5px",
                              borderRadius: "5px",
                              width: "20vh",
                            }}
                            onChange={(event) => {
                              const selectedStyle = event.target.value;
                              FilterStyles(selectedStyle);
                            }}
                          >
                            <option value="Any Category" selected="selected">
                              Any Category
                            </option>
                            {/* <option value="Appartment">Appartment</option>
                        <option value="Corporate Buildings">
                          Corporate Buildings
                        </option>
                        <option value="Shopping Complex">
                          Shopping Complex
                        </option>
                        <option value="Hospitality">Hospitality</option>
                        <option value="Hospitals">Hospitals</option>
                        <option value="Institutional Buildings">
                          Institutional Buildings
                        </option> */}

                            {categoryList.map((item, i) => (
                              <option value={item} key={i}>{item}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}

                    <div
                      className="col"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="columns medium-4">
                        <select
                          id="category-filterDirec"
                          className="video-filter DirectionFloor"
                          data-filter-group="category"
                          style={{
                            padding: "5px",
                            borderRadius: "5px",
                            width: "19vh",
                          }}
                          onChange={(event) => {
                            const selectedValue = event.target.value;
                            FilterPlotDirect(selectedValue);
                          }}
                        >
                          <option value="Any Direction" selected="selected">
                            Any Direction
                          </option>
                          <option value="East">East</option>
                          <option value="West">West</option>
                          <option value="North">North</option>
                          <option value="South">South</option>
                          <option value="North-East">North-East</option>
                          <option value="North-West">North-West</option>
                          <option value="South-East">South-East</option>
                          <option value="North-West">North-West</option>
                        </select>
                      </div>
                    </div>

                    <div
                      className="col"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="columns medium-4">
                        <select
                          id="category-filterFloor"
                          className="video-filter NumberFloor"
                          data-filter-group="category"
                          style={{
                            padding: "5px",
                            borderRadius: "5px",
                            width: "15vh",
                          }}
                          onChange={(event) => {
                            const selectedValue = event.target.value;
                            FilterFloorType(selectedValue);
                          }}
                        >
                          <option value="Any Floor" selected="selected">
                            Any Floor
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="4+">4+</option>
                        </select>
                      </div>
                    </div>

                    <div
                      className="col"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {/* <button
             className="searchButton"      
              >
                Search
              </button> */}
                      <button
                        className="searchButton"
                        id="clearFilter"
                        onClick={callSearchBtn}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>

                  {loading ? (
                    <div
                      style={{
                        height:"250px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ScaleLoader color="#e89246" loading={loading} size={18} />
                    </div>
                  ) : (
                    <div className="section px-5 mx-5 cardFloor">
                      {nakshaData?.length > 0 ? (
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
                          {/* loop over nakshaData to render cards of floorPlans Data */}
                          {nakshaData.map((item) => (
                            <div
                              className="col CardInResponsive"
                              key={item?.floorObj.Id}
                            >
                              <div
                                className="card shadow-md imgDivFloorMain"
                                style={{ maxWidth: "18rem" }}
                              >
                                <img
                                  onClick={() => {
                                    viewDetailsCall(item?.floorObj.Id);
                                  }}
                                  src={item?.floorImageUrlList[0]}
                                  className="card-img-top"
                                  alt="..."
                                  style={{ padding: "1vh 2vh" }}
                                />
                                <div className="card-body padding-0-2vh">
                                  <h5 className="card-title floorCardTitle">
                                    {item?.floorObj.Name}
                                  </h5>
                                  <p className="IconFloorPart">
                                    <span className="SpanIconCardFloor">
                                      <i className="fab fa-expand iconCard" />
                                      &nbsp;
                                      {item?.floorObj.Plot_Area__c} sqft
                                    </span>{" "}
                                    <span className="SpanIconCardFloor">
                                      <i className="fab fa-compass ml-2 iconCard" />
                                      &nbsp;
                                      {item?.floorObj.Facing__c} Facing
                                    </span>
                                  </p>
                                  <p className="BottomCardPart">
                                    <span className="codeSpan">
                                      Code: {item?.floorObj.Code__c}
                                    </span>
                                    <span>
                                      <a
                                        className="btn mb-3 viewBtnCard"
                                        style={{
                                          backgroundColor: "#e89246",
                                          color: "white",
                                        }}
                                        onClick={() => {
                                          viewDetailsCall(item?.floorObj.Id);
                                        }}
                                      >
                                        View details
                                      </a>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                          {/* loop over nakshaData to render cards of floorPlans Data */}
                        </div>
                      ) : (
                        // Show blank div with image if nakshaData is empty
                        <div
                          className="blankDiv"
                          style={{ textAlign: "center", marginBottom: "3rem" }}
                        >
                          <img
                            style={{ height: "350px" }}
                            src="assets/images/NoData.png"  
                          /> 
                          <div>
                            <h2>No data Available!</h2>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {/* FloorData Show */}
          <FloorFooter setshowOrHideProjectModal={setshowOrHideProjectModal} />

          <a
            target="_blank"
            href="https://wa.link/cp1ike"
            className="ui-to-topWhatsapp  active"
          >
            <img src="https://storage.googleapis.com/bik-assets/bap%2Ffab-assets%2Fimages%2FDDQoj5ZVYwYd8aEtdFP5NRvL2Mk2%2F4b0d640ebd.png"></img>
          </a>

          <a href="tel:9654788000" className="ui-to-phone  active">
            <img src="assets/images/CallLogo.png"></img>
          </a>
        </>
      )}
    </>
  );
};

export default FloorPlans;
