import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACCESS_TOKEN_API } from "../shared/constants";
import "./BannerSection12.css";
import "./Blog.css";
import { Dashboard } from "./Dashboard";
import FloorFooter from "./FloorFooter";
import FloorNavBar from "./FloorNavBar";

function Blog() {
  const navigate = useNavigate();
  const [userloggedin, setuserloggedin] = useState(false);
  const [showOrHideProjectModal, setshowOrHideProjectModal] = useState(false);
  const [bannerData, setBannnerData] = useState([]);
  const [showContent, setShowContent] = useState(true);
  const [blogDataLocal, setBlogDataLocal] = useState([]);
  const [banBlogData, setBanBlogData] = useState([]);
  const [singleBlogData, setSingleBlogData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const openGetAQuoteModal = () => {
    document.getElementById("getAQuoteButton").click();   
  };

  const [fnameWarning, setFnameWarning] = useState(false);
  const [fnameDigitError, setFnameDigitError] = useState(false);
  const [phoneWarning, setPhoneWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);
  const [companyWarning, setCompanyWarning] = useState(false);
  const [submitDisBtn, setSubmitDisBtn] = useState(false);

  const [getDetailsFormPage, setgetDetailsFormPage] = useState({
    full_Name: "",
    Company: "",
    email: "",
    mobileNumber: "",
    area: "",
  });

  useEffect(() => {
    fetch(ACCESS_TOKEN_API, { method: "POST" }) ///getAccessToken
      .then((response) => response.json())
      .then((result) => {
        getData(result);
      })
      .catch((error) => {
        // console.log('The error in react ='+error);
      });
    if (localStorage.getItem("isLogin")) {
      setuserloggedin(true);
    } else {
      setuserloggedin(false);
    }

    let url = window.location.href;
    if(url.includes('Blogs')){
      document.body.style.setProperty('padding-top', '30px', 'important');
    }

    
  }, []);

  useEffect(() => {
    let url = window.location.href;
    if (url.includes("BlogName")) {
      let urlString = window.location.href.split("?")[1];
      urlString = urlString.split("=")[1];
      urlString = decodeURIComponent(urlString);
      if(loading === false){
        openBlogDetails(urlString);
      }
     
    }
     }, [loading]);

  const getData = (res) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${res.access_token}`);

    var raw = JSON.stringify({
      Name: "Kamran Khan",
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${res.instance_url}/services/apexrest/Interior_App_Api`,
      requestOptions
    ).then(async (response) => {
      let result = await response.json();
      const data = JSON.parse(result);
      // console.log("Data=", data.blogWrapList);

      if (
        data.blogWrapList !== null &&
        data.blogWrapList !== "" &&
        data.blogWrapList !== undefined
      ) {
        setBlogDataLocal([...data.blogWrapList]);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const validatePhoneNumber = (event) => {
    var keycode = event.which;
    if (
      !(
        (event.shiftKey === false &&
          (keycode === 46 ||
            keycode === 8 ||
            keycode === 37 ||
            keycode === 39 ||
            (keycode >= 48 && keycode <= 57))) ||
        (keycode >= 96 && keycode <= 105) ||
        keycode === 9
      )
    ) {
      event.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setgetDetailsFormPage({ ...getDetailsFormPage, [name]: value });

    if ("full_Name" === event.target.name) {
      if (event.target.value?.length >= 2) {
        setFnameWarning(false);
      } else {
        setFnameWarning(true);
      }

      const re = /^([^0-9]*)$/;
      if (!event.target.value.match(re) && event.target.value?.length > 2) {
        setFnameDigitError(true);
      } else {
        setFnameDigitError(false);
      }
    }

    if ("Company" === event.target.name) {
      if (event.target.value?.length >= 1) {
        setCompanyWarning(false);
      } else {
        setCompanyWarning(true);
      }
    }

    if ("mobileNumber" === event.target.name) {
      if (value?.length === 10) {
        setPhoneWarning(false);
      } else {
        setPhoneWarning(true);
      }
    }
    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if ("email" === event.target.name) {
      if (event.target.value.match(mailformat)) {
        setEmailWarning(false);
      } else {
        setEmailWarning(true);
      }
    }
  };

  const handleSelectChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setgetDetailsFormPage({ ...getDetailsFormPage, [name]: value });
  };

  const submitBtnClick = () => {
    if (getDetailsFormPage.full_Name?.length >= 2) {
      setFnameWarning(false);
    } else {
      setFnameWarning(true);
    }

    const re = /^([^0-9]*)$/;
    // const re = /^[0-9-+()]*$/;
    if (
      !getDetailsFormPage.full_Name.match(re) &&
      getDetailsFormPage.full_Name?.length > 2
    ) {
      setFnameDigitError(true);
    } else {
      setFnameDigitError(false);
    }

    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (getDetailsFormPage.email.match(mailformat)) {
      setEmailWarning(false);
    } else {
      setEmailWarning(true);
    }

    if (getDetailsFormPage.mobileNumber?.length === 10) {
      setPhoneWarning(false);
    } else {
      setPhoneWarning(true);
    }

    if (getDetailsFormPage.Company?.length >= 1) {
      setCompanyWarning(false);
    } else {
      setCompanyWarning(true);
    }

    if (getDetailsFormPage.full_Name?.length < 2) {
      // console.log('Hello If Name');
      setSubmitDisBtn(false);
      //  return;
    }
    if (getDetailsFormPage.mobileNumber?.length < 10) {
      // console.log('Hello If Phone');
      setSubmitDisBtn(false);
      //  return;
    }
    if (getDetailsFormPage.Company?.length < 1) {
      // console.log('Hello If City');
      setSubmitDisBtn(false);
      //  return;
    }
    if (getDetailsFormPage.email.match(mailformat)) {
      // console.log('Hello If PropertyType');
      setSubmitDisBtn(false);
    }

    if (
      getDetailsFormPage.full_Name?.length >= 2 &&
      getDetailsFormPage.mobileNumber?.length >= 10 &&
      getDetailsFormPage.Company?.length > 0 &&
      getDetailsFormPage.email.match(mailformat)
    ) {
      // console.log('Hello Else');
      setSubmitDisBtn(true);
      fetch(ACCESS_TOKEN_API, { method: "POST" })
        .then((response) => response.json())
        .then((result) => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${result.access_token}`);
          var raw = JSON.stringify({
            getAQuoteNewEnqiry: "sdfdsfdsf",
            Name: getDetailsFormPage.full_Name,
            phone: getDetailsFormPage.mobileNumber,
            company: getDetailsFormPage.Company,
            email: getDetailsFormPage.email,
            area: getDetailsFormPage.area,
          });
          // console.log("The Console Raw=" + raw);

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
          fetch(
            `${result.instance_url}/services/apexrest/Interior_App_Api`,
            requestOptions
          ).then(async (response) => {
            let result = await response.json();
            // console.log('result='+result);
            if (result === "Successfully Inserted") {
              getDetailsFormPage.full_Name = "";
              getDetailsFormPage.Company = "";
              getDetailsFormPage.mobileNumber = "";
              getDetailsFormPage.email = "";

              // console.log('getDetailsFormPage',getDetailsFormPage);

              toast.success("Request Submitted Successfully !", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
              });
              setSubmitDisBtn(false);
              // setIsOpen(false);
            } else if (result === "Failed GetAQuote") {
              getDetailsFormPage.full_Name = "";
              getDetailsFormPage.Company = "";
              getDetailsFormPage.mobileNumber = "";
              getDetailsFormPage.email = "";
              toast.error("Something Went Wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
              });
              setSubmitDisBtn(false);
              // setIsOpen(false);
            }
          });
        })
        .catch((error) => {
          // console.log('result Failed');
          getDetailsFormPage.full_Name = "";
          getDetailsFormPage.Company = "";
          getDetailsFormPage.mobileNumber = "";
          getDetailsFormPage.email = "";
          toast.error("Something Went Wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
          setSubmitDisBtn(false);
          // setIsOpen(false);
        });
    }
  };

  useEffect(() => {
    getDetailsFormPage.area = "Less than 2000 sq feet";

  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    let url = window.location.href;
    // console.log('UrlBlog='+url);
    window.scrollTo(0, 0);  
    setShowContent(true);  
  };

  useEffect(() => {
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []); 

  const openBlogDetails = (itemId) => {
    let url = window.location.href;
    navigate("/Blogs" + "?BlogName=" + encodeURIComponent(itemId), { 
      state: null,
    });
    document.body.style.setProperty('padding-top', '0px', 'important');
    // console.log("ItemId=" + itemId);
    const mainData = blogDataLocal;
    // console.log("mainData=", mainData);
    const filterData = mainData.filter((item) => item.blogObj.Name === itemId);  

    // console.log("FilterData=", filterData);
    setSingleBlogData(filterData);
    setShowContent(false);
    window.scrollTo(0, 0);
   
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        rtl={false}
        draggable
        theme="light"
        style={{ zIndex: 9999999 }}
      />

      {userloggedin ? (
        <Dashboard
          setuserloggedin={setuserloggedin}
          showOrHideProjectModal={showOrHideProjectModal}
          setshowOrHideProjectModal={setshowOrHideProjectModal}
        />
      ) : (
        <>
          {/* Navbar Section */}

          <FloorNavBar
            setuserloggedin={setuserloggedin}
            setshowOrHideProjectModal={setshowOrHideProjectModal} 
          />

          {/*NEWS SECTION*/}

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <ScaleLoader color="#e89246" loading={loading} size={18} />
            </div>
          ) : (
            <>
              {showContent && (
                <>
                <div className="BlogCntMainDivCar">
                  <Carousel
                    className="BlogCarousel"
                    responsive={responsive}
                    showDots={true}
                  >
                      {blogDataLocal?.map((item, index) => (
                    <div className="bannerNewDivBlog"  key={index}>
                        <div
                          className="bannerNewImageDivBlog"
                          style={{
                            backgroundImage: `url("${item.blogImageUrlList[0]}")`,
                            cursor:"pointer",
                          }}
                          onClick={() => {
                            openBlogDetails(item.blogObj.Name);
                          }}

                        >
                          <div className="centeredContentBlog">
                            <h2>{item.blogObj.Name}</h2>
                            <button 
                                onClick={() => {
                                  openBlogDetails(item.blogObj.Name); 
                                }}
                            >Read Blog..</button>
                          </div>
                        </div>
                    </div>
                      ))}
                  </Carousel>
                </div>

                  <section className="news" id="news">
                    <div className="titletext">
                      <h1>
                        Our <span>Blogs</span>
                      </h1>
                    </div>

                    <div className="containerPostCard">
                      {blogDataLocal?.map((item, index) => (
                        <div
                          className="PostCard"
                          key={index}
                          onClick={() => {
                            openBlogDetails(item.blogObj.Name);
                          }}
                        >
                          <div className="Postcard__header">
                            <img
                              src={item.blogImageUrlList[0]}
                              alt="card__image"
                              className="card__image"
                              width={600}
                              loading="eager"
                            />
                          </div>
                          <div className="Postcard__body">
                            <h4>{item.blogObj.Name}</h4>
                            <p className="contPostCardBody">
                              {item.blogObj.Short_Description__c.length > 150
                                ? `${item.blogObj.Short_Description__c.slice(
                                    0,
                                    150
                                  )}...`
                                : item.blogObj.Short_Description__c}
                              <span style={{ color: "#e89246" }}>
                                Read more
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                </>
              )}
            </>
          )}

          {showContent === false && (
            <>
              <div className="bannerNewDivBlog">
                {singleBlogData?.map((item, index) => (
                  <div
                    key={index}
                    className="bannerNewImageDivBlog"
                    style={{
                      backgroundImage: `url("${item.blogImageUrlList[0]}")`,
                    }}
                  >
                    <div className="centeredContentBlog">
                      <h2>{item.blogObj.Name}</h2>
                      <button onClick={openGetAQuoteModal}>Book Free Consultation</button>
                    </div>
                  </div>
                ))}
              </div>

              <section className="detaSecBlog">
                <div className="BlogDetailMainDiv">
                  <div className="BlogDiv1">
                    {singleBlogData[0]?.blogObj?.Blog_Contents__r?.records?.map(
                      (item, index) => (
                        <div key={index}>
                          <p>{item.Description__c}</p>
                          {singleBlogData[0]?.blogCntImageUrlList[item.Id] &&  ( 
                               <img className="blogCntImg" src={singleBlogData[0]?.blogCntImageUrlList[item.Id][0]}></img> 
                            )}
                        </div>
                      )
                    )}
                  </div>

                  <div className="BlogDiv2">
                    <form className="login floorModal blogForm">
                      <h2>Get In Touch</h2>
                      <input
                         onChange={handleInputChange}
                         value={getDetailsFormPage.full_Name}
                        id="full_Name"
                        name="full_Name"
                        autoComplete="off"
                        maxLength={100}
                        type="text"
                        required="true" 
                        placeholder="Full Name*"
                      />
                      {fnameWarning && (
                        <label style={{ color: "red", marginTop: "-5%" }}>
                          First Name Contains Atleast 3 Characters.
                        </label>
                      )}
                      {fnameDigitError && (
                        <label style={{ color: "red", marginTop: "-5%" }}>
                          Name Not Contains Digits
                        </label>
                      )}
                      <input
                        id="Company"
                        name="Company"
                        type="text"
                        required="true"
                        placeholder="Company"
                        autoComplete="off"
                         onChange={handleInputChange}
                         value={getDetailsFormPage.Company}
                      />
                      {companyWarning && (
                        <label style={{ color: "red", marginTop: "-5%" }}>
                          Company Name Contains Atleast 1 Character.
                        </label>
                      )}

                      <input
                        id="email"
                        name="email"
                        required="true"
                        placeholder="Email"
                        autoComplete="off"
                         onChange={handleInputChange}
                         value={getDetailsFormPage.email}
                        type="email"
                      />
                      {emailWarning && (
                        <label style={{ color: "red", marginTop: "-5%" }}>
                          Email Must Be Correct.
                        </label>
                      )}
                      <input
                        id="mobileNumber"
                        name="mobileNumber"
                        autoComplete="off"
                        required="true"
                         onChange={handleInputChange}
                         onKeyDown={validatePhoneNumber}
                         value={getDetailsFormPage.mobileNumber}
                        type="tel"
                        maxLength="10"
                        placeholder="Mobile Number"
                      />
                      {phoneWarning && (
                        <label style={{ color: "red", marginTop: "-5%" }}>
                          Phone No. Contains 10 Digits
                        </label>
                      )}
                      <select
                            onChange={handleSelectChange}
                        name="area"
                      >
                        <option value="Less than 2000 sq feet">
                          Less than 2000 sq feet
                        </option>
                        <option value="2000-5000 sq feet">
                          2000-5000 sq feet
                        </option>
                        <option value="5000+ sq feet">5000+ sq feet</option>
                      </select>
                      <button
                        className="buttonFormMainHome"
                         disabled={submitDisBtn} 
                         onClick={submitBtnClick}
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </section>
            </>
          )}

          <FloorFooter setshowOrHideProjectModal={setshowOrHideProjectModal} />
        </>
      )}
    </>
  );
}

export default Blog;
