import React from "react";
import "./NewContactUs.css";
export default function NewContactUs() {
  return (
    <div className="bodyDiv" id="ContactUs">
      <section className="contactSection">
        <div className="section-header">
          <div className="containerContactUs">
            <h2>Contact Us</h2>
            <p>
            Our team creates comfortable spaces for our clients. We’ve been designing your everyday life and work through great ideas.
            Besides great interior design, there are lots of reasons to choose SPACIFY CONCEPTS PRIVATE LIMITED.
            </p>
          </div>
        </div>
        <div className="containerContactUs">
          <div className="row1">
            <div className="contact-info">
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-home" />
                </div>
                <div className="contact-info-content">
                  <h4>Address</h4>
                  <p>
                    R8 139 & R8 140, 1st floor,
                    <br /> M3M 65th Avenue, Sector65, <br />
                    Gurugram, Haryana - 122101, India
                  </p>
                </div>
              </div>
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-phone" />
                </div>
                <div className="contact-info-content">
                  <h4>Phone</h4>
                  <a href="tel:9654788000">  
                    <p>+91 96547-88000</p>
                  </a>
                </div>
              </div>
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-envelope" />
                </div>
                <div className="contact-info-content">
                  <h4>Email</h4>
                  <a href="mailto:response@spacifyindia.com">
                    <p>response@spacifyindia.com</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="contact-form">
              <div className="map_bind">
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14532.543320348914!2d77.05075336372795!3d28.407857602873367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d226651e7ca9b%3A0x67b528c6b42beac0!2sM3m%2065th%20Avenue!5e0!3m2!1sen!2sin!4v1703481374470!5m2!1sen!2sin"
                  width="100%"
                  height={450}
                  style={{border:"6px solid white", borderRadius:"7px", boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"}}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
