import React, { useState } from "react";
import "./Faq.css";

export default function Faq() {
  const faqData = [
    {
      question: "1. What services does Spacify offer?",
      answer:
        "Spacify offers comprehensive interior design services for residential and commercial spaces, including concept development, space planning, and custom furniture design.",
    },
    {
      question: "2. How do I get started with Spacify's interior design services?",
      answer:
        "Getting started is easy! Simply contact us through our website or give us a call, and we'll schedule an initial consultation to discuss your needs and vision.",
    },
    {
      question: "3. What is the typical timeline for an interior design project?",
      answer:
        "Project timelines vary based on the scope and complexity. On average, residential projects take anywhere from 8 to 12 weeks, while commercial projects may require more time.",
    },
    {
      question: "4. How much does an interior design project with Spacify cost?",
      answer:
        "Project costs depend on factors such as project size, scope, and material choices. We provide customized quotes during the initial consultation based on your specific requirements.",
    },
    {
      question: "5. Can I use my existing furniture in the new design?",
      answer:
        "Absolutely! We can incorporate your existing furniture into the new design, ensuring a seamless blend of old and new elements that align with your style.",
    },
    {
      question: "6. Do I need to hire contractors separately for the construction phase?",
      answer:
        "No need! Spacify manages the entire process, including collaborating with trusted contractors. We ensure a coordinated effort for a smooth and efficient project.",
    },
    {
      question: "7. Can Spacify work with a specific design theme or style preference?",
      answer:
        "Yes, we love incorporating your preferences! Whether it's a specific theme, style, or color palette, we tailor the design to reflect your unique taste and vision.",
    },
    {
      question: "8. Is Spacify experienced in sustainable and eco-friendly design?",
      answer:
        "Absolutely! We are committed to sustainable design practices and can incorporate eco-friendly materials and energy-efficient solutions into your project.",
    },
    {
      question: "9. How involved will I be in the decision-making process?",
      answer:
        "Your involvement is crucial! We collaborate closely with you, seeking your input at every stage to ensure the final design aligns perfectly with your vision.",
    },
    {
      question: "10. Can Spacify handle large-scale commercial projects?",
      answer:
        "Certainly! Spacify has extensive experience in commercial design, handling projects of various scales, from office spaces to retail establishments.",
    },
  ];
  // Use state to track the active accordion item
  const [activeAccordionItem, setActiveAccordionItem] = useState(null);

  // Event handler for accordion item click
  const handleAccordionItemClick = (index) => {
    setActiveAccordionItem(index === activeAccordionItem ? null : index);
  };
  return (
    <section className="faq faq--default" style={{ backgroundColor: "#fff" }}>
      <h2 className="faq-title">FAQs About Home Interior Design</h2>
      <div className="accordion">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`accordion-item ${
              index === activeAccordionItem ? "active" : ""
            }`}
          >
            <div
              className="accordion-item-header"
              onClick={() => handleAccordionItemClick(index)}
            >
              {item.question}
            </div>
            <div
              className="accordion-item-body"
              style={{
                maxHeight: index === activeAccordionItem ? "1000px" : "0",
              }}
            >
              <div className="accordion-item-body-content">{item.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
