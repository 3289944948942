import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./BannerSection12.css";
export default function BannerSection12({ responseFlag }) {
  const [bannerData, setBannnerData] = useState([]);
  useEffect(() => {
    // console.log('ResponseFlag='+responseFlag);
    // console.log('BannerWrapLoist=',localStorage.getItem("bannerWrapList"));
    if (responseFlag || localStorage.getItem("bannerWrapList") != null) {
      let bannerData = JSON.parse(localStorage.getItem("bannerWrapList"));
      //  console.log('The BannerData=',bannerData);
      if (bannerData) {
        setBannnerData(bannerData);
        // console.log('BannerData=',bannerData);
      }
      setTimeout(() => {
        window.initJs();
      }, 1000);
    }
  }, [responseFlag]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const openGetAQuoteModal = () => {
    document.getElementById("getAQuoteButton").click();   
  };
  return (
    // <div className="FramerMotionCarouselBannner">
    //   <Carousel responsive={responsive} className="CarouselBanner" showDots={true}>      
    //   {bannerData.length > 0 &&
    //       bannerData.map((item) => (
    //         <img className="bannerImgHover"
    //           src={item.bannerImageUrlList[0]}
    //           style={{ width: "-webkit-fill-available" }}
    //           onClick={openGetAQuoteModal}
    //           // decoding="async"
    //           loading="eager"
    //         />
    //       ))}
    //   </Carousel>
    // </div>
    <div className="BanCarMainDiv">
    <Carousel responsive={responsive} showDots={true}>
       {bannerData.length > 0 &&
          bannerData.map((item, i) => (
            <div className="bannerNewDiv" key={i}>
            <div
              className="bannerNewImageDiv"
              style={{
                backgroundImage: `url("${item.bannerImageUrlList[0]}")`,       
              }}
            >
              <div className="centeredContent">  
              <h2>{item.bannerObj.Banner_Title__c}</h2>   
                {/* <h2>Home to beautiful Interiors</h2> */}
                <button onClick={openGetAQuoteModal}>Book Free Consultation</button>
              </div>
            </div>
          </div>
      ))}
    

      {/* <div className="bannerNewDiv">
        <div
          className="bannerNewImageDiv"
          style={{
          }}
        >
          <div className="centeredContent">
            <h2>Home to beautiful Interiors</h2>
            <button onClick={openGetAQuoteModal}>Book Free Consultation</button>
          </div>
        </div>
      </div>

      <div className="bannerNewDiv">
        <div
          className="bannerNewImageDiv"
          style={{
            backgroundImage: `url("https://plus.unsplash.com/premium_photo-1661882222947-215131d17cb8?q=80&w=1952&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
          }}
        >
          <div className="centeredContent">
            <h2>Home to beautiful Interiors</h2>
            <button onClick={openGetAQuoteModal}>Book Free Consultation</button>
          </div>
        </div>
      </div>  */}
    </Carousel>
    </div>
  );
}
