import React from "react";
import "./WhySpacify.css";
export default function WhySpacify() {
  return (
    <div
      className="why-homelane"
      style={{ backgroundColor: "rgb(255, 255, 255)" }}
    >
      <div className="all-partners">Why Spacify?</div>
      <section className="whySection">
        <div className="Marquee">
          <div className="Marquee-content">
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/50+ Quality Checks-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  50+ Quality Checks
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/45 Days Execution-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  45 Days Execution
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/5 Year Warranty-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  5 Year Warranty
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/Trusted Home Interior Brand-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  Trusted Home Interior Brand
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/50+ Interior Designers-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  50+ Interior Designers
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/80+ Complete Project-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  80+ Complete Project
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/End-to-End Management-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  End-to-End Management
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/In House Production-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  In House Production
                </p>
              </div>
            </div>
            {/* Second Set */}
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/50+ Quality Checks-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  50+ Quality Checks
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/45 Days Execution-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  45 Days Execution
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/5 Year Warranty-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  5 Year Warranty
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/Trusted Home Interior Brand-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  Trusted Home Interior Brand
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/50+ Interior Designers-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  50+ Interior Designers
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/80+ Complete Project-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  80+ Complete Project
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/End-to-End Management-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  End-to-End Management
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/In House Production-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  In House Production
                </p>
              </div>
            </div>
            {/* Third Set */}
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/50+ Quality Checks-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  50+ Quality Checks
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/45 Days Execution-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  45 Days Execution
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/5 Year Warranty-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  5 Year Warranty
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/Trusted Home Interior Brand-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  Trusted Home Interior Brand
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/50+ Interior Designers-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  50+ Interior Designers
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/80+ Complete Project-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  80+ Complete Project
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/End-to-End Management-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  End-to-End Management
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/In House Production-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  In House Production
                </p>
              </div>
            </div>
            {/* Fourth Set */} {/* Third Set */}
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/50+ Quality Checks-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  50+ Quality Checks
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/45 Days Execution-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  45 Days Execution
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/5 Year Warranty-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  5 Year Warranty
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/Trusted Home Interior Brand-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  Trusted Home Interior Brand
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/50+ Interior Designers-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  50+ Interior Designers
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/80+ Complete Project-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  80+ Complete Project
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/End-to-End Management-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  End-to-End Management
                </p>
              </div>
            </div>
            <div className="ImageDivMarquee">
              <div className="imgNameDivMar">
                <img
                  data-v-cdecefc6
                  loading="lazy"
                  src="assets/images/In House Production-01.png"
                  width={80}
                  height={80}
                  alt="75,000+ happy homes"
                />
                <p data-v-cdecefc6 className="text-center w-full">
                  In House Production
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
