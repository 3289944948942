import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import React, { useEffect } from "react";
import "./ClientJourney.css";
export default function ClientJourney() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  const openGetAQuoteModal = () =>{
     document.getElementById('getAQuoteButton').click();
  }
  const seeVideoClientJourney = () =>{ 
    var youtubeUrl = "https://www.youtube.com/@spacifyindia";
    window.open(youtubeUrl,'_blank');
 }
  return (
    <section id="about-boxes" className="about-boxes">
      <div className="clientJourneyHead">What we Do?</div>
      <div className="blogSecondHead">We offer comprehensive services that cater to a broad spectrum of needs and
          <br/>
          clients, ensuring satisfaction across diverse requirements.</div>
      <div className="container" >    
        <div className="row">
          <div
            className=" col-12 col-lg-4 col-md-4  col-sm-6 d-flex align-items-stretch"
            // data-aos="fade-up"
            // data-aos-delay={100}
          >
            <div className="card">
              <img
              src="assets/images/ClientJour1.jpg"  
                className="card-img-top"  
                alt="..."
              />
              {/* <div className="card-icon">
                  <i className="fas fa-handshake" style={{paddingTop:'10px'}}/>
                </div> */}
              <div className="card-body">
                <h5 className="card-title">
                  <a href>Budget - Premium - Luxury</a>
                  {/* <div className="NumberSpan">
                    <a href>01</a>
                  </div>
                  <div className="HeadingSpan">
                    {" "}
                    <a href>Understand What you need</a>
                  </div> */}
                </h5>
                <p className="card-text" style={{ textAlign: "justify" }}>
                Extensive architectural design services catering to all budgets, ensuring quality and innovative solutions for all clients
                </p>
              </div>
            </div>
          </div>
          <div
            className=" col-12 col-lg-4 col-md-4  col-sm-6 d-flex align-items-stretch"
            // data-aos="fade-up"
            // data-aos-delay={200}
          >
            <div className="card">
              <img
                 src="assets/images/clientJourney.webp"
                className="card-img-top"
                alt="..."
              />
              {/* <div className="card-icon">
                  <i className="fa-solid fa-house" style={{paddingTop:'10px'}}/>
                </div> */}
              <div className="card-body">
                <h5 className="card-title">
                  <a href>New Space - Renovation - Products</a>
                  {/* <div className="NumberSpan">
                    <a href>02</a>
                  </div>
                  <div className="HeadingSpan">
                    {" "}
                    <a href>Let's co-create your future home</a>
                  </div> */}
                </h5>
                <p className="card-text" style={{ textAlign: "justify" }}>
                End-to-end architectural solutions ensure a seamless and panoramic experience for our clients
                </p>
              </div>
            </div>
          </div>
          <div
            className=" col-12 col-lg-4 col-md-4 col-sm-6 d-flex align-items-stretch"
            // data-aos="fade-up"
            // data-aos-delay={300}
          >
            <div className="card">
              <img
                src="assets/images/clientJourney2.jpg"
                className="card-img-top"
                alt="..."
              />
              {/* <div className="card-icon">
                  <i className="fa-solid fa-user-check" style={{paddingTop:'10px'}}/> 
                </div> */}
              <div className="card-body">
                <h5 className="card-title">
                  <a href>Corporates - MSMEs - Professionals</a>
                  {/* <div className="NumberSpan">
                    <a href>03</a>
                  </div>
                  <div className="HeadingSpan">
                    {" "}
                    <a href>Cost optimisation and planning</a>
                  </div> */}
                </h5>
                <p className="card-text" style={{ textAlign: "justify" }}>
                Services extend across all sizes and domains, ensuring tailored solutions for every client 
                </p>
              </div>
            </div>
          </div>
          {/* <div
            className=" col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="card">
              <img
                src="https://newpropertyboard.com/wp-content/uploads/Property-handover-key.jpg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-icon">
                  <i className="fas fa-handshake" style={{paddingTop:'10px'}}/>
                </div>
              <div className="card-body">
                <h5 className="card-title">
                  <div className="NumberSpan">
                    <a href>04</a>
                  </div>
                  <div className="HeadingSpan">
                    {" "}
                    <a href>Site handover and warranty</a>
                  </div>
                </h5>
                <p className="card-text" style={{ textAlign: "justify" }}>
                  Once the work is completed, we hand over the keys to your new
                  home - a succesfull collaboration!
                </p>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div  style={{ textAlign: "center", display: "flex", justifyContent:"center" }}>
          <button className="clientJourneyBtn" onClick={openGetAQuoteModal} >
            <span >Let's Talk</span>&nbsp; &nbsp;
            <span>
              <i className="fa fa-angle-right"></i>
            </span>
          </button>
           &nbsp; &nbsp; &nbsp; &nbsp;  
          <button className="clientJourneyBtn"  onClick={seeVideoClientJourney}>
            <span>See the video</span>&nbsp; &nbsp;
            <span>
              <i className="fa fa-youtube-play"></i>         
            </span>
          </button>
        </div> */}
      </div>
    </section>
  );
}
