import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import "./Services.css";

function Services({ responseFlag }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [imageAllData, setImageAllData] = useState([]);  
  const openGetAQuoteModal = () => {
    setIsOpen(false);
    document.getElementById("getAQuoteButton").click();
  };
  // const [backgroundImage, setBackgroundImage] = useState('');

  // const handleImageHover = (imageSrc) => {
  //   setBackgroundImage(imageSrc);
  // };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 464);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // console.log('ImageData=',imageAllData);
    let url = window.location.href;
    if(url.includes('Servicename')){
      //  console.log('If Conds')
       let urlString = window.location.href.split('?')[1];  
       urlString = urlString.split('=')[1];
       urlString = decodeURIComponent(urlString);   
      //  console.log('imageAllData=',imageAllData);
       const filterImagesData = imageAllData.filter(
        (item) => item.title === urlString
      );
    // console.log('filterImagesData='+filterImagesData.length);
      if(filterImagesData.length!==0){
        openModal(urlString);        
      }
       
    }  
  }, [imageAllData]);
  
  
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    // console.log('HelloServices');
    const valueModal = JSON.parse(localStorage.getItem("servicesModalValue"));
    if (valueModal) {
        closeModal();              
    }

  };

  useEffect(() => {
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const flickityOptions = {
    pageDots: false,
  };

  const handleToggleClick = (event) => {
    event.preventDefault();
    const $ctx = event.currentTarget;
    const customToggle = $ctx.getAttribute("data-custom-toggle");
    document.querySelector(customToggle).classList.toggle("active");
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };

  const responsiveForModalCraousel = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [btnCollection, setBtnCollection] = useState([]);
  const [prevBtn, setPrevBtn] = useState("");
  
  const [modalImage, setmodalImage] = useState([]);
  const [relatedImage, setrelatedImage] = useState([]);
  const [maxImageWidths, setMaxImageWidths] = useState([]);
  const [selectedButton, setSelectedButton] = useState({
    title: "",
    image: "",
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    zIndex: "999",
  };

  let subtitle;



  const openModal = (itemData) => {  
    localStorage.setItem("servicesModalValue", true);    
  
  let url = window.location.href;
  if(!url.includes('Servicename')){
    url += '?Servicename=' + encodeURIComponent(itemData); // Encode itemData to handle special characters  
    window.history.pushState({ path: url }, '', url);  
  }
 
    setSelectedIndex(0);
    const filterImagesData = imageAllData.filter(
      (item) => item.title === itemData
    );
    var tempLi = [...filterImagesData];
    // // console.log("tempLi", tempLi.length);
    // tempLi[0] = filterImagesData[index];
    // tempLi[index] = filterImagesData[0];

    const newArr = tempLi.slice(1);
    setmodalImage(tempLi);
    setrelatedImage(newArr);

    const imageWidths = tempLi.map(() => 0);
    tempLi.forEach((item, index) => {
      const img = new Image();
      img.src = item.image;
      img.onload = () => {
        // console.log('Helloo')
        const width = img.width;
        imageWidths[index] = width;
        setMaxImageWidths([...imageWidths]);
      };
    });

    // console.log("tempLi", tempLi[0].title);
    setIsOpen(true);
    document.getElementById("home").style.pointerEvents = "none";
    document.body.style.overflow = "hidden";
  };

  function afterOpenModal() {
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    localStorage.setItem("servicesModalValue", false);
    setIsOpen(false);
    document.getElementById("home").style.pointerEvents = "auto";
    document.body.style.overflow = "auto";
    window.history.pushState(null, '', '/');
  }

  useEffect(() => {

    if (localStorage.getItem("interiorWrapList") !== null) {
      let localStorageData = JSON.parse(
        localStorage.getItem("interiorWrapList")
      );
      var newImageDataList = [];
      if (localStorageData && localStorageData.length > 0) {
        localStorageData.forEach((item) => {
          let eachImageData = {
            iconid: item.interiorObj.Name,
            title: item.interiorObj.Name,
            imageList: item.imageUrlList,
            description: item.interiorObj.Description__c,
          };
          newImageDataList.push(eachImageData);
        });
        setData(newImageDataList);
      } else {
        console.error(
          "Error: Unable to retrieve valid data from localStorage."
        );
      }

      var btnList = [];
      var imageList = [];
      if (localStorageData.length > 0) {
        for (let i = 0; i < localStorageData.length; i++) {
          var deta = localStorageData[i];
          let eachBtn = {
            iconid: deta.interiorObj.Name,
            title: deta.interiorObj.Name,
            image: deta.interiorObj.Interior_Logo_Url__c,
            type: deta.interiorObj.Name,
          };
          btnList.push(eachBtn);
          var eachBtnImages = [];
          for (let j = 0; j < deta.imageUrlList.length; j++) {
            let eachImage = {
              iconid: deta.interiorObj.Name + j,
              title: deta.interiorObj.Name,
              image: deta.imageUrlList[j],
              type: deta.interiorObj.Name,
              icon: deta.interiorObj.Interior_Logo_Url__c,
              description: deta.interiorObj.Description__c,
            };
            imageList.push(eachImage);
            eachBtnImages.push(eachImage);
          }
          if (i === 0) {
            //  setData(eachBtnImages);
          }
        }
      }
      setmodalImage(imageList);
      setBtnCollection(btnList);
      setImageAllData(imageList);

      setTimeout(() => {
        // document.getElementById(localStorageData[0].interiorObj.Name).style =
        // "color: black; background: #e89246; font-weight: 700;";
        setPrevBtn(localStorageData[0].interiorObj.Name);
        setSelectedButton((prevSelectedButton) => ({
          ...prevSelectedButton,
          image: localStorageData[0].interiorObj.Interior_Logo_Url__c,
          title: localStorageData[0].interiorObj.Name,
        }));
      }, 2000);
    }
  }, [responseFlag]);

  useEffect(()=>{
    localStorage.setItem("servicesModalValue", false); 
  },[]);

  const galleryFilter = (itemData) => {
    setLoading(true);
    document.getElementById("ImageGallery").style.visibility = "hidden";
    setTimeout(() => {
      setLoading(false);
      document.getElementById("ImageGallery").style.visibility = "visible";
    }, 1000);
    for (let i = 0; i < btnCollection.length; i++) {
      if (btnCollection[i].iconid === itemData) {
        document.getElementById(itemData).style =
          "color: black; background: #e89246; font-weight: 700;";
      } else {
        document.getElementById(btnCollection[i].iconid).style = "none";
      }
    }

    setTimeout(() => {
      const filterData = imageAllData.filter((item) => item.title === itemData);
      setData(filterData);

      setSelectedButton((prevSelectedButton) => ({
        ...prevSelectedButton,
        image: filterData[0].icon,
        title: filterData[0].title,
      }));
    }, 500);

    setPrevBtn(itemData);
  };

  const handleSelectedService = (itemData) => {
    setLoading(true);
    document.getElementById("ImageGallery").style.visibility = "hidden";
    setTimeout(() => {
      setLoading(false);
      document.getElementById("ImageGallery").style.visibility = "visible";
    }, 2000);
    const filterData = imageAllData.filter((item) => item.title === itemData);
    setData(filterData);

    const buttonData = btnCollection.filter((item) => item.title === itemData);
    setSelectedButton((prevSelectedButton) => ({
      ...prevSelectedButton,
      image: buttonData[0].image,
      title: buttonData[0].title,
    }));

    document.getElementById(itemData).style =
      "color: black; background: #ffdc25; font-weight: 700;";
    setPrevBtn(itemData);
    document.getElementById(prevBtn).style = "none";

    setTimeout(() => {
      document.getElementById("owlDropDown").click();
    }, 200);
  };

  const [widthCards, setwidthCards] = useState();

  // useEffect(() => {
  //     console.log('Hello');
  //   setTimeout(()=>{
  //     let elements = document.querySelectorAll(".serviceCrauselHome .react-multi-carousel-item" );
  //     elements.forEach((el)=>{
  //       console.log('current=',el.style.width);
  //       var widthNew = parseInt(el.style.width) - 2;
  //       setwidthCards(widthNew);
  //       el.style.width = widthNew + 'px';
  //     });
  //   },100);
  // }, [])

  const handleCarouselBeforeChange = (oldIndex, newIndex) => {
    // console.log("Index Before", oldIndex);
    setSelectedIndex(oldIndex);
  };

  const handleCarouselAfterChange = (index) => {
    // console.log("Index after=", index);
  };

  // const newCarouselAfterChange = (index) => {
  //    console.log('oldIndex=',index);
  //   let ulelements = document.querySelectorAll(
  //     ".serviceCrauselHome ul.react-multi-carousel-track"
  //   );
  //   ulelements.forEach((ul1,index)=>{
  //     var newIndex1 = index +1;
  //     console.log('new Index=',newIndex1)
  //     console.log('widthCards=',widthCards);
  //     var parseVal = parseInt(widthCards) *newIndex1;
  //     console.log('parseVal=',parseVal);
  //     // ul1.style.cssText += 'overflow: auto !important;';
  //     // ul1.style.cssText += `transform: translate3d(-${parseVal}px, 0px, 0px) !important;`;
  //     // ul1.style.transform = `translate3d(-${parseVal}px, 0px, 0px) !important`;
  //     // ul1.style.transform = `translate3d(-${parseVal}px, 0px, 0px) !important`;
  //     console.log('ul1.style.transform',ul1.style.transform)
  //   });
  // };

  const firstModalImage = modalImage[0];

  const RelatedImageClick = (clickedIndex, clickedImage) => {
    const clickedImageIndex = modalImage.findIndex(
      (item) => item.image === clickedImage
    );
    // console.log('Selected=',selectedIndex);
    // console.log('clickedImageIndex=',clickedImageIndex);

    if (selectedIndex !== clickedImageIndex) {
      const updatedModalImage = [...modalImage];
      updatedModalImage[clickedImageIndex] = modalImage[selectedIndex];
      updatedModalImage[selectedIndex] = modalImage[clickedImageIndex];
      setmodalImage(updatedModalImage);
    }
  };

  // const [maxImageWidths, setMaxImageWidths] = useState([]);

  // useEffect(() => {
  //   const imageWidths = modalImage.map(() => 0);
  //   modalImage.forEach((item, index) => {
  //     const img = new Image();
  //     img.src = item.image;
  //     img.onload = () => {
  //       const width = img.width;
  //       imageWidths[index] = width;
  //       setMaxImageWidths([...imageWidths]);
  //     };
  //   });
  // }, [modalImage]);

  return (
    <>
      {/* for Sample Modal Open  */}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        // className="123Hello"
      >
        <div className="innerDivModal lg:overflow-hidden">
          <div
            onClick={closeModal}
            style={{
              width: "4vh",
              height: "auto",
              marginBottom: "10px",
              color: "black",
              marginRight: "16px",
              display: "flex",
              justifyContent: "Center",
              alignItems: "center",
              cursor: "pointer",
              float: "right",
              position: "fixed",
              right: "9%",
            }}
            className="closeBtnServiceModal"
          >
            <span style={{ fontSize: "24px" }}>✖</span>
          </div>
          <div className="mainDivService bg-transparent">
            <div className="FramerMotionCarouselModal">
              {/* {console.log('maxWidth Crausel='+currentImageWidth)} */}
              {/* <Carousel
                responsive={responsiveForModalCraousel}
                className="CarouselPropModal"
                beforeChange={handleCarouselBeforeChange}
                afterChange={handleCarouselAfterChange}
              >
                {modalImage.length > 0 &&
                  modalImage.map((item, index) => (
                    <img
                      key={index}
                      src={item.image}
                      alt={`Image ${index}`}
                      className="carousel-image"
                      // loading="lazy"
                      // decoding="async"
                      style={{ maxWidth: maxImageWidths[index] }}
                      loading="eager"
                    />
                  ))}
              </Carousel>   */}
           
              <PhotoProvider>
                <Carousel
                  responsive={responsiveForModalCraousel}
                  className="CarouselPropModal"
                  beforeChange={handleCarouselBeforeChange}
                  afterChange={handleCarouselAfterChange}
                >
                  {modalImage.length > 0 &&
                    modalImage.map((item, index) => (
                      <PhotoView key={index} src={item.image}>
                        <img
                          src={item.image}
                          alt={`Image ${index}`}
                          className="carousel-image"
                          style={{ maxWidth: maxImageWidths[index] }}
                          loading="eager"
                        />
                      </PhotoView>
                    ))}
                </Carousel>
              </PhotoProvider>

            </div>

            <div className="detailDiv bg-white">
              <h2 className="HeadingName">
                {firstModalImage && firstModalImage.title} Services{" "}
              </h2>
              <p className="description">
                {firstModalImage && firstModalImage.description}
                {/* Metallic textures abound in this simple small kitchen design
                that prizes clean lines and colour contrasts above all else. The
                brushed steel lower cabinets and pantry meld with the silver
                paint on the walls and ceiling */}
              </p>
              <br />
              <button className="bookButton" onClick={openGetAQuoteModal}>
                Book A Free Designer
              </button>
              <br />
              <br />
              <h3 className="ShareDesign">Share this Design</h3>
              <div class="social-links">
                <a
                  href="https://www.facebook.com/spacifyindia"
                  target="_blank"
                  className="FacebookIcon iconsModal"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://twitter.com/spacifyindia"
                  target="_blank"
                  className="iconsModal"
                >
                  <i class="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/spacifyindia/"
                  target="_blank"
                  className="iconsModal"
                >
                  <i class="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/spacify-india-5394892a8/"
                  target="_blank"
                  className="iconsModal"
                >
                  <i class="fa fa-linkedin"></i>
                </a>
                <a
                  href="https://www.youtube.com/@spacifyindia"
                  target="_blank"
                  className="iconsModal"
                >
                  <i class="fa fa-youtube-play"></i>
                </a>
                {/* <a href="#">
                  <i class="fab fa-whatsapp"></i>  
                </a> */}
              </div>
              {/*Grid System Start*/}
              <br />
              <h3 className="ShareDesign" style={{ marginBottom: "10px" }}>
                Related Images
              </h3>
              <div className="row">
                {relatedImage.length > 0 &&
                  relatedImage.map((item, index) => (
                    <div
                      className="col-lg-4 col-md-2 col-sm-4 col-6 "
                      key={index}
                    >
                      <img
                        key={index}
                        src={item.image}
                        className="img-thumbnail"
                        decoding="async"
                        onClick={() => {
                          RelatedImageClick(index, item.image);
                        }}
                      />
                    </div>
                  ))}
              </div>
              {/*Grid System End */}
            </div>
          </div>
        </div>
      </Modal>

      {/* for Sample Modal Open  */}
      <section className="ServiceSectionPort" id="ourServices">
        <div className="ServiceHead">
          <div className="ServiceTopHeading">
            Our Services
            {/* <p className="PortNewSecondHead">
              Latest dream home interiors delivered the hassle-free way
            </p> */}
          </div>
        </div>
        <div
          className="parent"
          style={{ paddingLeft: "80px", paddingRight: "80px", margin: "auto" }}
        >
          <Carousel
            // onChange={handleOnChange}
            // afterChange={newCarouselAfterChange}
            // beforeChange={newCarouselBeforeChange}
            //  additionalTransfrom={isMobile ? -(100 / 4) : 0} // Apply additional transform for mobile screens
            responsive={responsive}
            showDots={true}
            className="serviceCrauselHome"
          >
            {data.length > 0 &&
              data.map((item, idx) => (
                <div
                  className="slider12 propDivHoverSha serviceDivHover"
                  style={{ cursor: "pointer" }}
                  key={idx}
                  // onClick={() => {
                  //   showProperty(
                  //     item.portfolioObj.Name,
                  //     item.portfolioImageUrlList[0],
                  //     idx
                  //   );
                  // }}
                >
                  <article
                    className="cardProp"
                    onClick={() => {
                      openModal(item.title);
                    }}
                  >
                    <div className="imgDivPropCard">
                      <img src={item.imageList[0]} />
                    </div>

                    <div className="textCardProp">
                      <div className="textCardProp1">
                        <h4>{item.title}</h4>
                      </div>
                      <div className="textCardPropIcon">
                        <span className="textCardIconSpan">
                          <i class="fas fa-angle-right"></i>
                        </span>
                      </div>
                    </div>
                  </article>
                </div>
              ))}
          </Carousel>
        </div>
      </section>
    </>
  );
}

export default Services;
