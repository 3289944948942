import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACCESS_TOKEN_API } from "../shared/constants";
// import GetaQuote from "./GetaQuote";
import "./BlogComp.css";
import ClientJourney from "./ClientJourney";
import "./Home2.css";
// import ServicesNew from "./ServicesNew";

const OfficeSpaces = () => {  
  const [fnameWarning, setFnameWarning] = useState(false);
  const [fnameDigitError, setFnameDigitError] = useState(false);
  const [phoneWarning, setPhoneWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);
  const [companyWarning, setCompanyWarning] = useState(false);
  const [submitDisBtn, setSubmitDisBtn] = useState(false);

  const [getDetailsFormPage, setgetDetailsFormPage] = useState({
    full_Name: "",
    Company: "",
    email: "",
    mobileNumber: "",
    area: "",
  });

  const validatePhoneNumber = (event) => {
    var keycode = event.which;
    if (
      !(
        (event.shiftKey === false &&
          (keycode === 46 ||
            keycode === 8 ||
            keycode === 37 ||
            keycode === 39 ||
            (keycode >= 48 && keycode <= 57))) ||
        (keycode >= 96 && keycode <= 105) ||
        keycode === 9
      )
    ) {
      event.preventDefault();
    }
  };


  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setgetDetailsFormPage({ ...getDetailsFormPage, [name]: value });

    if ("full_Name" === event.target.name) {
      if (event.target.value.length >=2) {
        setFnameWarning(false);
      } else {
        setFnameWarning(true);
      }

      const re = /^([^0-9]*)$/;
      if (!event.target.value.match(re) && event.target.value.length > 2) {
        setFnameDigitError(true);
      } else {
        setFnameDigitError(false);
      }
    }

    
    if ("Company" === event.target.name) {
      if (event.target.value.length >=1) {
        setCompanyWarning(false);
      } else {
        setCompanyWarning(true);    
      }
    }



    if ("mobileNumber" === event.target.name) {
      if (value.length === 10) {
        setPhoneWarning(false);
      } else {
        setPhoneWarning(true);
      }
    }
    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if ("email" === event.target.name) {
      if (event.target.value.match(mailformat)) {
        setEmailWarning(false);
      } else {
        setEmailWarning(true);
      }
    }
  };

  const handleSelectChange = (event) => {
       const name = event.target.name;
       const value = event.target.value;
       setgetDetailsFormPage({ ...getDetailsFormPage, [name]: value });
  };

  const submitBtnClick = () => {
    if (getDetailsFormPage.full_Name.length >=2) {
      setFnameWarning(false);
    } else {
      setFnameWarning(true);
    }

    const re = /^([^0-9]*)$/;
    // const re = /^[0-9-+()]*$/;
    if (!getDetailsFormPage.full_Name.match(re) && getDetailsFormPage.full_Name.length > 2) {
      setFnameDigitError(true);
    } else {
      setFnameDigitError(false);
    }

    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (getDetailsFormPage.email.match(mailformat)) {
      setEmailWarning(false);
    } else {
      setEmailWarning(true);
    }

    if (getDetailsFormPage.mobileNumber.length === 10) {
      setPhoneWarning(false);
    } else {
      setPhoneWarning(true);
    }

    if (getDetailsFormPage.Company.length >=1) {
      setCompanyWarning(false);
    } else {
      setCompanyWarning(true);
    }

    if (getDetailsFormPage.full_Name.length < 2) {
      // console.log('Hello If Name');
      setSubmitDisBtn(false);
      //  return;
    }
    if (getDetailsFormPage.mobileNumber.length < 10) {
      // console.log('Hello If Phone');
      setSubmitDisBtn(false);
      //  return;
    }
    if (getDetailsFormPage.Company.length <1) {  
      // console.log('Hello If City');
      setSubmitDisBtn(false);
      //  return;
    }
    if (getDetailsFormPage.email.match(mailformat)) {
      // console.log('Hello If PropertyType');
      setSubmitDisBtn(false);
    }

    if (
      getDetailsFormPage.full_Name.length >= 2 &&
      getDetailsFormPage.mobileNumber.length >= 10 &&
      getDetailsFormPage.Company.length > 0 &&
      getDetailsFormPage.email.match(mailformat)
    ){
       
      // console.log('Hello Else');
      setSubmitDisBtn(true);
      fetch(ACCESS_TOKEN_API, { method: "POST" })
        .then((response) => response.json())
        .then((result) => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${result.access_token}`);
          var raw = JSON.stringify({
            getAQuoteNewEnqiry: "sdfdsfdsf",
            Name: getDetailsFormPage.full_Name,
            phone: getDetailsFormPage.mobileNumber,
            company: getDetailsFormPage.Company,
            email: getDetailsFormPage.email,  
            area: getDetailsFormPage.area,
          });
          // console.log("The Console Raw=" + raw);

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
          fetch(
            `${result.instance_url}/services/apexrest/Interior_App_Api`,
            requestOptions
          ).then(async (response) => {
            let result = await response.json();
            // console.log('result='+result);
            if (result === "Successfully Inserted") {
              getDetailsFormPage.full_Name = "";  
              getDetailsFormPage.Company = "";  
              getDetailsFormPage.mobileNumber = "";
              getDetailsFormPage.email = "";

              // console.log('getDetailsFormPage',getDetailsFormPage);

              toast.success("Request Submitted Successfully !", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
              });
              setSubmitDisBtn(false);
            } else if (result === "Failed GetAQuote") {
              getDetailsFormPage.full_Name = "";
              getDetailsFormPage.Company = "";
              getDetailsFormPage.mobileNumber = "";
              getDetailsFormPage.email = "";
              toast.error("Something Went Wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
              });
              setSubmitDisBtn(false);
            }
          });
        })
        .catch((error) => {
          // console.log('result Failed');
          getDetailsFormPage.full_Name = "";
          getDetailsFormPage.Company = "";
          getDetailsFormPage.mobileNumber = "";
          getDetailsFormPage.email = "";
          toast.error("Something Went Wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
          setSubmitDisBtn(false);  
        });
    }
  };


  useEffect(() => {
    getDetailsFormPage.area="Less than 2000 sq feet";
    // For Http Link Change To Https
    let cnt = window.location.href.search("https");
    //  console.log('cnt',cnt);
    //  if(cnt<0){
    //  window.location.replace('https://spacifyindia.com/')
    //  }
    // For Http Link Change To Https
  }, []);
  var privacy_policy =
    process.env.PUBLIC_URL +
    "assets/privacy_policy/Privacy Policy_Spacify India.pdf";
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        rtl={false}
        draggable
        theme="light"
      />
      {/* Navbar Section */}
      <nav
        className="navbar navbar-expand-md navbar-dark "
        style={{ marginTop: "-80px", borderBottom: "1px solid black" }}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="assets/images/LogoImage.png" alt="" />
          </a>
        </div>
      </nav>
      {/* Navbar Section */}
      {/* mainHome */}
      <section className="mainHomeSection" 
     style={{
      backgroundImage: 'url("assets/images/enquiryLandingPage.jpg")'
    }}
      >
        <div className="MainHomeDiv">
          <div className="MainHomeDivInside1">
            <div className="divBlockHome">
              <h1>
                <span style={{ color: "#e89246" }}>
                  {" "}
                  Transform your workspace
                </span>
                <br />
              with technology-driven Interior Solutions
              </h1>
              <p>
                With in-house built technology , we specialise in designing
                customised solutions that align with your brand identity and
                business objectives.
              </p>
            </div>
          </div>
          <div className="MainHomeDivInside2">
            <form className="login">
              <h2>Get In Touch</h2>
              <input
                onChange={handleInputChange}
                value={getDetailsFormPage.full_Name}
                id="full_Name"
                name="full_Name"
                autoComplete="off"
                maxLength={100}
                type="text"
                required="true"
                placeholder="Full Name*"
              />
                {fnameWarning && (
                  <label style={{ color: "red", marginTop: "-5%" }}>
                    First Name Contains Atleast 3 Characters.
                  </label>
                )}
                {fnameDigitError && (
                  <label style={{ color: "red", marginTop: "-5%" }}>
                    Name Not Contains Digits
                  </label>
                )}
              <input
                id="Company"
                name="Company"
                type="text"
                required="true"
                placeholder="Company"
                autoComplete="off"
                onChange={handleInputChange}
                value={getDetailsFormPage.Company}
              />
               {companyWarning && (
                  <label style={{ color: "red", marginTop: "-5%" }}>
                   Company Name Contains Atleast 1 Character.
                  </label>
                )}

              <input
                id="email"
                name="email"
                required="true"
                placeholder="Email"
                autoComplete="off"
                onChange={handleInputChange}
                value={getDetailsFormPage.email}
                type="email"
              />
               {emailWarning && (
                  <label style={{ color: "red", marginTop: "-5%" }}>
                    Email Must Be Correct.
                  </label>
                )}
              <input
                id="mobileNumber"
                name="mobileNumber"
                autoComplete="off"
                required="true"
                onChange={handleInputChange}
                onKeyDown={validatePhoneNumber}
                value={getDetailsFormPage.mobileNumber}
                type="tel"
                maxLength="10"
                placeholder="Mobile Number"
              />
                 {phoneWarning && (
                  <label style={{ color: "red",marginTop: "-5%"}}>
                    Phone No. Contains 10 Digits
                  </label>
                )}
              <select onChange={handleSelectChange} name="area">  
                <option value="Less than 2000 sq feet">
                  Less than 2000 sq feet
                </option>
                <option value="2000-5000 sq feet">2000-5000 sq feet</option>
                <option value="5000+ sq feet">5000+ sq feet</option>
              </select>
              <button className="buttonFormMainHome"  
              disabled={submitDisBtn}
             onClick={submitBtnClick}
              >Submit</button>
            </form>
          </div>
        </div>
      </section>
      {/* mainHome */}

      {/* LandingComp */}
      <section
        id="about-boxes"
        className="about-boxesLanding"
        style={{
          backgroundColor: "#fff",
          paddingLeft: "20vh",
          paddingRight: "20vh",
          paddingTop: "4rem",
          paddingBottom: "4rem",
        }}
      >
        <div className="LandingCmpHead">
          <div className="LandingCmpHeadDiv1">
            <div></div>
          </div>
          <div className="LandingCmpHeadDiv2">
            <h2>
              Experience the combined power of
              <br />
              <span style={{ color: "#e89246" }}> Technology and Design</span>
            </h2>
          </div>
        </div>
        <div className="blogSecondHead">
        Transform your workspace with SpacifyIndia's avant-garde interior designs, blending innovation and technology for a modern
         and inspiring environment. Infuse creativity and ergonomic principles to enhance productivity and boost employee satisfaction. 
        Collaborate with us to energize your space and elevate the essence of your professional environment.
        </div>
        <div className="row">
          <div
            className=" col-12 col-lg-4 col-md-4  col-sm-6 d-flex align-items-stretch columnLanding"
            // data-aos="fade-up"
            // data-aos-delay={100}
          >
            <div className="cardLanding">
              <div className="imgDivLanding">
                <img src="assets/images/WhatweDo.svg" ></img>
              </div>
              <div className="imgDivH2">
                <h2>Cutting edge technology</h2>
              </div>
            </div>
          </div>
          <div
            className=" col-12 col-lg-4 col-md-4  col-sm-6 d-flex align-items-stretch columnLanding"
            // data-aos="fade-up"
            // data-aos-delay={200}
          >
            <div className="cardLanding">
              <div className="imgDivLanding">
                <img src="assets/images/WhatweDo2.svg" ></img>
              </div>
              <div className="imgDivH2">
                <h2>Global design community</h2>
              </div>
            </div>
          </div>
          <div
            className=" col-12 col-lg-4 col-md-4 col-sm-6 d-flex align-items-stretch columnLanding"
            // data-aos="fade-up"
            // data-aos-delay={300}
          >
            <div className="cardLanding">
              <div className="imgDivLanding">
                <img src="assets/images/WhatweDo3.svg"></img>
              </div>
              <div className="imgDivH2">
                <h2>Extensive execution ecosystem</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* LandingComp */}

      {/*What we do */}
      <ClientJourney />
      {/*What we do */}

      {/* Projects */}
      <section
        id="about-boxes"
        className="about-boxes"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="clientJourneyHead">Projects</div>
        <div className="blogSecondHead">
          Transforming spaces and delivering projects with Excellence Across
          <br />
          Locations and Clients
        </div>
        <div className="container">
          <div className="row rowBlogsProject">
            <div
              className=" col-12 col-lg-3 col-md-4  col-sm-6 d-flex align-items-stretch colBlogsProject"
              // data-aos="fade-up"
              // data-aos-delay={100}
            >
              <article className="cardBlog">
                <div className="imgDivBlogs">
                  <img src="assets/images/Project1Home2.jpeg" />
                </div>

                <div className="text">
                  <div>
                    <i className="fa-thin fa-location-dot"></i>
                  </div>
                  &nbsp; &nbsp;
                  <div>
                    {" "}
                    <p style={{ margin: "0px" }}>Delhi</p>
                  </div>
                </div>
              </article>
            </div>
            <div
              className=" col-12 col-lg-3 col-md-4  col-sm-6 d-flex align-items-stretch colBlogsProject"
              // data-aos="fade-up"
              // data-aos-delay={200}
            >
              <article className="cardBlog">
                <div className="imgDivBlogs">
                  <img src="assets/images/Project2Home2.png" />
                </div>

                <div className="text">
                  <div>
                    <i className="fa-thin fa-location-dot"></i>
                  </div>
                  &nbsp; &nbsp;
                  <div>
                    {" "}
                    <p style={{ margin: "0px" }}>Gurugram</p>
                  </div>
                </div>
              </article>
            </div>
            <div
              className=" col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch colBlogsProject"
              // data-aos="fade-up"
              // data-aos-delay={300}
            >
              <article className="cardBlog">
                <div className="imgDivBlogs">
                  <img src="assets/images/Project3Home2.jpg" />
                </div>

                <div className="text">
                  <div>
                    <i className="fa-thin fa-location-dot"></i>
                  </div>
                  &nbsp; &nbsp;
                  <div>
                    {" "}
                    <p style={{ margin: "0px" }}>Noida</p>
                  </div>
                </div>
              </article>
            </div>
            <div
              className=" col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch colBlogsProject"
              // data-aos="fade-up"
              // data-aos-delay={300}
            >
              <article className="cardBlog">
                <div className="imgDivBlogs">
                  <img src="assets/images/Project4Home2.jpg" />
                </div>

                <div className="text">
                  <div>
                    <i className="fa-thin fa-location-dot"></i>
                  </div>
                  &nbsp; &nbsp;
                  <div>
                    {" "}
                    <p style={{ margin: "0px" }}>Faridabad</p>
                  </div>
                </div>
              </article>
            </div>

            <div
              className=" col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch colBlogsProject"
              // data-aos="fade-up"
              // data-aos-delay={300}
            >
              <article className="cardBlog">
                <div className="imgDivBlogs">
                  <img src="assets/images/office4.jpg"/>
                </div>

                <div className="text">
                  <div>
                    <i className="fa-thin fa-location-dot"></i>
                  </div>
                  &nbsp; &nbsp;
                  <div>
                    {" "}
                    <p style={{ margin: "0px" }}>Ghaziabad</p>
                  </div>
                </div>
              </article>
            </div>

            <div
              className=" col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch colBlogsProject"
              // data-aos="fade-up"
              // data-aos-delay={300}
            >
              <article className="cardBlog">
                <div className="imgDivBlogs">
                  <img src="assets/images/office1.jpg"/>
                </div>

                <div className="text">
                  <div>
                    <i className="fa-thin fa-location-dot"></i>
                  </div>
                  &nbsp; &nbsp;
                  <div>
                    {" "}
                    <p style={{ margin: "0px" }}>Faridabad</p>
                  </div>
                </div>
              </article>
            </div>


            <div
              className=" col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch colBlogsProject"
              // data-aos="fade-up"
              // data-aos-delay={300}
            >
              <article className="cardBlog">
                <div className="imgDivBlogs">
                  <img src="assets/images/office2.jpg"/>
                </div>

                <div className="text">
                  <div>
                    <i className="fa-thin fa-location-dot"></i>
                  </div>
                  &nbsp; &nbsp;
                  <div>
                    {" "}
                    <p style={{ margin: "0px" }}>Delhi</p>
                  </div>
                </div>
              </article>
            </div>

            <div
              className=" col-12 col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch colBlogsProject"
              // data-aos="fade-up"
              // data-aos-delay={300}
            >
              <article className="cardBlog">
                <div className="imgDivBlogs">
                  <img src="assets/images/office3.jpg"/>
                </div>

                <div className="text">
                  <div>
                    <i className="fa-thin fa-location-dot"></i>
                  </div>
                  &nbsp; &nbsp;
                  <div>
                    {" "}
                    <p style={{ margin: "0px" }}>Gurugram</p>
                  </div>
                </div>
              </article>
            </div>


          </div>
        </div>
      </section>
      {/* Projects */}

      {/*5 Steps Proces */}
   
      {/* 5 Steps Proces*/}

      {/* ======= About Section ======= */}
      <section className="about" id="about" style={{ padding: "20px" }}>
        <div
          className="container"
          //  data-aos="fade-up"
        >
          <div style={{ paddingTop: "20px" }}>
            <h3
              style={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: "24px",
                color: "#263d4d",
              }}
            >
              About Us
            </h3>
          </div>
          <br />
          <div className="row">
            <div
              className="col-lg-6 video-box align-self-baseline"
              // data-aos="zoom-in"
              // data-aos-delay={50}
            >
              <img
                src="assets/images/aboutUsImg.webp"
                className="img-fluid"
                alt=""
              />
              {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="glightbox play-btn mb-4"></a> */}
            </div>
            <div className="col-lg-6 pt-3 pt-lg-0 content">
              <p>
              Spacify is a creative design practice whose work seamlessly weaves art and style in interior spaces, from 
              large space ideas to the smallest of furniture details. 
              We believe in creating sensory experience for people through timeless design.
              </p>
              <p>
              We take complete pride in designing and furnishing your complete home or a section or a specialized room in our signature, 
              luxurious, bespoke and timeless appeal. We customize and personalize your abode into an end result that is plush, and elegant
               and sophisticated. Our designs exude luxury and finesse.
At Spacify, we believe that every space has the potential to become extraordinary. Whether it's a residential home, office, or commercial 
              </p>
              <p>
              We understand that your space is a reflection of your identity, and we take pride in translating your ideas into stunning reality. 
              Our approach is rooted in collaboration and attention to detail
              </p>
              <p>
                We bring expertise in various areas of technology to deliver
                comprehensive solutions to our clients. Our core areas of
                expertise include Software Development, Cloud Computing,
                Cybersecurity, Data Analytics, Artificial Intelligence and
                Machine Learning.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End About Section */}

      {/* footer Section */}
      <footer id="footer" className="footer" style={{ marginLeft: "0px" }}>
        <div className="container">
          <div style={{ textAlign: "center" }}>
            <img
              src="assets/images/Spacify white Final Logo-01.png"
              height={100}
              width={170}
            />
            <p className="PfooterNew" style={{ marginTop: "0px" }}>
              {" "}
              Our team creates comfortable spaces for our clients. We’ve been
              designing your everyday life and work through great ideas. Besides
              great interior design, there are lots of reasons to choose SPACIFY
              CONCEPTS PRIVATE LIMITED.
            </p>
          </div>
          <br />
          <div className="row gy-3 addressDiv">
            <div className="col-lg-4 col-md-6 d-flex">
              <i className="bi bi-geo-alt icon" />
              <div>
                <h4>Address</h4>
                <p className="PfooterNew">
                  R8 139 & R8 140, 1st floor, M3M 65th Avenue, Sector 65,
                  Gurugram, Haryana - 122101, India
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 footer-links d-flex footerMiddleCol">
              <i className="bi bi-telephone icon" />
              <div>
                <h4>Reservations</h4>
                <p className="PfooterNew">
                  <strong>Phone:</strong>{" "}
                  <a href="tel:9654788000" style={{ color: "#ffffff" }}>
                    {" "}
                    +91 96547-88000
                  </a>{" "}
                  <br />
                  <strong>Email:</strong>{" "}
                  <a
                    href="mailto: response@spacifyindia.com"
                    style={{ color: "#ffffff" }}
                  >
                    response@spacifyindia.com
                  </a>
                  <br />
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 footer-links footerLastCol">   
              <h4>Follow Us</h4>
              <div className="social-links d-flex">
                <a
                  href="https://twitter.com/spacifyindia"
                  target="_blank"
                  className="twitter"
                >
                  <i className="bi bi-twitter" />
                </a>
                <a
                  href="https://www.facebook.com/spacifyindia"
                  target="_blank"
                  className="facebook"
                >
                  <i className="bi bi-facebook" />
                </a>
                <a
                  href="https://www.instagram.com/spacifyindia/"
                  target="_blank"
                  className="instagram"
                >
                  <i className="bi bi-instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/in/spacify-india-5394892a8/"
                  className="linkedin"
                  target="_blank"
                >
                  <i className="bi bi-linkedin" />
                </a>
                <a
                  href="https://www.youtube.com/@spacifyindia"
                  className="youtube"
                  target="_blank"
                >
                  <i className="bi bi-youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <p
          className="rights cursor-pointer pb-0 mb-0 PfooterNew"
          style={{ marginTop: "15px" }}
        >
          <a href="#" onClick={() => window.open(privacy_policy, "_blank")}>
            Privacy Policy{" "}
          </a>
        </p>
        <p className="rights p-0 m-0 PfooterNew">
          <span>©&nbsp; </span>
          <span className="copyright-year" />
          <span>&nbsp;</span>

          <span>All Rights Reserved By SPACIFY CONCEPTS PRIVATE LIMITED</span>
        </p>
      </footer>
      {/* footer Section */}
      <a
        target="_blank"
        href="https://wa.link/cp1ike"
        className="ui-to-topWhatsapp  active"
      >
        <img src="https://storage.googleapis.com/bik-assets/bap%2Ffab-assets%2Fimages%2FDDQoj5ZVYwYd8aEtdFP5NRvL2Mk2%2F4b0d640ebd.png"></img>
      </a>

      <a
                  href="tel:9654788000"
                  className="ui-to-phone  active"
                >
                  <img src="assets/images/CallLogo.png"></img>
                </a>
    </>
  );
};

export default OfficeSpaces;
