import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACCESS_TOKEN_API } from "../shared/constants";
import "./Style.css";
export default function ProfilePage({
  setsignupModal,
  setloggedin,
  setloginModal,
  setuserloggedin,
  setBurgerBtn,
}) {
  const [btn, setbtn] = useState(false);
  // setTimeout(() => {
  //   const profileImage = document.getElementById("profilePageImage");
  //   profileImage.textContent = "PG";
  // }, 0);

  const signUpbuttonCLK = () => {
    setloginModal(false);
    setsignupModal(true);
    setloggedin(false);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleInputValueChange = (evt) => {
    evt.target.name === "email"
      ? setEmail(evt.target.value)
      : setPassword(evt.target.value);
  };
  const validation = () => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(regex)) {
      return true;
    } else {
      toast.error("Invalid email address");
      setbtn(false);
      return false;
    }
  };
  useEffect(() => {
    const tabKey = (event) => {
      if (event.key === "Tab") {
        if (
          event.target.placeholder === "Password" ||
          event.target.placeholder === "Email"
        ) {
        } else {
          event.preventDefault();
          document.getElementById("email").focus();
        }
      } else if (event.key === "Enter") {
        document.getElementById("loginBtn").click();
      } else if (event.key === "Escape") {
        document.getElementById("loginCrossBtn").click(); 
      }
    };
    window.addEventListener("keydown", tabKey);
    return () => {
      window.removeEventListener("keydown", tabKey);
    };
  }, []);
  const loginbuttonCLK = () => {
    
    setbtn(true);
    toast.dismiss();
    if (email === "" || password === "") {
      toast.error("Please enter value");
      setbtn(false);
      return false;
    }

    if (validation()) {
      let bodyEmail = email.toLowerCase();
      fetch(ACCESS_TOKEN_API, { method: "POST" })
        .then((response) => response.json())
        .then((result) => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${result.access_token}`);
          var raw = JSON.stringify({
            username: bodyEmail,
            password: password,
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(
            `${result.instance_url}/services/apexrest/Interior_App_Api`,
            requestOptions
          ).then(async (response) => {
            let result = await response.json();
            let errorType = result.substring(0, result.indexOf("*") - 1);
            if (
              errorType === "Invalid UserName" ||
              result === "Invalid UserName"
            ) {
              setEmail("");
              setPassword("");
              setbtn(false);
              toast.error("Invalid username or password ");
            } else if (
              errorType === "Invalid Password" ||
              result === "Invalid Password"
            ) {
              setPassword("");
              setbtn(false);
              toast.error("Invalid username or password ");
            } else if (
              errorType === "User is Not Active" ||
              result === "User is Not Active"
            ) {
              toast.error("User is not active yet");
              setbtn(false);
            } else {
              setbtn(false);

              let data = JSON.parse(result);

              setBurgerBtn(false);

              if (data) {
                localStorage.setItem("Spacify_CloudCertitude_Username", email); 
                localStorage.setItem(
                  "Spacify_CloudCertitude_Password",
                  password
                );
                document.getElementById("logoimg").style.pointerEvents = "all";
                // document.getElementById("threeDotBtn").style.pointerEvents =  "all";
                localStorage.setItem("SpacifyData", result);
                setloginModal(false);
                setsignupModal(false);
                setloggedin(true);
                setuserloggedin(true);
                localStorage.setItem("isLogin", true);
                document.body.style.overflow = "auto";
                document.body.style.background = 'white !important'
                toast.dismiss();
                toast.success("Login Successfully");
                     
              } else {
                toast.dismiss();
                toast.error("Invalid Username or Password");
                setbtn(false);
              }
            }
          });
        })
        .catch((error) => {
          toast.error("Something went wrong");
          setbtn(false);
        });
    } else {
    }
    //
  };

  return (
    <>
      <div>
        <div className="loginModalDiv1 ">
          <div
            className="LoginModalDiv4  SlidInanimation overff"
            style={{ overflow: "auto" }}
          >
            <div
              className="closebtnforLoginUp "
              style={{ textAlign: "right", fontWeight: "700" }}
            >
              <div className="centerss">
                <BeatLoader color="#000000" loading={btn} size={15} />
              </div>
              <button
              className="closeBtn"
                id="loginCrossBtn"   
                onClick={() => {
                  console.log('Hello Login')
                  setloggedin(false);
                  setsignupModal(false);
                  setloginModal(false);
                  setuserloggedin(false);
                  document.body.style.overflow = "auto";         
                  setBurgerBtn(false);
                  document.getElementById("home").style.pointerEvents = "auto";  
                  document.getElementById("logoimg").style.pointerEvents =
                    "all";
                  // document.getElementById("threeDotBtn").style.pointerEvents =
                  //   "all";
                }}
                disabled={btn}  
              >
                &#x2715;
              </button>
            </div>
            <div className="loginModalmainDiv" style={{backgroundImage: "url(assets/images/loginBack.jpg)"}}> 
            <div className="loginModalDiv2portion">
              <div className="loginPopContainer border-none">
                 {/* <h1>66</h1> */}
               
                  {/* <img className="imgDoubleQuote" src="https://i.imgur.com/1VIQPBL.png">
                  </img> */}
                  <div className="imgDiv">
                     <img  src="https://i.imgur.com/1VIQPBL.png">
                  </img>
                  </div>
                <div className="text-center d-flex align-items-center px-1" >
                  <h4>Let <span >Spacify</span> take care of your first impressions  </h4>
                </div>
              </div>
            </div>
              <div className="loginModalDiv1portion">
                <div className="text-center loginLabel">Feel Free to Login</div>
                <form>
                  <div className="text-center" style={{ marginTop: "1vh" }}>
                    <input
                      placeholder="Email"
                      value={email}
                      onChange={handleInputValueChange}
                      name="email"
                      id="email"
                      disabled={btn}
                    />
                  </div>
                  <div className="text-center">
                    <input
                      placeholder="Password"
                      type={"password"}
                      value={password}
                      onChange={handleInputValueChange}
                      name="password"
                      disabled={btn}
                    />
                  </div>
                </form>
                <div className="text-center">
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={loginbuttonCLK}
                    id="loginBtn"
                    disabled={btn}
                  >
                    Login
                  </button>
                </div>
                <div className="text-center loginModalforgotpasswordSpan"></div>
                <div className="text-center ">
                  <h5
                    onClick={signUpbuttonCLK}
                    className="loginModalSignUp"
                    style={{ cursor: "default" }}
                  >
                    Don't have an account? <u>Sign up</u>
                  </h5>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
