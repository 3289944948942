import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ACCESS_TOKEN_API } from "../shared/constants";

export default function Contact2() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [discription, setDiscription] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  const validateName = (event) => {
    if (/\d/g.test(event.key)) event.preventDefault();
  };
  const validateEmail = () => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(regex)) {
      return true;
    } else {
      toast.error("Invalid email address");
      return false;
    }
  };
  useEffect(() => {
    setEmail(localStorage.getItem("Spacify_CloudCertitude_Username"));
  }, []);
  const submit = (event) => {
    toast.dismiss();
    if (discription === "" || name === "" || subject === "") {
      toast.error("Please enter all values");
      event.preventDefault();
      return false;
    } else if (validateEmail() === false) {
      event.preventDefault();
      return false;
    } else {
      setBtnDisable(true);
      fetch(ACCESS_TOKEN_API, { method: "POST" })
        .then((response) => response.json())
        .then((result) => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${result.access_token}`);
          var raw = JSON.stringify({
            userName: name,
            userDescription: discription,
            userEmail: email,
            userSubject: subject,
            webToCase: "webToCase",
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
          fetch(
            `${result.instance_url}/services/apexrest/Interior_App_Api`,
            requestOptions
          ).then(async (response) => {
            let result = await response.json();
            if ((result = "Case successfully updated")) {
              toast.success("Thank you for contacting us we will connect soon");
              setBtnDisable(false);
            } else if ((result = "Failed")) {
              toast.error("Something Went Wrong");
              setBtnDisable(false);
            }
            setName("");
            setSubject("");
            setDiscription("");
          });
        })
        .catch((error) => {
          toast.error("Something Went Wrong");
          setBtnDisable(false);
        });
    }
  };

  return (
    <section
      className="section contact opacity1animation"
      style={{ backgroundColor: "rgb(244 244 244)", padding: "4vh" }}
    >
      <div className="row gy-4">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-lg-6">
              <div className="info-box card" style={{ height: "15rem" }}>
                <i className="bi bi-geo-alt" />
                <h3>Address</h3>
                <p>
                  R8 139 & R8 140, 1st floor,
                  <br />
                  M3M 65th Avenue, Sector 65,
                  <br />
                  Gurgaon - 122101
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="info-box card" style={{ height: "15rem" }}>
                <i className="bi bi-telephone" />
                <h3>Call Us</h3>
                <p>+91 96547-88000</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="info-box card" style={{ height: "15rem" }}>
                <i className="bi bi-envelope" />
                <h3>Email Us</h3>
                <p>response@spacifyindia.com</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="info-box card" style={{ height: "15rem" }}>
                <i className="bi bi-clock" />
                <h3>Open Hours</h3>
                <p>
                  Monday - Friday
                  <br />
                  9:00AM - 05:00PM
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6" style={{}}>
          <div className="card p-4 " style={{ height: "32rem" }}>
            <form
              style={{ paddingBottom: "2vh" }}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <input
                    id="name"
                    type="text"
                    name="name"
                    onKeyDown={validateName}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    placeholder="Your Name"
                    required=""
                    value={name}
                  />
                </div>
                <div className="col-md-6 ">
                  <input
                    id="email"
                    type="text"
                    className="form-control"
                    name="email"
                    onChange={(e) => e.target.preventDefault()}
                    placeholder="Your Email"
                    required=""
                    value={email}
                  />
                </div>
                <div className="col-md-12">
                  <input
                    id="subject"
                    type="text"
                    className="form-control"
                    name="subject"
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Subject"
                    required=""
                    value={subject}
                  />
                </div>
                <div className="col-md-12">
                  <textarea
                    name="description"
                    className="form-control"
                    onChange={(e) => setDiscription(e.target.value)}
                    rows={6}
                    placeholder="Description"
                    required=""
                    defaultValue={""}
                    value={discription}
                  />
                </div>
                <input type="hidden" id="external" name="external" value="1" />
                <br></br>
                <div className="col-md-12 text-center">
                  <button
                    class="btn btn-primary"
                    onClick={submit}
                    disabled={btnDisable}
                  >
                    &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;Send Message&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
