 export const ACCESS_TOKEN_API = '/getAccessToken'; //for production                            
// export const ACCESS_TOKEN_API = 'http://localhost:4000/getAccessToken'; //for Local host             

// localStorage Remove Items 
//Keys -
// notificationData
// SpacifyData
// isLogin
// partnerWrapList
// interiorWrapList     
// Spacify_CloudCertitude_Password 
// Spacify_CloudCertitude_Username
// feedbackWrapList
// PortfolioWrapList
// element